import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Cookies from 'js-cookie'
import {
  AboutPage,
  Account,
  Business,
  FAQ,
  LandingPage,
  LoginPage,
  PrivacyPage,
  ResetPassword,
  SearchPageContainer,
  SetPassword,
  TermsPage,
  FeedbackPage,
} from 'modules'
import { NotFound } from 'components'
import { useDispatch } from 'react-redux'
import { fetchUser, fetchUserFavorites, fetchUserLocation } from 'modules/User/actions'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const hasToken = Cookies.get('access_token')
    dispatch(fetchUserLocation())
    if (hasToken) {
      dispatch(fetchUser())
      dispatch(fetchUserFavorites())
    }
  }, [dispatch])

  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/search" exact component={SearchPageContainer} />
      <Route path="/help" exact component={FAQ} />
      <Route path="/privacy" exact component={PrivacyPage} />
      <Route path="/terms" exact component={TermsPage} />
      <Route path="/feedback" exact component={FeedbackPage} />
      <Route path="/about" exact component={AboutPage} />
      <Route path="/account" component={Account} />
      <Route path="/business/:businessId?" component={Business} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/set-password" component={SetPassword} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default App
