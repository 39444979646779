import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CardActions,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import HomeIcon from "@material-ui/icons/HomeOutlined";
import FavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteFilledIcon from "@material-ui/icons/Favorite";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";
import ClaimPopup from "../ClaimPopup";
import InfoPopup from "../InfoPopup";
import * as userActions from 'modules/User/actions'
import * as uiActions from 'modules/UI/actions'
import { AuthPopupEnum } from 'utils/constants'
import { useStyles } from './styles'
import api from 'utils/api'

const OfferActions = ({
  active,
  businessImageUrl,
  claimsRemaining,
  description,
  details,
  distance,
  endDate,
  headline1,
  headline2,
  id,
  imageUrl,
  isPreview,
  revalidateList,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { details: userDetails, favorites } = useSelector(s => s.user)
  const [filled, setFilled] = useState(Array.isArray(favorites) && favorites.find(f => f === id))
  const [showClaimPopup, setShowClaimPopup] = useState(false)
  const [showInfoPopup, setShowInfoPopup] = useState(false)

  const handleFavorite = useCallback(
    () => {
      const payload = {
        offerId: id
      }
      if (!userDetails) {
        dispatch(uiActions.showLogin(AuthPopupEnum.register))
        return
      }

      if (filled) {
        setFilled(false)
        api
          .delete(`/favorite/${id}`)
          .then(() => {
            dispatch(userActions.fetchUserFavorites())
            if (revalidateList) {
              revalidateList()
            }
          })
        return
      }

      setFilled(true)
      api
        .post('/favorite', payload)
        .then(() => {
          dispatch(userActions.fetchUserFavorites())
          if (revalidateList) {
            revalidateList()
          }
        })
    }, [dispatch, filled, id, revalidateList, setFilled, userDetails]
  )

  const isFavorite = filled

  return (
    <>
      <CardActions disableSpacing>
        <Tooltip title="Visit website" enterDelay={500} leaveDelay={50}>
          <IconButton aria-label="go to website" disabled={isPreview || !active} href={`/api/common/offer_homepage/${id}`} target="_blank" rel="nofollow">
            <HomeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={filled || isFavorite ? "Remove from favorites" : "Add to favorites"} enterDelay={500} leaveDelay={50}>
          <IconButton aria-label="Add to favorites" onClick={handleFavorite} disabled={isPreview}>
            {
              (filled || isFavorite) ?
              <FavoriteFilledIcon color="error" />
              :
              <FavoriteIcon />
            }
          </IconButton>
        </Tooltip>
        <Tooltip title="More information" enterDelay={500} leaveDelay={50}>
          <IconButton aria-label="More information" disabled={isPreview || !active} onClick={() => setShowInfoPopup(true)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            color="primary"
            disableElevation
            variant="contained"
            startIcon={<LocalOfferIcon />}
            disabled={isPreview || !active}
            onClick={() => setShowClaimPopup(true)}
          >
            Claim
          </Button>
        </div>
      </CardActions>
      {
        showClaimPopup &&
        <ClaimPopup
          closePopup={() => setShowClaimPopup(false)}
          headline1={headline1}
          headline2={headline2}
          description={description}
          distance={distance}
          claims={claimsRemaining}
          endDate={endDate}
          id={id}
        />
      }
      {
        showInfoPopup &&
        <InfoPopup
          openClaim={() => setShowClaimPopup(true)}
          businessImageUrl={businessImageUrl}
          claimsRemaining={claimsRemaining}
          closePopup={() => setShowInfoPopup(false)}
          endDate={endDate}
          details={details}
          headline1={headline1}
          headline2={headline2}
          id={id}
          imageUrl={imageUrl}
          location={distance}
          updateFavorite={setFilled}
        />
      }
    </>
  )
}

export default OfferActions
