import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import api from 'utils/api'
import * as businessActions from 'modules/Business/actions'

const KeywordsEditor = ({ businessId, keywords, onClose, }) => {
  const dispatch = useDispatch()
  const [posting, setPosting] = useState(false)
  const [newKeywords, setNewKeywords] = useState(keywords)
  const { enqueueSnackbar } = useSnackbar()

  const handleChange = useCallback(
    (val) => {
      setNewKeywords(val)
    },
    [setNewKeywords]
  )

  const handleSubmit = useCallback(() => {
    const url = `/business/${businessId}/keywords`
    setPosting(true)
    api
      .put(url, { keywords: newKeywords })
      .then(() => {
        enqueueSnackbar('Saved keywords', { variant: 'success' })
        dispatch(businessActions.updateBusinessKeywords(newKeywords))
        onClose()
      })
      .catch(() => {
        enqueueSnackbar('Cannot save your keywords', { variant: 'error' })
      })
  }, [businessId, dispatch, enqueueSnackbar, newKeywords, onClose])

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Edit business keywords</DialogTitle>
      <DialogContent>
        <Autocomplete
          ChipProps={{ color: 'primary' }}
          onChange={(e, val) => {
            handleChange(val)
          }}
          onInputChange={(e, newValue) => {
            const options = newValue.split(",");
            if (options.length > 1) {
              handleChange(
                newKeywords
                  .concat(options)
                  .map(x => x.trim())
                  .filter(x => x)
              )
            }
          }}
          options={[]}
          freeSolo
          size="small"
          name="keywords"
          multiple
          value={newKeywords || []}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              InputLabelProps={{
                color: 'primary',
              }}
              label="Keywords"
              variant="outlined"
            />
          )}
        />
          <FormHelperText>
              Type in a keyword, or words followed by ‘enter’ or ‘comma‘.
          </FormHelperText>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={posting}
            variant="contained"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default KeywordsEditor
