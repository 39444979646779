import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Box, Hidden, IconButton, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import { Container } from 'components'
import UserIcon from '../../../../components/Header/components/UserIcon'
import MobileMenu from '../MobileMenu'
import DropdownMenu from '../DropdownMenu'
import LogoSmallSrc from 'components/Logo/LogoSmall.png'
import {useIdleTimer} from "react-idle-timer";
import api from 'utils/api'

const AccountHeader = ({
  children,
  title,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [dropdownMenuAnchor, setDropdownMenuAnchor] = useState(null)
  const user = useSelector(state => state.user)

  useIdleTimer({
    timeout: 1000 * 60 * 35,
    onActive: () => {
      api.post('/geo', user.location)
    },
    debounce: 500
  })

  return (
    <>
      <header>
        <Container>
          <Box my={3} display="flex" alignItems="center" justifyContent="space-between" height={48}>
            <Box display="flex" alignItems="center">
              <Hidden mdUp>
                <NavLink to="/">
                  <img src={LogoSmallSrc} height={40} alt="HiddenUnderground logo" style={{ marginRight: 10 }} />
                </NavLink>
              </Hidden>
              <Typography variant="h5">
                {title}
              </Typography>
              <Box display="flex" alignItems="center">
                {children}
              </Box>
            </Box>
            <Box>
              <Hidden mdUp>
                <IconButton onClick={() => setMobileMenuOpen(true)} style={{ marginRight: 8 }}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <UserIcon
                avatar={user.details.imageUrl}
                name={user.details.firstName || user.details.email}
                handleClick={(e) => {
                  setDropdownMenuAnchor(e.currentTarget)
                }}
              />
              <DropdownMenu anchorElement={dropdownMenuAnchor} onClose={() => setDropdownMenuAnchor(null)} />
            </Box>
          </Box>
        </Container>
      </header>
      <MobileMenu isOpen={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} />
    </>
  )
}

export default AccountHeader
