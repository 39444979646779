import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import { OfferCard } from 'components'
import { period } from 'utils/constants'
import { dateTimeFormat, parsableDateTimeFormat } from 'utils/time'
import OfferListPreview from '../OfferListPreview'
import {fetchBusiness} from 'modules/Business/actions'


const getLocation = (location) => {
  if (location.local) {
    return `${location.local.address} + ${location.local.radius}miles radius`
  }
  if (location.states) {
    return location.states.join(',')
  }
  if (location.zips) {
    return location.zips.join(',')
  }

  if (location.type === "GLOBAL") {
    return "Global"
  }

  return 'United States'
}

const OfferReview = ({ back, onClose, onComplete, values, resetToStart }) => {
  const business = useSelector(state => state.business) // Get business state
  const businessLogo = useSelector(state => get(state, 'business.companyInfo.logoUrl'))

  const handleFormSubmit = (data) => {
    onComplete(data)
  }

  if (!values.description || !values.budget || !values.details || !values.audience) {
    return null
  }

  const accountBalance = parseFloat(business.creditBalance) 
  const balance1 = parseFloat(business.creditBalance.replace('$', '')); // Ensure accountBalance is a number

  return (
    <>
      <Box mb={4}>
        <Typography variant="h6" component="h2">
          Review your offer
        </Typography>
      </Box>

      {/* Rest of the offer review content */}
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Card>
            <CardHeader
              subheader="Summary"
              action={<IconButton onClick={resetToStart}>
                <Edit />
              </IconButton>}
            />
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Title
                  </TableCell>
                  <TableCell align="right">
                    {values.description.title}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Categories
                  </TableCell>
                  <TableCell align="right">
                    {values.description.category.map(c => c.categoryName).join(', ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Keywords
                  </TableCell>
                  <TableCell align="right">
                    {
                      Array.isArray(values.description.keywords) &&
                      values.description.keywords.join(', ')
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Starts on
                  </TableCell>
                  <TableCell align="right">
                    {moment(values.budget.startDate, parsableDateTimeFormat).format(dateTimeFormat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Ends on
                  </TableCell>
                  <TableCell align="right">
                    {moment(values.budget.endDate, parsableDateTimeFormat).format(dateTimeFormat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Location
                  </TableCell>
                  <TableCell align="right">
                    {getLocation(values.audience)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Budget
                  </TableCell>
                  <TableCell align="right">
                    ${values.budget.maxAmount} {Object.keys(period).find(p => period[p] === values.budget.amountPeriod)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Clicks
                  </TableCell>
                  <TableCell align="right">
                    {values.budget.maxClick} {Object.keys(period).find(p => period[p] === values.budget.clickPeriod)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
        <Grid item sm={12} lg={6} xs={12}>
          <Box position="sticky" top={0}>
            <Box mx="auto" maxWidth={450}>
              <Box textAlign="right">
                <OfferListPreview offer={{
                  ...values.details,
                  endDate: get(values, 'budget.endDate'),
                  imageUrl: values.details.offerImage || get(values, 'description.category[0].categoryImage'),
                  businessImageUrl: businessLogo,
                }} />
              </Box>
              <OfferCard
                {...values.details}
                isPreview
                imageUrl={get(values, 'details.offerImage') || get(values, 'description.category[0].categoryImage')}
                businessImageUrl={businessLogo}
                endDate={get(values, 'budget.endDate')}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box mt={2} mb={1} display="flex" justifyContent="space-between" alignItems="center">
        {/* Left-side Cancel Button */}
        <Box>
          <Button onClick={onClose}>
            Cancel
          </Button>
        </Box>

        {/* Right-side Buttons and Account Balance */}
        <Box display="flex" alignItems="center">
        {(() => {
    try {
      // Remove the dollar sign and convert to a number
      const balance = parseFloat(business.creditBalance.replace('$', ''));

      // Check if the balance is less than $5
      if (balance < 5) {
        return (
          <Typography variant="body2" color="error">
            Credit balance should be a minimum of $5. Please complete the offer setup and update the payment on your profile. Current balance: {business.creditBalance}
          </Typography>
        );
      } else {
        return (
          <Typography variant="body2" color="textSecondary">
            Account Balance: {business.creditBalance}
          </Typography>
        );
      }
    } catch (error) {
      // Handle any potential errors (e.g., if accountBalance is not in the expected format)
      
      return (
        <Typography variant="body2" color="error">
          Unable to display account balance. Please check your account settings.
        </Typography>
      );
    }
  })()}
          <Button
            color="primary"
            onClick={back}
            style={{ marginRight: '16px' }}
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleFormSubmit}
            variant="contained"
            type="submit"
            style={{ marginRight: '16px' }}
          >
            {
              
              balance1 < 5 ? 'Save' :
              moment(values.budget.endDate).isBefore(moment()) ?
                  'Expire' : moment(values.budget.startDate).isAfter(moment()) ?
                    'Schedule' : 'Launch'
            }
          </Button>
          
        </Box>
      </Box>
    </>
  )
}

export default OfferReview
