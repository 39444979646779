import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'

const Loader = () => (
  <Box p={2} display="flex" justifyContent="center">
    <CircularProgress />
  </Box>
)

export default Loader
