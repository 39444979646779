import React from 'react'
import Helmet from 'react-helmet'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import {
  Container,
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Button,
} from '@material-ui/core'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Page } from 'components'
import api from 'utils/api'

const schema = yup.object().shape({
  name: yup.string().required('Name can not be empty'),
  email: yup.string().email().required('The E-mail Address field is required'),
  feedback: yup.string().required('Feedback can not be empty'),
})

const FeedbackPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const { register, reset, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const submit = (data) => {
    const payload = {
      name: data.name,
      email: data.name,
      content: data.feedback,
    }

    api
      .post('/common/feedback', payload)
      .then(() => {
        enqueueSnackbar('Thank you for your feedback', { variant: 'success' })
        reset()
      })
      .catch(() => {
        enqueueSnackbar('We cannot send your feedback, please try again', { variant: 'error' })
      })
  }

  const showSuccess = location && location.search.includes('success=true')

  return (
    <Page>
      <Helmet>
        <title>Feedback - HiddenUnderground</title>
      </Helmet>
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" component="h1">
            Feedback
          </Typography>
        </Box>
        {showSuccess ? (
          <Typography variant="body2" component="p">
            Thank you for sending your feedback!
          </Typography>
        ) : (
          <form onSubmit={handleSubmit(submit)} noValidate>
            <Grid container spacing={3} lg={7}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" error={errors.name}>
                  <TextField
                    autoFocus
                    fullWidth
                    label="Name"
                    name="name"
                    variant="outlined"
                    inputRef={register}
                  />
                  <FormHelperText>
                    {errors.name ? errors.name.message : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" error={errors.email}>
                  <TextField
                    fullWidth
                    label="E-Mail"
                    name="email"
                    variant="outlined"
                    inputRef={register}
                  />
                  <FormHelperText>
                    {errors.email ? errors.email.message : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={errors.feedback}
                >
                  <TextField
                    fullWidth
                    label="Your Feedback"
                    multiline
                    name="feedback"
                    variant="outlined"
                    inputRef={register}
                  />
                  <FormHelperText>
                    {errors.feedback ? errors.feedback.message : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" width={1} justifyContent="flex-end">
                  <Button color="primary" variant="contained" type="submit">
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Container>
    </Page>
  )
}

export default FeedbackPage
