import React, { useEffect, useState } from 'react'
import { ReactFitty } from "react-fitty";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import useSWR from 'swr'
import api from 'utils/api'
import { useIsMobile } from 'utils/UI'
import Barcode from './components/Barcode'
import Qrcode from './components/Qrcode'

const codeType = {
  promoCode: 0,
  barcode: 1,
  qrCode: 2,
}

const ClaimPopup = ({
  closePopup,
  description,
  distance,
  id,
  headline1,
  headline2,
}) => {
  const url = `/offer/${id}/claim`
  const { data, error } = useSWR(url, () => api.put(url), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  })
  const [currentType, setCurrentType] = useState()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!data) {
      return
    }      
    if (data.promoCode) {
      setCurrentType(codeType.promoCode)
      return
    }
    if (data.barcode) {
      setCurrentType(codeType.barcode)
      return
    }
    if (data.qrCode) {
      setCurrentType(codeType.qrCode)
    }
  }, [data])

  return (
    <Dialog
      onClose={closePopup}
      fullScreen={isMobile}
      open
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography variant="h5">Claim offer</Typography>
      </DialogTitle>
      <DialogContent>
        <Box pb={3}>
          <Typography variant="h6" disableGutter>
            {headline1} | {headline2}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {distance}
          </Typography>
        </Box>
        <Typography paragraph variant="body2">
          {description || 'No description for this offer'}
        </Typography>
        {!data && !error && (
          <Box p={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {data && (
          <Tabs centered onChange={(e, val) => setCurrentType(val)} value={currentType}>
            {data.promoCode && <Tab label="Promo code" value={codeType.promoCode} />}
            {data.barcode && <Tab label="Barcode" value={codeType.barcode} />}
            {data.qrCode && <Tab label="QR Code" value={codeType.qrCode} />}
          </Tabs>
        )}
        {currentType === codeType.qrCode && <Qrcode code={data.qrCode} />}
        {currentType === codeType.barcode && (<Barcode code={data.barcode} />)}
        {currentType === codeType.promoCode && (
          <Box
            borderRadius={4}
            p={4}
            mb={1}
            style={{ border: '1px solid #ddd' }}
            display="flex"
            justifyContent="center"
          >
            <Typography variant="h3" color="primary" component={(props) => <ReactFitty {...props} maxSize="50" style={{ textAlign: 'center', width: '100%' }}/>}>
              {data.promoCode}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClaimPopup
