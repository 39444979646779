import React, { useCallback, useState } from 'react'
import { Box, Button, Popover, Typography } from '@material-ui/core'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import LocationForm from '../LocationForm'

const DesktopSwitcher = ({
  location,
}) => {
  const [anchorElement, setAnchorElement] = useState(null)
  const openMenu = useCallback((e) => { setAnchorElement(e.currentTarget)}, [])
  const closeMenu = useCallback(() => { setAnchorElement(null)}, [])
  return (
    <>
      <Button style={{ marginRight: 16 }} onClick={openMenu}>
        <Box display="flex" alignItems="center">
          <RoomOutlinedIcon />
          <Box ml={1} display="flex" flexDirection="column" alignItems="flex-start">
            {
              location ?
              <>
                <Typography variant="body2" textTransform="normal">
                  {location.city}
                </Typography>
                <Typography variant="body2" textTransform="normal" color="textSecondary" style={{ fontSize: 12 }}>
                  {
                    location.region_name ?
                    `${location.region_name}, ${location.country_code}`
                    :
                    `${location.country_name}`
                  }
                </Typography>
              </>
              :
              <Typography variant="body2" textTransform="normal">
                Change location
              </Typography>
            }
          </Box>
        </Box>
      </Button>
      <Popover
        anchorEl={anchorElement}
        onClose={closeMenu}
        open={Boolean(anchorElement)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        variant="menu"
      >
        <LocationForm location={location} onClose={closeMenu} />
      </Popover>
    </>
  )
}

export default DesktopSwitcher
