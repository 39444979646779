import moment from 'moment'
import { parsableDateTimeFormat } from 'utils/time'

export const mapOfferToForm = (offer) => ({
  description: {
    title: offer.title,
    keywords: offer.keywords,
    category: offer.category,
  },
  details: {
    headline1: offer.headline1,
    headline2: offer.headline2,
    description: offer.description,
    offerImage: offer.offerImage,
    siteUrl: offer.siteUrl,
    details: offer.details,
  },
  code: {
    promoCode: offer.promoCode,
    barcode: offer.barcode,
    qrCode: offer.qrCode,
  },
  budget: {
    amountPeriod: offer.budget.amountPeriod,
    clickPeriod: offer.budget.clickPeriod,
    maxAmount: offer.budget.maxAmount,
    maxClick: offer.budget.maxClick,
    endDate: moment.utc(offer.endDate).local().format(parsableDateTimeFormat),
    startDate: moment.utc(offer.startDate).local().format(parsableDateTimeFormat),
  },
  audience: {
    type: offer.offerLocation.type,
    states: offer.offerLocation.states,
    zips: offer.offerLocation.zips,
    local: offer.offerLocation.local,
  }
})
