import { useState, useEffect } from 'react'
import useSWR from 'swr'
import api from 'utils/api'

export const useWidgetData = () => {
  const [widgets, setWidgets] = useState({})
  const widgetsRoute = `/admin/stats`
  const { data, isValidating, error } = useSWR(widgetsRoute, () => api.get(widgetsRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    if (data) {
      setWidgets(data)
    } else {
      setWidgets({})
    }
  }, [data])

  return {
    loadingWidgets: !data && isValidating,
    widgetError: error,
    widgets,
  }
}

export const useUsers = ({
  order,
  orderBy,
  page,
  query,
  size,
}) => {
  const [items, setItems] = useState([])
  const [totalPages, setTotalPages] = useState()
  const usersRoute = `/admin/user/list?size=${size}&page=${page - 1}&sort=${orderBy},${order}&email=${query}`
  const { data: trans, isValidating } = useSWR(usersRoute, () => api.get(usersRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    if (trans) {
      setItems(trans.content)
      setTotalPages(trans.totalPages)
    }
  }, [trans])

  return {
    items,
    loadingItems: !trans && isValidating,
    totalPages,
  }
}
