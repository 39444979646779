import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const isBusinessKeyword = (keyword, businessKeywords) => {
  if (!businessKeywords) {
    return false
  }
  return !!businessKeywords.find(k => k === keyword)
}

const KeywordSelector = ({ control }) => {
  const business = useSelector(s => s.business)
  const options = business.keywords

  const getFilteredOptions = useCallback(
    (selected) => {
      return options.filter(o => !selected.includes(o))
    }, [options]
  ) 

  return (
    <Controller
      name="keywords"
      control={control}
      render={({ onChange, value = [] }) => {
        return (
          <Autocomplete
            onChange={(e, val) => {
              onChange(val)
            }}
            freeSolo
            size="small"
            name="keywords"
            multiple
            onInputChange={(e, newValue) => {
              const options = newValue.split(",");
              if (options.length > 1) {
                onChange(
                  value
                    .concat(options)
                    .map(x => x.trim())
                    .filter(x => x)
                )
              }
            }}
            options={getFilteredOptions(value)}
            value={value}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => {
                return (
                  <Chip
                    {...getTagProps({ index })}
                    label={option}
                    color={isBusinessKeyword(option, business.keywords) ? 'primary' : undefined}
                  />
                )
                })
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                InputLabelProps={{
                  color: 'primary',
                }}
                label="Keywords"
                variant="outlined"
              />
            )}
          />
        )}}
    />
  )
}

export default KeywordSelector
