import React, { useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { CircularProgress, FormControl, FormHelperText, IconButton, TextField } from '@material-ui/core'
import { Folder } from '@material-ui/icons'
import { Controller } from 'react-hook-form'
import Cookies from 'js-cookie'
import { parseResponse } from 'utils/api'
import { isProd } from 'utils/functions'

const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

const ImageUpload = ({
  control,
  error,
  name = 'offerImage',
  placeholder = 'Cover image',
  uploadUrl,
}) => {
  const inputRef = useRef(null)
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleImageSelect = (e, callback) => {
    const files = e.target.files
    if (!SUPPORTED_IMAGE_FORMATS.includes(files[0].type)) {
      enqueueSnackbar('Please choose only .jpg or .png images', { variant: 'error' })
      return
    }
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result) {
        validateImage(files[0], callback)
      }
    }
    reader.readAsDataURL(files[0])
  }

  // Validate the image before upload
  const validateImage = (img, callback) => {
    const formData = new FormData()
    formData.append('file', img)
    setIsUploading(true)
    
    // Validation URL
    const validationUrl = 'https://www.hiddenunderground.com/imagevalidation/analyze-imagee/'
    
    fetch(validationUrl, {
      method: 'POST',
      body: formData,
    })
    .then(res => res.json())
    .then((response) => {
      // Log the full response for debugging
      console.log('Validation API Response:', response)
      
      // Ensure the response contains an "answer" field
      if (response && response.answer) {
        const answer = response.answer.trim().toLowerCase()

        if (answer.startsWith('no')) {
          // Image is valid, proceed with upload
          uploadLogo(img, callback)
        } else {
          // Image is inappropriate
          enqueueSnackbar('Inappropriate image, cannot upload it', { variant: 'error' })
          setIsUploading(false)
        }
      } else {
        // Handle case where "answer" is undefined or missing
        enqueueSnackbar('Invalid response from validation service. Please try again.', { variant: 'error' })
        setIsUploading(false)
      }
    })
    .catch((err) => {
      enqueueSnackbar(`Failed to validate the image: ${err.message}`, { variant: 'error' })
      setIsUploading(false)
    })
  }

  // Upload image after validation
  const uploadLogo = (img, callback) => {
    const formData = new FormData()
    formData.append('file', img)
    setIsUploading(true)
    fetch(uploadUrl, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('access_token') || ''}`,
      },
      mode: 'cors',
      withCredentials: true,
      credentials: 'include',
    })
    .then(res => parseResponse(res))
    .then((response) => {
      enqueueSnackbar('Image uploaded', { variant: 'success' })
      const host = isProd() ? window.location.origin : 'https://staging.hiddenunderground.com'
      const url = `${host}${response.result}`
      callback(url)
    })
    .catch(() => {
      enqueueSnackbar('Cannot upload image, please try again', { variant: 'error' })
    })
    .finally(() => {
      setIsUploading(false)
    })
  }

  const handleClick = () => {
    if (!inputRef || !inputRef.current) {
      return
    }
    inputRef.current.click()
  }

  return (
    <>
      <FormControl fullWidth variant="outlined" margin="dense">
        <Controller
          control={control}
          name={name}
          render={({ value, onChange }) => (
            <>
              <TextField
                label={value == null ? placeholder : ""}
                inputProps={{ disabled: true }}
                InputProps={{
                  endAdornment: isUploading ? 
                    <CircularProgress size={20} />
                    :
                    <IconButton onClick={handleClick} size="small"><Folder /></IconButton>
                }}
                variant="outlined"
                margin="dense"
                value={value}
              />
              <input
                accept=".png,.jpg,.jpeg"
                onChange={(e) => handleImageSelect(e, onChange)}
                ref={inputRef}
                type="file"
                style={{
                  visibility: 'hidden',
                  width: 0,
                  position: 'absolute',
                }}
              />
            </>
          )}
        />
        {
          error &&
          <FormHelperText error>
            {error.message}
          </FormHelperText>
        }
      </FormControl>
    </>
  )
}

export default ImageUpload