import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Grid, FormControl, FormHelperText, TextField } from '@material-ui/core'
import AccountPage from '../../components/AccountPage'
import api from 'utils/api'
import * as userActions from 'modules/User/actions'

const AccountInformation = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(state => state.user)
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit, errors, formState} = useForm({
    defaultValues: {
      firstName: user.details.firstName,
      lastName: user.details.lastName,
      job: user.details.job,
      phone: user.details.phone,
    },
  })

  const handleFormSubmit = useCallback(
    (values) => {
      setSubmitting(true)
      api
        .post('/user/profile', values)
        .then(() => {
          enqueueSnackbar('Account information updated', { variant: 'success' })
          dispatch(userActions.fetchUser())
        })
        .catch(() => {
          enqueueSnackbar('Cannot update account information', { variant: 'error' })
        })
        .finally(() => {
          setSubmitting(false)
        })
    }, [dispatch, enqueueSnackbar]
  )

  const { isDirty } = formState

  return (
    <AccountPage title="My information">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box p={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <FormControl
                error={errors.firstName}
                fullWidth
                variant="outlined"
              >
                <TextField
                  defaultValue=""
                  disabled={user.details.provider !== 'hug'}
                  error={errors.firstName}
                  fullWidth
                  label="First name"
                  name="firstName"
                  inputRef={register}
                  variant="outlined"
                />
                {
                  errors.firstName &&
                  <FormHelperText>
                    {errors.firstName.message}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <FormControl
                error={errors.lastName}
                fullWidth
                variant="outlined"
              >
                <TextField
                  defaultValue=""
                  disabled={user.details.provider !== 'hug'}
                  error={errors.lastName}
                  fullWidth
                  label="Last name"
                  name="lastName"
                  inputRef={register}
                  variant="outlined"
                />
                {
                  errors.lastName &&
                  <FormHelperText>
                    {errors.lastName.message}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <FormControl
                error={errors.job}
                fullWidth
                variant="outlined"
              >
                <TextField
                  defaultValue=""
                  error={errors.firstName}
                  fullWidth
                  label="Job title"
                  name="job"
                  inputRef={register}
                  variant="outlined"
                />
                {
                  errors.job &&
                  <FormHelperText>
                    {errors.job.message}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <FormControl
                error={errors.phone}
                fullWidth
                variant="outlined"
              >
                <TextField
                  defaultValue=""
                  error={errors.phone}
                  fullWidth
                  label="Phone"
                  name="phone"
                  inputRef={register}
                  variant="outlined"
                />
                {
                  errors.phone &&
                  <FormHelperText>
                    {errors.phone.message}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box py={1} px={2}>
          <Button disabled={submitting || !isDirty} type="submit" variant="contained" color="primary">
            {
              submitting ?
              'Saving'
              :
              'Save'
            }
          </Button>
        </Box>
      </form>
    </AccountPage>
  )
}

export default AccountInformation
