import { get } from 'lodash'
import { useSelector } from 'react-redux'

export const getSavedLocation = () => {
  const L = sessionStorage.getItem('location')
  return JSON.parse(L)
}

export const persistLocation = (location) => {
  sessionStorage.setItem('location', JSON.stringify(location))
}

export const getBrowserPosition = async () => {
  const pos = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: false, timeout: 1_000 });
  });

  return {
    latitude: pos.coords.latitude,
    longitude: pos.coords.longitude,
  };
}

export const useLocation = () => {
  return useSelector(state => get(state, 'user.location'))
}
