import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers';
import { Box, Button, Grid, FormControl, FormHelperText, TextField, Typography, Link } from '@material-ui/core';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import api from 'utils/api';
import { fetchUser } from 'modules/User/actions';
import { PaymentSchema } from './utils';
import cardImg from './cards.png';

const PaymentForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const [posting, setPosting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(PaymentSchema),
  });

  const handleFormSubmit = async (data) => {
    if (!stripe || !elements) {
      return;
    }

    const payload = {
      name: data.name,
      address_line1: data.address,
      address_city: data.city,
      address_state: data.state,
      address_zip: data.zipCode,
      address_country: data.country,
    };

    try {
      setPosting(true);
      const res = await stripe.createToken(elements.getElement(CardNumberElement), payload);
      await api.post('/stripe/customer', { token: res.token.id });
      dispatch(fetchUser());
      onClose();
    } catch (e) {
      setPosting(false);
      enqueueSnackbar('Cannot add payment method', { variant: 'error' });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="body2" color="textSecondary">
        We use <Link href={"https://www.stripe.com/"} target={"_blank"} rel="nofollow">Stripe</Link> as our secure payment processor. By adding your payment information to your account you enable your offers to run uninterrupted. Add, Update or Remove your payment methods at any time.
      </Typography>
      
      <Box py={2}>
        <img src={cardImg} alt="cards" height={30} />
      </Box>

      {/* Spacing added between form elements */}
      <Box my={2}>
        <TextField
          fullWidth
          label="Card number"
          size="small"
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputComponent: CardNumberElement }}
          variant="outlined"
        />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box my={2}>
            <TextField
              fullWidth
              label="Expiration date"
              size="small"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: CardExpiryElement }}
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box my={2}>
            <TextField
              fullWidth
              label="CVC"
              size="small"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: CardCvcElement }}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Typography variant="subtitle1">Billing Address</Typography>
      </Box>

      <Box my={2}>
        <FormControl error={errors.name} fullWidth variant="outlined">
          <TextField
            defaultValue=""
            error={errors.name}
            fullWidth
            label="Name"
            name="name"
            size="small"
            inputRef={register}
            variant="outlined"
          />
          {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
        </FormControl>
      </Box>

      <Box my={2}>
        <FormControl error={errors.address} fullWidth variant="outlined">
          <TextField
            defaultValue=""
            error={errors.address}
            fullWidth
            label="Address"
            name="address"
            size="small"
            inputRef={register}
            variant="outlined"
          />
          {errors.address && <FormHelperText>{errors.address.message}</FormHelperText>}
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl error={errors.city} fullWidth variant="outlined">
            <TextField
              defaultValue=""
              error={errors.city}
              fullWidth
              label="City"
              name="city"
              size="small"
              inputRef={register}
              variant="outlined"
            />
            {errors.city && <FormHelperText>{errors.city.message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl error={errors.state} fullWidth variant="outlined">
            <TextField
              defaultValue=""
              error={errors.state}
              fullWidth
              label="State"
              name="state"
              size="small"
              inputRef={register}
              variant="outlined"
            />
            {errors.state && <FormHelperText>{errors.state.message}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl error={errors.zipCode} fullWidth variant="outlined">
            <TextField
              defaultValue=""
              error={errors.zipCode}
              fullWidth
              label="Zip code"
              name="zipCode"
              size="small"
              inputRef={register}
              variant="outlined"
            />
            {errors.zipCode && <FormHelperText>{errors.zipCode.message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl error={errors.country} fullWidth variant="outlined">
            <TextField
              defaultValue=""
              error={errors.country}
              fullWidth
              label="Country"
              name="country"
              size="small"
              inputRef={register}
              variant="outlined"
            />
            {errors.country && <FormHelperText>{errors.country.message}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      <Box my={2} display="flex" justifyContent="flex-end">
        <Button style={{ marginRight: 16 }} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!stripe || posting} type="submit" color="primary" variant="contained" disableElevation>
          Submit{posting ? 'ting' : ''}
        </Button>
      </Box>
    </form>
  );
};

export default PaymentForm;