import React, { useCallback, useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { listingType, sendImpression } from '../utils'
import OfferCard from '../OfferCard'
import OfferListItem from '../OfferListItem'

const Offer = ({
  offer,
  offerOrigin,
  offerType = 'card',
  revalidateList,
}) => {
  const [impressionSent, setImpressionSent] = useState(false)

  const trackImpression = useCallback((isVisible) => {
    if (isVisible && !impressionSent && !offer.isPreview && offer.active) {
      setImpressionSent(true)
      sendImpression(offer.id, offerOrigin)
    }
  }, [offer.active, offer.id, impressionSent, offer.isPreview, offerOrigin])
  return (
    <VisibilitySensor onChange={trackImpression} partialVisibility={true}>
      {
        offerType === listingType.card ?
        <OfferCard {...offer} revalidateList={revalidateList} />
        :
        <OfferListItem offer={offer} revalidateList={revalidateList} />
      }
    </VisibilitySensor>
  )
}

export default Offer
