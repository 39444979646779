import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import CategorySelector from './components/CategorySelector'
import KeywordSelector from './components/KeywordSelector'
import api from 'utils/api'
import * as businessActions from 'modules/Business/actions'

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  category: yup.array().required('At least 1 category is required'),
})

const OfferDescription = ({ onClose, onComplete, values }) => {
  const dispatch = useDispatch()
  const business = useSelector(state => state.business)
  const { enqueueSnackbar } = useSnackbar()
  const { register, control, handleSubmit, errors, watch } = useForm({
    defaultValues: values.description || {},
    resolver: yupResolver(schema),
  })

  const keywords = watch('keywords')

  const handleFormSubmit = (data) => {
    onComplete(data)
  }

  const handleSaveKeywords = () => {
    api.post(`/business/${business.businessId}/keywords`, {
      keywords: keywords
    })
      .then(() => {
        enqueueSnackbar('Saved your keywords', { variant: 'success' })
        dispatch(businessActions.fetchBusiness(business.businessId, true))
      })
      .catch(() => {
        enqueueSnackbar('Cannot save your keywords', { variant: 'error' })
      })
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box mb={4}>
        <Typography variant="h6" component="h2">
          Describe your offer
        </Typography>
        <Typography variant="body2" component="p">
          This info will be used to create an offer that reaches the right
          customers
        </Typography>
      </Box>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={!!errors.title}>
            <TextField
              id="offer-title"
              label="Title"
              defaultValue=""
              name="title"
              inputRef={register}
              helperText="This is for your own reference. It won’t be displayed to customers."
              variant="outlined"
              margin="dense"
            />
            {
              errors.title &&
              <FormHelperText>
                {errors.title.message}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={!!errors.category}>
            <CategorySelector control={control} />
            {
              errors.category &&
              <FormHelperText>
                {errors.category.message}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" error={!!errors.keywords}>
            <KeywordSelector control={control} />
            <FormHelperText>
              {errors.keywords
                ? errors.keywords.message
                : `Keywords help your customers find your offer. Type in a keyword, or words followed by ‘enter’ or ‘comma‘. You can also add currently displayed Offer keywords as Business keywords - to be reused for other offers.`
              }
            </FormHelperText>
          </FormControl>
          <Button color="primary" onClick={handleSaveKeywords}>
            Add to Business keywords
          </Button>
        </Grid>
      </Grid>
      <Box mt={2} mb={1} display="flex" justifyContent="space-between">
        <Box>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default OfferDescription
