import React, { useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Box, Card, CardHeader, CardContent, Grid, MenuItem, Select } from '@material-ui/core'
import {KeyboardDatePicker} from '@material-ui/pickers'
import BusinessPage from '../../components/BusinessPage'
import Chart from './components/Chart'
import { dateFormat } from 'utils/time'
import { useChartData, useWidgetData } from './utils'
import { useIsMobile } from 'utils/UI'

const chartTypes = {
  impression: 'IMPRESSION',
  click: 'CLICK',
  debit: 'DEBIT',
  budget: 'BUDGET',
}

const chartLabels = {
  [chartTypes.impression]: 'Impressions',
  [chartTypes.click]: 'Clicks',
  [chartTypes.debit]: 'Charges',
  [chartTypes.budget]: 'Account balance',
}

const Dashboard = () => {
  const isMobile = useIsMobile()
  const businessId = useSelector(state => state.business.businessId)
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').startOf('day'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [chartType, setChartType] = useState(chartTypes.impression)

  const startDateParsed = startDate && startDate._isValid ? startDate.startOf('day') : null
  const endDateParsed = endDate && endDate._isValid ? endDate.endOf('day') : null
  const startDateLocalized = startDateParsed ? startDateParsed.toISOString() : null
  const endDateLocalized = endDateParsed ? endDateParsed.toISOString() : null

  const { widgets } = useWidgetData({ businessId, startDate: startDateLocalized, endDate: endDateLocalized })
  const { chartData, loadingChartData } = useChartData({ businessId, chartType, startDate: startDateLocalized, endDate: endDateLocalized })

  return (
    <BusinessPage title="Dashboard">
      <Box p={2} display="flex" alignItems="flex-start" flexDirection={isMobile ? 'column' : 'row'}>
        <Box>
          <KeyboardDatePicker
            autoOk
            InputAdornmentProps={{ position: "end" }}
            variant="inline"
            format={dateFormat}
            inputVariant="outlined"
            label="Start date"
            margin="dense"
            mask="__/__/____"
            placeholder={dateFormat}
            value={startDate}
            maxDate={moment(endDate)}
            maxDateMessage="Start date should not be before End date"
            onChange={val =>
              setStartDate(val)
            }
            KeyboardButtonProps={{
              'aria-label': 'Change start date',
            }}
          />
        </Box>
        <Box ml={isMobile ? 0 : 1}>
          <KeyboardDatePicker
            autoOk
            disableFuture
            InputAdornmentProps={{ position: "end" }}
            variant="inline"
            format={dateFormat}
            inputVariant="outlined"
            label="End date"
            margin="dense"
            mask="__/__/____"
            placeholder={dateFormat}
            value={endDate}
            minDate={moment(startDate)}
            minDateMessage="End date should not be before Start date"
            maxDateMessage="End date should not be a future date"
            onChange={val =>
              setEndDate(val)
            }
            KeyboardButtonProps={{
              'aria-label': 'Change end date',
            }}
          />
        </Box>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <CardHeader
                title="Total impressions"
                subheader={widgets.impressions || '-'}
                subheaderTypographyProps={{
                  variant: 'h6',
                  color: 'textPrimary',
                }}
                titleTypographyProps={{
                  variant: 'body2',
                  color: 'textSecondary',
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <CardHeader
                title="Offers claimed"
                subheader={widgets.claims || '-'}
                subheaderTypographyProps={{
                  variant: 'h6',
                  color: 'textPrimary',
                }}
                titleTypographyProps={{
                  variant: 'body2',
                  color: 'textSecondary',
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <CardHeader
                title="Offers favorited"
                subheader={widgets.favorite || '-'}
                subheaderTypographyProps={{
                  variant: 'h6',
                  color: 'textPrimary',
                }}
                titleTypographyProps={{
                  variant: 'body2',
                  color: 'textSecondary',
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <CardHeader
                title="Offer homepages visited"
                subheader={widgets.homepage || '-'}
                subheaderTypographyProps={{
                  variant: 'h6',
                  color: 'textPrimary',
                }}
                titleTypographyProps={{
                  variant: 'body2',
                  color: 'textSecondary',
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <Select margin="dense" variant="outlined" value={chartType} onChange={e => setChartType(e.target.value)}>
          <MenuItem value={chartTypes.impression}>{chartLabels[chartTypes.impression]}</MenuItem>
          <MenuItem value={chartTypes.click}>{chartLabels[chartTypes.click]}</MenuItem>
          <MenuItem value={chartTypes.debit}>{chartLabels[chartTypes.debit]}</MenuItem>
          <MenuItem value={chartTypes.budget}>{chartLabels[chartTypes.budget]}</MenuItem>
        </Select>
      </Box>
      <Box px={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Card>
              <CardHeader title={chartLabels[chartType]} titleTypographyProps={{
                  variant: 'body1',
                }} />
              <CardContent>
                <Chart config={chartData.config} loading={loadingChartData} points={chartData.points} title={chartLabels[chartType]} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </BusinessPage>
  )
}

export default Dashboard
