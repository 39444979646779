import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import { ConfirmationDialog } from 'components'
import PaymentPopup from '../../components/PaymentPopup'
import PaymentCard from '../../components/PaymentCard'
import AccountPage from '../../components/AccountPage'

const Payment = () => {
  const [formOpened, setFormOpened] = useState(false)
  const cards = useSelector(state => get(state, 'user.details.sources.data'))

  return (
    <AccountPage title="Payment">
      <Box p={2} pt={0}>
        <Button variant="contained" color="primary" disableElevation onClick={() => setFormOpened(true)}>
          Add new card
        </Button>
      </Box>
      <Box p={2}>
        <Grid container spacing={3} alignItems="stretch">
          {
            cards && !!cards.length ?
            cards.map(c => (
              <Grid item xs={12} sm={6} lg={4}>
                <PaymentCard card={c} />
              </Grid>  
            ))
            :
            <Grid item xs={12} sm={6} lg={4}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Payment method
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    You need credit card to fund your offers
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button color="primary" onClick={() => setFormOpened(true)}>
                    Add payment method
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          }
        </Grid>
      </Box>
      {
        formOpened &&
        <PaymentPopup onClose={() => setFormOpened(false)} />
      }
      {
        <ConfirmationDialog />
      }
    </AccountPage>
  )
}

export default Payment
