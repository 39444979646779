import * as types from './actionTypes'

const initialState = {
  isLoading: true,
}

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BUSINESS_INIT:
      return {
        isLoading: true,
      }
    case types.FETCH_BUSINESS_SUCCESS:
      return {
        isLoading: false,
        ...action.data,
      }
    case types.FETCH_BUSINESS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case types.UPDATE_BUSINESS_COMPANY_INFO:
      return {
        ...state,
        companyInfo: { ...action.companyInfo },
      }
    case types.UPDATE_BUSINESS_KEYWORDS:
      return {
        ...state,
        keywords: action.keywords,
      }
    case types.UPDATE_BUSINESS_BUDGET:
      return {
        ...state,
        businessBudget: action.budget,
      }
    case types.CLEANUP_BUSINESS:
      return initialState
    default:
      return state
  }
}

export default businessReducer
