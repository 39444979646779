import api from 'utils/api'

export const pauseOffer = (offerId) => {
  return api.post(`/offer/${offerId}/pause`)
}

export const cloneOffer = (offerId) => {
  return api.post(`/offer/${offerId}/clone`)
}

export const activateOffer = (offerId) => {
  return api.post(`/offer/${offerId}/activate`)
}

export const deleteOffer = (offerId) => {
  return api.delete(`/offer/${offerId}`)
}