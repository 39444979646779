import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { dateFormat, parsableDateFormat } from 'utils/time'
import api from 'utils/api'
import BusinessSelect from '../BusinessSelect'

const couponType = {
  SINGLE_USE: 'SINGLE_USE',
  MULTI_USE: 'MULTI_USE',
  BUSINESS: 'BUSINESS',
}

const schema = yup.object().shape({
  couponCode: yup
    .string()
    .required('Required')
    .test(
      'is-code-available',
      'Coupon code already in use',
        function (val) {
          return new Promise((resolve, reject) => {
            api.get(`/coupon/${val}/available`)
                .then(() => {
                  resolve(true)
                }).catch(() => {
                  resolve(false)
                })
          })
        }
    ),
  amount: yup
    .number()
    .min(1, 'Coupon should have at least a value of $1')
    .required('Required'),
  type: yup.string(),
  startDate: yup.string(),
  expirationDate: yup.string(),
  referrer: yup.string().max(20, 'Referrer code is too long'),
  businessId: yup
    .number()
    .when('type', {
      is: couponType.BUSINESS,
      then: yup
        .number()
        .required('Business ID is required for business coupon type'),
    }),
  maxUses: yup.number().min(1, 'Max uses should be at least 1'),
})

const CouponForm = ({ onClose, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [submitting, setSubmitting] = useState(false)
  const { register, control, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      amount: 20,
      startDate: moment(),
      expirationDate: moment().add(1, 'month'),
      maxUses: 1,
      newBusiness: 1,
      type: couponType.MULTI_USE,
    },
    resolver: yupResolver(schema),
  })

  const handleFormSubmit = (data) => {
    const payload = {
      ...data,
      newBusiness: !!data.newBusiness,
      startDate: moment(data.startDate).format(parsableDateFormat),
      expirationDate: moment(data.expirationDate).format(parsableDateFormat),
      maxUses: data.type === couponType.MULTI_USE ? data.maxUses : 1,
    }
    setSubmitting(true)
    api.post('/coupon', payload)
      .then(() => {
        enqueueSnackbar('Created new coupon', { variant: 'success' })
        onSuccess()
        onClose()
      })
      .catch(() => {
        enqueueSnackbar('Cannot create coupon', { variant: 'error' })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const typeWatch = watch('type')

  return (
    <Dialog open fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogTitle>Create coupon</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} sm={12} alignItems="flex-start">
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Coupon code"
                  name="couponCode"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                />
                {errors.couponCode && (
                  <FormHelperText error>
                    {errors.couponCode.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Amount"
                  name="amount"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                {errors.amount && (
                  <FormHelperText error>
                    {errors.amount.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" style={{ margin: '8px 0' }}>
                <InputLabel id="type" margin="dense">Type</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  as={
                    <Select
                      fullWidth
                      labelId="type"
                      margin="dense"
                      variant="outlined"
                      label="Type"
                    >
                        {
                          Object.keys(couponType).map(key => (
                            <MenuItem value={couponType[key]}>
                              {key}
                            </MenuItem>
                          ))
                        }
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="startDate"
                render={({ onChange, value }) => (
                  <DatePicker
                    autoOk
                    variant="inline"
                    format={dateFormat}
                    fullWidth
                    inputVariant="outlined"
                    label="Start date"
                    margin="dense"
                    onChange={val => onChange(val)}
                    KeyboardButtonProps={{
                      'aria-label': 'Change start date',
                    }}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="expirationDate"
                render={({ onChange, value }) => (
                  <DatePicker
                    autoOk
                    variant="inline"
                    format={dateFormat}
                    fullWidth
                    inputVariant="outlined"
                    label="Expiration date"
                    margin="dense"
                    onChange={val => onChange(val)}
                    KeyboardButtonProps={{
                      'aria-label': 'Change expiration date',
                    }}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Referrer"
                  name="referrer"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                />
                {errors.referrer && (
                  <FormHelperText error>
                    {errors.referrer.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  disabled={typeWatch !== couponType.MULTI_USE}
                  label="Max uses"
                  name="maxUses"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                  type="number"
                />
                {errors.maxUses && (
                  <FormHelperText error>
                    {errors.maxUses.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" style={{ margin: '8px 0' }}>
                <InputLabel id="business-only" margin="dense" >New business</InputLabel>
                <Controller
                  name="newBusiness"
                  control={control}
                  as={
                    <Select
                      fullWidth
                      labelId="business-only"
                      margin="dense"
                      variant="outlined"
                      label="Business only"
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
            {
              typeWatch === couponType.BUSINESS &&
              <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  control={control}
                  name="businessId"
                  render={({ onChange }) => (
                    <BusinessSelect onChange={onChange} />
                  )}
                />
              </FormControl>
            </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={submitting} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={submitting} variant="contained" color="primary" type="submit">
            Create coupon
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CouponForm
