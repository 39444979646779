import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core'
import FavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteFilledIcon from "@material-ui/icons/Favorite";
import OfferMeta from '../OfferCard/components/OfferMeta'
import * as userActions from 'modules/User/actions'
import * as uiActions from 'modules/UI/actions'
import { AuthPopupEnum } from 'utils/constants'
import api from 'utils/api'

const isRemote = (url) => url.indexOf('unsplash') !== -1

const InfoPopup = ({
  businessImageUrl,
  claimsRemaining,
  closePopup,
  endDate,
  id,
  imageUrl,
  details,
  headline1,
  headline2,
  openClaim,
  updateFavorite,
}) => {
  const dispatch = useDispatch()
  const img = isRemote(imageUrl) ? `${imageUrl}&w=500` : imageUrl
  const { details: userDetails, favorites } = useSelector(s => s.user)
  const [filled, setFilled] = useState(Array.isArray(favorites) && favorites.find(f => f === id))

  const handleFavorite = useCallback(
    () => {
      const payload = {
        offerId: id
      }
      if (!userDetails) {
        dispatch(uiActions.showLogin(AuthPopupEnum.register))
        return
      }

      if (filled) {
        setFilled(false)
        updateFavorite(false)
        api
          .delete(`/favorite/${id}`)
          .then(() => {
            dispatch(userActions.fetchUserFavorites())
          })
        return
      }

      setFilled(true)
      updateFavorite(true)
      api
        .post('/favorite', payload)
        .then(() => {
          dispatch(userActions.fetchUserFavorites())
        })
    }, [dispatch, filled, id, setFilled, updateFavorite, userDetails]
  )

  const isFavorite = filled

  return (
    <Dialog onClose={closePopup} open fullWidth maxWidth="sm">
      {imageUrl && (
        <Box
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url('${img}')`,
            backgroundPositionY: '50%',
            height: 150,
          }}
        />
      )}
      <Box px={1}>
        <OfferMeta quantity={claimsRemaining} endDate={endDate} />
      </Box>
      <DialogTitle>
        <Box height={30}>
          <img src={businessImageUrl} height={30} alt="" />
        </Box>
        <Typography variant="h5">
          {headline1} | {headline2}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">Terms</Typography>
        <Typography variant="body2">{details || 'No terms defined'}</Typography>
        <Box my={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={openClaim}
              >
                Claim offer
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                color="secondary"
                fullWidth
                href={`/api/common/offer_homepage/${id}`} 
                rel="nofollow"
                size="large"
                target="_blank"
                variant="contained"
              >
                Go to website
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Button onClick={handleFavorite}>
            {
              (filled || isFavorite) ?
              <FavoriteFilledIcon color="error" />
              :
              <FavoriteIcon />
            }
            <span style={{ marginLeft: 8 }}>
              {
                (filled || isFavorite) ?
                'Remove from favorites'
                :
                'Add to favorites'
              }
            </span>
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoPopup
