import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Card, Button, Typography, CardHeader, CardActions, CardContent } from '@material-ui/core'
import BudgetForm from './components/BudgetForm'
import { periodLabels } from 'utils/constants'

const BusinessBudget = () => {
  const [showEditor, setShowEditor] = useState(false)
  const { businessId, businessBudget } = useSelector(state => state.business)

  return (
    <>
      <Box mb={2}>
        <Card>
          <CardHeader subheader="Business budget" />
          <CardContent>
            {
              businessBudget ?
              <>
                <Typography variant="h6">
                  {businessBudget.maxClick} clicks - {periodLabels[businessBudget.clickPeriod]}
                </Typography>
                <Typography variant="h6">
                  ${businessBudget.maxAmount} - {periodLabels[businessBudget.amountPeriod]}
                </Typography>
              </>
              :
              <Typography color="textSecondary" variant="body2" paragraph>
                You didn't define a business budget
              </Typography>
            }
            
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={() => setShowEditor(true)}>
              {businessBudget ? "Edit budget" : "Add budget"}
            </Button>
          </CardActions>
        </Card>
      </Box>
      {
        showEditor &&
        <BudgetForm onClose={() => setShowEditor(false)} businessId={businessId} businessBudget={businessBudget} />
      }
    </>
  )
}

export default BusinessBudget
