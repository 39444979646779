import { useState, useEffect } from 'react'
import useSWR from 'swr'
import api from 'utils/api'

export const useWidgetData = ({
  businessId,
  endDate,
  startDate,
}) => {
  const [widgets, setWidgets] = useState({})
  const widgetsRoute = `/business/${businessId}/widgets?startDate=${startDate}&endDate=${endDate}`
  const datesValid = Boolean(startDate) && Boolean(endDate)
  const { data, isValidating, error } = useSWR(datesValid ? widgetsRoute : null, () => api.get(widgetsRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    if (data) {
      setWidgets(data)
    } else {
      setWidgets({})
    }
  }, [data])

  return {
    loadingWidgets: !data && isValidating,
    widgetError: error,
    widgets,
  }
}

export const useChartData = ({
  businessId,
  chartType,
  endDate,
  startDate,
}) => {
  const [chartData, setChartData] = useState({})
  const widgetsRoute = `/business/${businessId}/graph?type=${chartType}&startDate=${startDate}&endDate=${endDate}`
  const datesValid = Boolean(startDate) && Boolean(endDate)
  const { data, isValidating } = useSWR(datesValid ? widgetsRoute : null, () => api.get(widgetsRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    if (data) {
      setChartData(data)
    }
  }, [data])

  return {
    loadingChartData: isValidating,
    chartData,
  }
}