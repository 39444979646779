import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import { ConfirmationDialog } from 'components'
import AccountPage from '../../components/AccountPage'
import { fetchUser } from 'modules/User/actions'
import api from 'utils/api'

const Payment = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [verificationOpen, setVerificationOpen] = useState(false)
  const dispatch = useDispatch()

  const convert = () => {
    api
      .post('/user/upgrade/business')
      .then(() => {
        history.push('/business')
        dispatch(fetchUser())
      })
      .catch(() => {
        enqueueSnackbar('Cannot upgrade your account', { variant: 'error' })
      })
  }

  return (
    <AccountPage title="Settings">
      <Box p={2}>
        <Grid container>
          <Grid item lg={8} md={10} sm={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Business account
                </Typography>
                <Typography color="textSecondary">
                  Convert your account to business in order to add your business offers
                </Typography>
              </CardContent>
              <CardActions>
                <Button color="primary" onClick={() => setVerificationOpen(true)}>
                  Convert to business account
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {
        verificationOpen &&
        <ConfirmationDialog
          onCancel={() => setVerificationOpen(false)}
          onConfirm={convert}
          isOpen
          body = 'Are you sure you want to convert your account to business?'
          title = 'Convert to business account'
          successButtonLabel = 'Convert'
        />
      }
    </AccountPage>
  )
}

export default Payment
