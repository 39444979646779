import React from 'react'
import { useSelector } from 'react-redux'
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { Box, Drawer, Hidden, List } from '@material-ui/core'
import {
  Payment as PaymentIcon,
  InfoOutlined as InfoIcon,
  NotificationsActiveOutlined as AlertIcon,
  FavoriteBorderOutlined as FavIcon,
  SettingsOutlined as SettingsIcon,
} from '@material-ui/icons'
import { Loader, MenuItem } from 'components'
import AccountInformation from './modules/AccountInformation'
import Payment from './modules/Payment'
import Alerts from './modules/Alerts'
import Settings from './modules/Settings'
import FavoritePageContainer from './modules/FavoritePageContainer'
import LogoSrc from 'components/Logo/Logo.png'
import { useBusinessUser } from 'utils/roles'
import {get} from "lodash";
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded'
import Helmet from "react-helmet";

const Account = () => {
  const history = useHistory()
  const location = useLocation()
  const user = useSelector((state) => state.user)
  const businessUser = useBusinessUser()
  const cards = useSelector(state => get(state, 'user.details.sources.data'))

  if (user.isFetching) {
    return <Loader />
  }

  if (!user.details.userId) {
    history.push('/login', { redirectUrl: location.pathname })
  }

  return (
    <>
      <Helmet>
        <title>My Profile - HiddenUnderground</title>
      </Helmet>
      <Hidden smDown>
        <Drawer variant="permanent" anchor="left">
          <Box pt={6} pb={4} width={300} display="flex" justifyContent="center">
            <NavLink to="/">
              <img src={LogoSrc} alt="" height={62} />
            </NavLink>
          </Box>
          <List>
            <MenuItem
              icon={<AlertIcon />}
              text="My alerts"
              link="/account/my-alerts"
            />
            <MenuItem
              icon={<FavIcon />}
              text="My favorites"
              link="/account/my-favorites"
            />
            <MenuItem
              icon={<InfoIcon />}
              text="My information"
              link="/account/my-information"
            />
            {
              businessUser &&
              <MenuItem
                icon={<PaymentIcon />}
                text="Payment"
                link="/account/payment"
              />
            }
            {
              businessUser && cards && !!cards.length &&
              <MenuItem
                  icon={<DashboardRoundedIcon />}
                  text="My Business"
                  link="/business"
              />
            }
            {
              !businessUser &&
              <MenuItem
                icon={<SettingsIcon />}
                text="Account settings"
                link="/account/settings"
              />
            }
          </List>
        </Drawer>
      </Hidden>
      <Switch>
        <Route path="/account/my-alerts" exact component={Alerts} />
        <Route path="/account/my-favorites" exact component={FavoritePageContainer} />
        {
          businessUser &&
          <Route path="/account/payment" exact component={Payment} />
        }
        <Route
          path="/account/my-information"
          exact
          component={AccountInformation}
        />
        {
          !businessUser &&
          <Route
            path="/account/settings"
            exact
            component={Settings}
          />
        }
        <Redirect from="/account" to="/account/my-alerts" />
      </Switch>
    </>
  )
}

export default Account
