import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Cookies from 'js-cookie'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core'
import api from 'utils/api'
import roles from 'utils/roles'
import FacebookIcon from './assets/facebook.png'
import GoogleIcon from './assets/google.png'
import { fetchUser, fetchUserFavorites } from 'modules/User/actions'
import { hideLogin } from 'modules/UI/actions'
import { facebookLoginUrl, googleLoginUrl } from 'utils/auth'

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup.string().required('Password is required'),
})

const Login = () => {
  const [loading, setLoading] = useState()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const returnUrl = encodeURIComponent(`?return=${pathname}`)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const handleFormSubmit = (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    }
    setLoading(true)
    api
      .post('/auth/login', payload)
      .then((res) => {
        if (!res.error) {
          Cookies.set('access_token', res.accessToken, { expires: 1 })
          if (res.userRoles.find((r) => r === roles.ROLE_BUSINESS)) {
            window.location.href = '/business'
          }
          dispatch(fetchUser())
          dispatch(fetchUserFavorites())
          dispatch(hideLogin())
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message || 'Something went wrong, please try again ', {
          variant: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Box mb={4}>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          Login
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Box mb={3}>
          <FormControl error={!!errors.email} fullWidth variant="outlined">
            <TextField
              autoFocus
              defaultValue=""
              error={!!errors.email}
              fullWidth
              label="Email"
              name="email"
              inputRef={register}
              variant="outlined"
            />
            {!!errors.email && (
              <FormHelperText>{errors.email.message}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box mb={1}>
          <FormControl error={!!errors.password} fullWidth variant="outlined">
            <TextField
              defaultValue=""
              error={!!errors.password}
              fullWidth
              label="Password"
              name="password"
              inputRef={register}
              variant="outlined"
              type="password"
            />
            {!!errors.password && (
              <FormHelperText>{errors.password.message}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box mb={2}>
          <Link to="/reset-password" style={{ textDecoration: 'none' }}>
            <Typography variant="caption" color="textSecondary">
              Forgot password?
            </Typography>
          </Link>
        </Box>
        <Box mb={1.5}>
          <Button
            disabled={loading}
            variant="contained"
            color="secondary"
            fullWidth
            style={{ height: 50 }}
            disableElevation
            type="submit"
          >
            Login
          </Button>
        </Box>
      </form>
      <Box mb={1.5}>
        <Button
          variant="outlined"
          fullWidth
          style={{ height: 50 }}
          href={`${facebookLoginUrl}${returnUrl}`}
        >
          <img
            src={FacebookIcon}
            height={22}
            alt=""
            style={{ marginRight: 8 }}
          />
          Login with Facebook
        </Button>
      </Box>
      <Box>
        <Button
          link
          fullWidth
          variant="outlined"
          style={{ height: 50 }}
          href={`${googleLoginUrl}${returnUrl}`}
        >
          <img src={GoogleIcon} height={32} alt="" style={{ marginRight: 8 }} />
          Login with Google
        </Button>
      </Box>
    </>
  )
}

export default Login
