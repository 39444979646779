const isDevelop = process.env.NODE_ENV === 'development'

export const facebookLoginUrl = isDevelop ?
  'https://staging.hiddenunderground.com/oauth2/authorize/facebook?redirect_uri=localhost:3000/interstitial.html'
  :
  `${window.location.origin}/oauth2/authorize/facebook?redirect_uri=${window.location.origin}/interstitial.html`
  
export const googleLoginUrl = isDevelop ?
  'https://staging.hiddenunderground.com/oauth2/authorize/google?redirect_uri=localhost:3000/interstitial.html'
  :
  `${window.location.origin}/oauth2/authorize/google?redirect_uri=${window.location.origin}/interstitial.html`