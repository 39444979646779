import React from 'react'
import {
  makeStyles,
  TableCell,
  TableRow,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  definition: {
    fontSize: 13,
    fontWeight: 600,
  },
  value: {
    fontSize: 13,
  },
}))

const Definition = ({ id, value }) => {
  const classes = useStyles()
  return (
    <TableRow>
      <TableCell>
        <span className={classes.definition}>
          {id}
        </span>
      </TableCell>
      <TableCell align="right">
        <span className={classes.value}>
          {value}
        </span>
      </TableCell>
    </TableRow>
  )
}

export default Definition
