import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import {
  LocalOfferOutlined as OfferIcon,
  InfoOutlined as InfoIcon,
  Payment as PaymentIcon,
  Add as AddIcon,
  ReceiptOutlined as TransactionIcon,
  AssessmentOutlined as DashboardIcon,
  PeopleOutlined as UsersIcon,
} from '@material-ui/icons'
import { Drawer, MenuItem } from 'components'
import { showBusinessForm } from 'modules/UI/actions'
import { useAdminUser } from 'utils/roles'
import {CompanySelector} from 'components'
import LogoSrc from 'components/Logo/Logo.png'

const Sidebar = ({ businesses }) => {
  const dispatch = useDispatch()
  const isAdmin = useAdminUser()

  return (
    <Drawer>
      <Box pt={6} pb={4} width={300} display="flex" justifyContent="center">
        <NavLink to="/">
          <img src={LogoSrc} alt="" height={62} />
        </NavLink>
      </Box>
      {
        businesses &&
        <>
          <CompanySelector />
          <List>
            <MenuItem disabled={!businesses} icon={<DashboardIcon />} text="Dashboard" link="dashboard" />
            <MenuItem disabled={!businesses} icon={<OfferIcon />} text="Offers" link="offers" />
            <MenuItem disabled={!businesses} icon={<TransactionIcon />} text="Transactions" link="transactions" />
            <MenuItem disabled={!businesses} icon={<PaymentIcon />} text="Payments" link="payments" />
            <MenuItem disabled={!businesses} icon={<InfoIcon />} text="Business profile" link="profile" />
          </List>
        </>
      }
      <Box py={3}>
        <List>
          <ListItem button onClick={() => {
            dispatch(showBusinessForm())
          }} style={{ background: '#eee' }}>
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText>
              Create new business
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      {
        isAdmin &&
        <Box>
          <List>
            <MenuItem icon={<UsersIcon />} text="User List" link="user-list" />
            <MenuItem icon={<OfferIcon />} text="Coupons" link="coupons" />
          </List>
        </Box>
      }
    </Drawer>
  )
}

export default Sidebar
