import React, { useCallback, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { Box, Card, CardHeader, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import BusinessPage from '../../components/BusinessPage'
import { useUsers, useWidgetData } from './utils'
import Table from './components/Table'

const widgetList = [
  { key: 'sessions', label: 'Sessions' },
  { key: 'users', label: 'Users' },
  { key: 'businessUsers', label: 'Business Users' },
  { key: 'businesses', label: 'Businesses' },
  { key: 'promotionalCharges', label: 'Promotional Charges' },
  { key: 'promotionalChargesAmount', label: 'Promotional Charges ($)' },
  { key: 'creditCharges', label: 'Credit Charges' },
  { key: 'creditChargesAmount', label: 'Credit Charges ($)' },
]

const UserList = () => {
  const usersPerPage = 12
  const [currentPage, setCurrentPage] = useState(1)
  const [orderBy, setOrderBy] = useState('creditAmount')
  const [order, setOrder] = useState('desc')
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, 350)

  const { widgets } = useWidgetData()
  const { items, loadingItems, totalPages } = useUsers({ page: currentPage, order, orderBy, query: debouncedQuery, size: usersPerPage })

  const handleChange = useCallback(e => setQuery(e.target.value), [])
  const handleClear = useCallback(() => setQuery(''), [])

  return (
    <BusinessPage title="User list">
      <Box p={2}>
        <Grid container spacing={2}>
          {
            widgetList.map(w => (
              <Grid item xs={12} sm={6} lg={3}>
                <Card>
                  <CardHeader
                    title={w.label}
                    subheader={widgets[w.key] || '-'}
                    subheaderTypographyProps={{
                      variant: 'h6',
                      color: 'textPrimary',
                    }}
                    titleTypographyProps={{
                      variant: 'body2',
                      color: 'textSecondary',
                    }}
                  />
                </Card>
              </Grid>
            ))
          }
          <Grid xs={12} lg={12}>
            <Box p={2}>
              <TextField
                label="Search users"
                InputLabelProps={{
                  color: 'primary',
                }}
                InputProps={{
                  endAdornment: query ?
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClear}
                        size="small"
                      >
                        &times;
                      </IconButton>
                    </InputAdornment>
                    : undefined
                }}
                onChange={handleChange}
                margin="dense"
                value={query}
                variant="outlined"
                style={{ width: 300 }}
              />
            </Box>
            <Table
              rows={items}
              changeOrderBy={v => setOrderBy(v)}
              changeOrder={c => setOrder(c)}
              columns={[
                { key: 'action', label: '', sortable: false },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'provider', label: 'Provider', sortable: true },
                { key: 'email', label: 'Email', sortable: true },
                { key: 'phone', label: 'Phone', sortable: true },
                { key: 'businessCount', label: 'No of business', sortable: true, align: 'right' },
                { key: 'offerCount', label: 'No of offers', sortable: true, align: 'right' },
                { key: 'couponAmount', label: 'Coupon amount', sortable: true, align: 'right' },
                { key: 'creditAmount', label: 'Credit amount', sortable: true, align: 'right' },
              ]}
              loading={loadingItems}
              order={order}
              orderBy={orderBy}
            />
          </Grid>
          {
            totalPages > 1 &&
            <Grid xs={12} lg={12}>
              <Box display="flex" justifyContent="center" pt={4}>
                <Pagination page={currentPage} count={totalPages} onChange={(e, newPage) => setCurrentPage(newPage)} color="primary" />
              </Box>
            </Grid>
          }
        </Grid>
      </Box>
    </BusinessPage>
  )
}

export default UserList
