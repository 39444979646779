import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { Info } from '@material-ui/icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { period, periodLabels } from 'utils/constants';
import { dateTimeFormat, parsableDateTimeFormat } from 'utils/time';
import { fetchBusiness } from 'modules/Business/actions'; // Adjusted import

const schema = yup.object().shape({
  maxAmount: yup.number().min(1, 'Minimum of 1$ is required').required('Required'),
  maxClick: yup.number().min(1, 'Minimum of 1 click is required').required('Required'),
  startDate: yup.string().required('Start date is required'),
  endDate: yup.string().required('End date is required'),
});

const OfferBudget = ({ back, onClose, onComplete, values }) => {
  const dispatch = useDispatch();
  const { businessBudget, creditBalance: businessAccountBalance } = useSelector(state => state.business);
 
  // Ensure accountBalance is a number
  const business = useSelector(state => state.business)
  const accountBalance = parseFloat(businessAccountBalance) || 0;
  useEffect(() => {
    dispatch(fetchBusiness(business.businessId, true));
  }, [dispatch, business.businessId]);
  


 

  const { register, control, handleSubmit, errors, watch } = useForm({
    defaultValues: values.budget || {
      amountPeriod: period.daily,
      clickPeriod: period.daily,
      maxAmount: 5,
      maxClick: 5,
    },
    resolver: yupResolver(schema),
  });

  const startDate = watch('startDate');

  useEffect(() => {
    if (businessBudget) {
      dispatch(fetchBusiness(businessBudget.businessId, true));
    }
  }, [dispatch, businessBudget]);

  const handleFormSubmit = (data) => {
    onComplete(data);
  };
  

  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" component="h2">
          Set a total budget for your offers
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          You can allocate and adjust portions of this amount to each offer you
          create
          <Tooltip title={
            <>
              <Typography variant="h6">How your budget works</Typography>
              <Typography variant="body2">What you pay for</Typography>
              <Typography variant="caption" paragraph>Pay only when people click on your offer.</Typography>
              <Typography variant="body2">Set your max budget</Typography>
              <Typography variant="caption" paragraph>Costs can vary per click, but you won’t spend more than your max budget for the selected amount of time.</Typography>
              <Typography variant="body2">Set your max clicks</Typography>
              <Typography variant="caption" paragraph>Also manage your offer by the max number of clicks for the selected duration. Your offer will pause when the max is reached.</Typography>
            </>
          }>
            <IconButton size="small">
              <Info />
            </IconButton>
          </Tooltip>
        </Typography>
      
      </Box>

      <Grid container spacing={2} sm={12}>
        <Grid item container spacing={2} xs={12} md={8}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Max budget"
                defaultValue=""
                name="maxAmount"
                inputRef={register}
                variant="outlined"
                margin="dense"
                type="number"
                min={0}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{
                  min: 1
                }}
              />
              {errors.maxAmount && (
                <FormHelperText error>{errors.maxAmount.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth style={{ margin: '8px 0' }}>
              <InputLabel id="budget-period">Period</InputLabel>
              <Controller
                name="amountPeriod"
                control={control}
                as={
                  <Select
                    fullWidth
                    labelId="budget-period"
                    margin="dense"
                    variant="outlined"
                    label="Period"
                  >
                    {
                      Object.keys(period).map(key => (
                        <MenuItem value={period[key]}>
                          {key}
                        </MenuItem>
                      ))
                    }
                  </Select>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Max clicks"
                defaultValue=""
                name="maxClick"
                inputRef={register}
                variant="outlined"
                margin="dense"
                type="number"
                min={0}
                inputProps={{
                  min: 1
                }}
              />
              {errors.maxClick && (
                <FormHelperText error>{errors.maxClick.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth style={{ margin: '8px 0' }}>
              <InputLabel id="click-period">Period</InputLabel>
              <Controller
                name="clickPeriod"
                control={control}
                as={
                  <Select
                    fullWidth
                    labelId="click-period"
                    margin="dense"
                    variant="outlined"
                    label="Period"
                  >
                    {
                      Object.keys(period).map(key => (
                        <MenuItem value={period[key]}>
                          {key}
                        </MenuItem>
                      ))
                    }
                  </Select>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h6" component="h2">
                Set a time limit for your offers
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="startDate"
                control={control}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    ampm={false}
                    autoOk
                    variant="inline"
                    format={dateTimeFormat}
                    initialFocusedDate={moment().startOf('hour')}
                    minDate={moment(formattedDate, parsableDateTimeFormat)}
                    minDateMessage="Start time should be in the future"
                    inputVariant="outlined"
                    label="Start date"
                    margin="dense"
                    minutesStep={60}
                    placeholder={dateTimeFormat}
                    views={['date', 'hours']}
                    value={value ? moment(value, parsableDateTimeFormat) : null}
                    onChange={val => {
                      onChange(val ? val.format(parsableDateTimeFormat) : undefined)
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change start date',
                    }}
                  />
                )}
              />
              {
                errors.startDate &&
                <FormHelperText error>
                  {errors.startDate.message}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" error={!!errors.endDate}>
              <Controller
                name="endDate"
                control={control}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    ampm={false}
                    autoOk
                    initialFocusedDate={moment().startOf('hour')}
                    minDate={moment(startDate, parsableDateTimeFormat).add(1, 'day')}
                    minDateMessage="End time should be after the start time"
                    variant="inline"
                    format={dateTimeFormat}
                    inputVariant="outlined"
                    label="End date"
                    margin="dense"
                    minutesStep={60}
                    views={['date', 'hours']}
                    value={value ? moment(value, parsableDateTimeFormat) : null}
                    onChange={val => {
                      onChange(val ? val.format(parsableDateTimeFormat) : undefined)
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change end date',
                    }}
                  />
                )}
              />
              {
                errors.endDate &&
                <FormHelperText>
                  {errors.endDate.message}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={6} mb={1} display="flex" justifyContent="space-between">
        <Box>
      
          <Button onClick={onClose}>
            Cancel
          </Button>
        </Box>
           
        <Box display="flex" alignItems="center">
           {/* Display Account Balance Message */}
           {(() => {
    try {
      // Remove the dollar sign and convert to a number
      const balance = parseFloat(business.creditBalance.replace('$', ''));

      // Check if the balance is less than $5
      if (balance < 5) {
        return (
          <Typography variant="body2" color="error">
            Credit balance should be a minimum of $5. Please complete the offer setup and update the payment on your profile. Current balance: {business.creditBalance}
          </Typography>
        );
      } else {
        return (
          <Typography variant="body2" color="textSecondary">
            Account Balance: {business.creditBalance}
          </Typography>
        );
      }
    } catch (error) {
      // Handle any potential errors (e.g., if accountBalance is not in the expected format)
   
      return (
        <Typography variant="body2" color="error">
          Unable to display account balance. Please check your account settings.
        </Typography>
      );
    }
  })()}
          <Button
            color="primary"
            onClick={back}
            style={{ marginRight: '16px' }}
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            type="submit"
            style={{ marginRight: '16px' }}
          >
            Next
          </Button>
          
        </Box>
      </Box>
    </>
  )
}

export default OfferBudget
