import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  chipContainerList: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    width: '100%',
    margin: theme.spacing(3),
    maxWidth: '250px'
  },
  chip: {
    flex: 1
  },
  claim: {
    background: 'rgba(78, 215, 225, 0.15)',
  },
}));