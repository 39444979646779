import React from 'react'
import { Drawer as D, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: theme.shadows[2]
  }
}))

const Drawer = ({
  anchor = 'left',
  variant = 'permanent',
  children,
  isOpen = true,
}) => {
  const classes = useStyles()
  return (
    <D
      anchor={anchor}
      variant={variant}
      open={isOpen}
      classes={classes}
    >
      {children}
    </D>
  )
}

export default Drawer
