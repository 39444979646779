import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Page } from 'components'
import img from './404.svg'

const NotFound = () => (
  <>
    <Page>
      <Box display="flex" justifyContent="center">
        <Box maxWidth={400} width="100%" textAlign="center">
          <img src={img} alt="" maxWidth={350} width="100%" />
          <Typography variant="h3" component="h2">
            Page not found
          </Typography>
        </Box>
      </Box>
    </Page>
  </>
)

export default NotFound
