import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InternalHeader } from 'components'
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import Sidebar from '../../components/Sidebar'
import BusinessForm from '../../components/BusinessForm'
import * as uiActions from 'modules/UI/actions'

const BusinessCreator = () => {
  const dispatch = useDispatch()
  const showForm = useSelector(state => state.ui.showBusinessForm)

  useEffect(() => {
    dispatch(uiActions.showBusinessForm())
  }, [dispatch])

  return (
    <>
      <Sidebar />
      <main style={{ marginLeft: 300, minHeight: '90vh' }}>
        <InternalHeader title="Welcome to HiddenUnderground" />
        <Box p={3}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item sm={3}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Create business
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Create business and start creating new offers
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button color="primary" onClick={() => dispatch(uiActions.showBusinessForm())}>
                    Add business
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </main>
      {
        showForm &&
        <BusinessForm />
      }
    </>
  )
}

export default BusinessCreator