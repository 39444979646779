import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { Box, Button, Dialog, FormControl, FormHelperText, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import queryString from 'query-string'
import LogoSrc from 'components/Logo/Logo.png'
import api from 'utils/api'

const schema = yup.object().shape({
  password: yup.string().required('New password is required'),
  passwordAgain: yup.string().required('Type your new password again').oneOf([yup.ref('password'), null], `Passwords don't match`),
})

const m = {
  fail: 'fail',
  success: 'success',
}

const SetPassword = () => {
  const [message, setMessage] = useState()
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const parsedParams = queryString.parse(location.search)

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const handleFormSubmit = (values) => {
    api
      .post('/auth/reset-password', { password: values.password, token: parsedParams.token })
      .then(() => {
        setMessage(m.success)
        reset()
      })
      .catch(() => {
        setMessage(m.fail)
      })
  }

  useEffect(() => {
    if (message === m.success) {
      setTimeout(() => {
        history.push('/')
      }, 10000) // Redirect after 10 seconds
    }
  }, [message, history])

  if (!parsedParams.token) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Dialog fullWidth maxWidth="xs" open onClose={() => {}}>
          <Box p={matches ? 2 : 4} pt={matches ? 1 : 2}>
            <Box p={2} pb={4} display="flex" justifyContent="center">
              <Link to="/">
                <img src={LogoSrc} height={40} alt="HiddenUnderground logo" />
              </Link>
            </Box>
            <Typography variant="h6" style={{ fontWeight: 700 }}>
              Set new password
            </Typography>
            <br />
            <Typography variant="p" paragraph>
              Your token has expired, please try to reset your password again
            </Typography>
          </Box>
        </Dialog>
      </Box>
    )
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Dialog fullWidth maxWidth="xs" open onClose={() => {}}>
        <Box p={matches ? 2 : 4} pt={matches ? 1 : 2}>
          <Box p={2} pb={4} display="flex" justifyContent="center">
            <Link to="/">
              <img src={LogoSrc} height={40} alt="HiddenUnderground logo" />
            </Link>
          </Box>
          {message !== m.success ? (
            <>
              <Typography variant="h6" style={{ fontWeight: 700 }}>Set new password</Typography>
              <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                <Box my={2}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                  >
                    <TextField
                      autoFocus
                      defaultValue=""
                      fullWidth
                      inputRef={register}
                      label="New password"
                      name="password"
                      variant="outlined"
                      type="password"
                    />
                    {
                    errors.password &&
                      <FormHelperText error>
                        {errors.password.message}
                      </FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Box my={2}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                  >
                    <TextField
                      defaultValue=""
                      fullWidth
                      inputRef={register}
                      label="Repeat new password"
                      name="passwordAgain"
                      variant="outlined"
                      type="password"
                    />
                    {
                    errors.passwordAgain &&
                      <FormHelperText error>
                        {errors.passwordAgain.message}
                      </FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Button type="submit" variant="contained" color="primary" disableElevation>
                  Submit
                </Button>
              </form>
            </>
          ) : (
            <Box my={2}>
              <Typography color="primary">
                Thank you! Your password has been reset. Redirecting to homepage...
              </Typography>
            </Box>
          )}
        </Box>
      </Dialog>
    </Box>
  )
}

export default SetPassword
