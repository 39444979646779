import React from 'react'
import { Box } from '@material-ui/core'
import { useIsMobile } from 'utils/UI'

const Barcode = ({ code }) => {
  const isMobile = useIsMobile()
  const h = 400
  const w = 400
  return (
    <Box borderRadius={4} mb={1} style={{ border: isMobile ? 0 : '1px solid #ddd'}} display="flex" alignItems="center" flexDirection="column">
      <Box width={isMobile ? '100%' : w} minHeight={h}>
        <img src={`/api/barcodes/qrcode?code=${code}&width=${w}&height=${h}`} alt="qrcode" style={{ maxWidth: '100%' }} />
      </Box>
    </Box>
  )
}

export default Barcode
