import React, { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import api from 'utils/api'

const AddressSearch = ({ onSelect, label, value }) => {
  const [cache, setCache] = useState({})
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState(value || '')
  const [debouncedQuery] = useDebounce(query, 250);

  useEffect(() => {
    setQuery(value || '')
  }, [value])

  const handleChange = (e, val, reason) => {
    if (reason !== 'reset'){
      setQuery(val)
    }
  }

  const searchAddress = useCallback(async () => {
    const key = `k_${debouncedQuery}`
    if (cache[key]) {
      return cache[key]
    }
    const options = await api.get(`/geo/autocomplete?address=${debouncedQuery}`)
    const parsedOptions = options.map(o => ({ id: o.placeId, description: o.description }))
    setCache({...cache, [key]: parsedOptions })
    return parsedOptions
  }, [debouncedQuery, cache])

  useEffect(() => {
    async function getResults() {
      if (!debouncedQuery) {
        setOptions([])
        return
      }
      setLoading(true)
      setOptions([])
  
      try {
        const newOptions = await searchAddress()
        setOptions(newOptions)
      } catch(e) {
        console.error(e)
      }
      setLoading(false)
    }
    getResults()
  }, [debouncedQuery, searchAddress])

  const handleSelect = async (e, val) => {
    try {
      const place = await api.get(`/geo/place?placeId=${val.id}`)
      onSelect(place)
    } catch (err) {
      console.error(`Cannot get place details for place ${val.id}`, err)
    }
  }

  return (
    <Autocomplete
      filterOptions={x => x}
      loading={loading}
      inputValue={query}
      onChange={handleSelect}
      onInputChange={handleChange}
      getOptionLabel={o => o.description}
      options={options}
      size="small"
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={label}
          InputLabelProps={{
            color: 'primary',
          }}
          variant="outlined"
        />
      )}
    />
  )
}

export default AddressSearch
