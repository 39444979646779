import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Badge, IconButton } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/FavoriteBorder';

const FavoritesIndicator = () => {
  const showIndicator = useSelector(state => state.ui.showFavoriteBadge)

  return (
    <IconButton
      component={Link}
      to="/account/my-favorites"
      style={{ marginRight: 16 }}
      aria-label={"favorites"}
    >
      <Badge variant="dot" invisible={!showIndicator} color="error">
        <FavoriteIcon />
      </Badge>
    </IconButton>  
  )
}

export default FavoritesIndicator
