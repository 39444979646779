import * as types from './actionTypes'

export const showLogin = (loginType) => ({
  type: types.SHOW_LOGIN_FORM,
  data: loginType,
})

export const hideLogin = () => ({
  type: types.HIDE_LOGIN_FORM,
})

export const showBusinessForm = (isEdit = false) => ({
  type: types.SHOW_BUSINESS_FORM,
  isEdit,
})

export const hideBusinessForm = () => ({
  type: types.HIDE_BUSINESS_FORM,
})

export const showFavoriteBadge = () => ({
  type: types.SHOW_FAVORITE_BADGE,
})

export const hideFavoriteBadge = () => ({
  type: types.HIDE_FAVORITE_BADGE,
})
