import React from 'react'
import { Box, Drawer, List } from '@material-ui/core'
import {
  Payment as PaymentIcon,
  InfoOutlined as InfoIcon,
  NotificationsActiveOutlined as AlertIcon,
  FavoriteBorderOutlined as FavIcon,
} from '@material-ui/icons'
import { MenuItem } from 'components'
import LogoSrc from 'components/Logo/Logo.png'

const MobileMenu = ({ isOpen, onClose }) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box p={2}>
        <img src={LogoSrc} alt="" height={50} />
      </Box>
      <List style={{ minWidth: 300 }}>
        <MenuItem
          icon={<AlertIcon />}
          text="My alerts"
          link="/account/my-alerts"
        />
        <MenuItem
            icon={<FavIcon />}
            text="My favorites"
            link="/account/my-favorites" />
        <MenuItem
          icon={<InfoIcon />}
          text="My information"
          link="/account/my-information"
        />
        <MenuItem
          icon={<PaymentIcon />}
          text="Payment"
          link="/account/payment"
        />
      </List>
    </Drawer>
  )
}

export default MobileMenu
