import React, { useEffect, useState } from 'react'
import { NavLink, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useIdleTimer } from 'react-idle-timer'
import Cookies from 'js-cookie'
import { Box, Button, Hidden, IconButton } from '@material-ui/core'
import SearchForm from './components/SearchForm'
import FavoritesIndicator from './components/FavoritesIndicator'
import LocationSwitcher from './components/LocationSwitcher'
import UserIcon from './components/UserIcon'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LoginButton from './components/LoginButton'
import SignupButton from './components/SignupButton'
import { Container } from 'components'
import * as uiActions from 'modules/UI/actions'
import { AuthPopup } from 'modules'
import { AuthPopupEnum } from 'utils/constants'
import { useIsMobile } from 'utils/UI'
import LogoSrc from 'components/Logo/Logo.png'
import LogoSmallSrc from 'components/Logo/LogoSmall.png'
import DropdownMenu from './components/DropdownMenu'
import api from 'utils/api'


const Header = () => {
  const history = useHistory()
  const [dropdownMenuAnchor, setDropdownMenuAnchor] = useState(null)
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()
  const user = useSelector(state => state.user)
  const showAuthPopup = useSelector(state => state.ui.showAuthPopup)
  const match = useRouteMatch('/')
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  useIdleTimer({
    timeout: 1000 * 60 * 35,
    onActive: () => {
      api.post('/geo', user.location)
    },
    debounce: 500
  })

  useEffect(() => {
    if (user.isFetching && !user.details && !Cookies.get('cookie-consent')) {
      enqueueSnackbar(
        'HiddenUnderGround uses cookies on the website for marketing purposes. They help us understand how you use our website, personalize content, and improvide your online experience. By clicking Accept, you agree that we and our partners may store and access cookies on your device for these purposes. California law considers some of these activities to be a sale of personal data. If you would like to learn more about how we handle your personal information, click here to review our privacy policy.',
        {
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          persist: true,
          variant: 'default',
          action: (
            <>
              <Button color="secondary" size="small" variant="contained" onClick={() => {
                Cookies.set('cookie-consent', '1')
                closeSnackbar()        
              }}>
                Accept
              </Button>
              <Button color="secondary" size="small" onClick={() => {
                history.push('/privacy')
              }}>
                Learn more
              </Button>
            </>
          )
        }
      )
    }
  }, [closeSnackbar, enqueueSnackbar, history, user.details, user.isFetching])

  return (
    <header style={{ position: isMobile ? 'static' : 'fixed', width: '100%', top: 0, background: '#fff', zIndex: 1000 }}>
      <Container>
        <Box my={isMobile ? 1 : 3} display="flex" alignItems="center" justifyContent="space-between" height={48}>
          <Box display="flex" alignItems="center">
            {
              !match.isExact &&
                <Box mr={isMobile ? 0 : 4}>
                  <NavLink to="/">
                    {
                      isMobile ?
                      <img src={LogoSmallSrc} height={50} alt="HiddenUnderground logo" />
                      :
                      <img src={LogoSrc} height={50} alt="HiddenUnderground logo" />
                    }
                  </NavLink>
                </Box>
            }
            <Hidden smDown>
              <Route path="/search">
                <SearchForm />
              </Route>
            </Hidden>
          </Box>
          <Box display="flex" alignItems="center">
            <LocationSwitcher />
            {
              user.details ?
              <>
                <FavoritesIndicator />
                <UserIcon
                  avatar={user.details.imageUrl}
                  name={user.details.firstName || user.details.email || 'a'}
                  handleClick={(e) => {
                    setDropdownMenuAnchor(e.currentTarget)
                  }}
                />
                <DropdownMenu
                  anchorElement={dropdownMenuAnchor}
                  onClose={() => setDropdownMenuAnchor(null)}
                />
              </>
              :
              <>
                <Hidden smDown>
                  <LoginButton />
                  <SignupButton />
                </Hidden>
                <Hidden mdUp>
                  <IconButton onClick={() => dispatch(uiActions.showLogin(AuthPopupEnum.login))}>
                    <AccountCircleIcon />
                  </IconButton>
                </Hidden>
              </>
            }
          </Box>
        </Box>
        <Hidden mdUp>
          <Box mt={3}>
            <Route path="/search">
              <SearchForm full />
            </Route>
          </Box>
        </Hidden>
      </Container>
      {
        !!showAuthPopup &&
        <AuthPopup />
      }
    </header>
  )
}

export default Header