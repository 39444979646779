import { useState, useEffect } from 'react'
import useSWR from 'swr'
import api from 'utils/api'

export const useTransactions = ({
  businessId,
  endDate,
  order,
  orderBy,
  page,
  size,
  startDate,
}) => {
  const [items, setItems] = useState([])
  const [totalPages, setTotalPages] = useState()
  const transactionsRoute = `/ledger/${businessId}/list?startDate=${startDate}&endDate=${endDate}&size=${size}&page=${page - 1}&sort=${orderBy},${order}`
  const datesValid = Boolean(startDate) && Boolean(endDate)
  const { data: trans, isValidating } = useSWR(datesValid ? transactionsRoute : null, () => api.get(transactionsRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    if (trans) {
      setItems(trans.content)
      setTotalPages(trans.totalPages)
    }
  }, [trans])

  return {
    items,
    loading: !trans && isValidating,
    totalPages,
  }
}

export const useWidgetData = ({
  businessId,
  endDate,
  startDate,
}) => {
  const [widgets, setWidgets] = useState({})
  const widgetsRoute = `/ledger/${businessId}/widgets?startDate=${startDate}&endDate=${endDate}`
  const datesValid = Boolean(startDate) && Boolean(endDate)
  const { data, isValidating, error } = useSWR(datesValid ? widgetsRoute : null, () => api.get(widgetsRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    if (data) {
      setWidgets(data)
    } else {
      setWidgets({})
    }
  }, [data])

  return {
    loadingWidgets: !data && isValidating,
    widgetError: error,
    widgets,
  }
}