import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryParam } from 'use-query-params'
import { Box, Button, Chip, makeStyles, Typography } from '@material-ui/core'
import { QueryBuilder } from '@material-ui/icons'
import moment from 'moment'
import { getQuantityUrgencyColor, getTimeUrgencyColor } from 'utils/UI'
import { dateFormat, parsableDateTimeFormat } from 'utils/time'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 0,
    paddingTop: 0,
    flex: '1 1 40px',
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
  },
  businessButton: {
    background: '#fff',
    dispaly: 'inline-block',
    padding: theme.spacing(0.5),
    '&:hover': {
      background: '#fff',
    },
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  claim: {
    background: props => getQuantityUrgencyColor(props.quantity, theme),
  },
  time: {
    background: props => getTimeUrgencyColor(props.daysRemaining, theme),
  },
}))

const getEndsIn = (days, hours, expirationTime) => {
  if (hours < 0) {
    return 'Expired'
  }
  if (hours < 2) {
    return `Ends in 1 hour`
  }
  if (hours < 24) {
    return `Ends in ${hours} hours`
  }
  if (days === 1) {
    return 'Ends tomorrow'
  }
  if (days < 14) {
    return `Ends in ${days} days`
  }
  return expirationTime ? `Ends on ${expirationTime.format(dateFormat)}` : 'N/A'
}

function OfferHeader({ isPreview, offer }) {
  const expirationTime = isPreview ? moment(offer.endDate) : moment(moment.utc(offer.endDate, parsableDateTimeFormat).toISOString())
  const currentTime = moment().local()
  const hoursRemaining = Math.ceil(expirationTime.diff(currentTime, 'hours', true))
  const daysRemaining = Math.floor(hoursRemaining / 24)

  const classes = useStyles({ daysRemaining, quantity: offer.claimsRemaining })
  const [, setBusinessId] = useQueryParam('businessId')
  const location = useLocation()
  const history = useHistory()
  const isNotSearch = location.pathname !== '/search'

  const handleBusinessClick = (id) => {
    if (isPreview) {
      return
    }
    if (isNotSearch) {
      history.push(`/search?businessId=${id}`)
      return
    }
    setBusinessId(id)
  }

  const qty = isPreview ? 100 : offer.claimsRemaining
  const qtyLabel = qty ? `${qty} left` : 'depleted'

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      p={2}
    >
      <Box display="flex">
        <Box>
          <Box>
            <Typography variant="h5" component="h3">
              {`${offer.headline1}${
                offer.headline2 ? ' | ' + offer.headline2 : ''
              }`}
            </Typography>
          </Box>
          <Box>
            <Typography>{offer.distance || 'Global'}</Typography>
          </Box>
        </Box>
        <Box ml={2}>
          <Button
            className={classes.businessButton}
            onClick={() => handleBusinessClick(offer.bussinessId)}
          >
            <img
              height={36}
              src={offer.businessImageUrl}
              alt={offer.businessId}
            />
          </Button>
        </Box>
      </Box>
      <Box className={classes.chips}>
        <Chip className={classes.claim} label={qtyLabel} />
        <Chip className={classes.time} label={getEndsIn(daysRemaining, hoursRemaining, expirationTime)} icon={<QueryBuilder />} variant="outlined" />
      </Box>
    </Box>
  )
}

export default OfferHeader
