import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import { Box, useMediaQuery } from '@material-ui/core'
import { Container, Footer, Header } from 'components'

const Page = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'))
  const location = useLocation()
  const parsedParams = queryString.parse(location.search)

  useEffect(() => {
    if (parsedParams.error) {
      enqueueSnackbar(`We could not log you in. \n Reason: ${parsedParams.error}`, { variant: 'error', style: { whiteSpace: 'pre-line' }} )
    }
  }, [enqueueSnackbar, parsedParams.error])

  return (
    <>
      <Header />
      <Container>
        <Box mt={matches ? 15 : 3} minHeight="50vh">
          {children}
        </Box>
        <Footer />
      </Container>
    </>
  )
}

export default Page
