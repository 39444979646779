import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Card, CardContent, CardHeader, TableContainer, Table, TableBody } from '@material-ui/core'
import Definition from '../Definition'

const Owner = () => {
  const ownerData = useSelector(state => state.business.userInfo)
  return (
    <Box my={2}>
      <Card>
        <CardHeader subheader="Business owner" />
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <Definition id="First Name" value={ownerData.firstName} />
                <Definition id="Last Name" value={ownerData.lastName} />
                <Definition id="Job" value={ownerData.job} />
                <Definition id="Email" value={ownerData.email} />
                <Definition id="Phone" value={ownerData.phone} />
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Owner
