import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core'
import api from 'utils/api'
import * as businessActions from 'modules/Business/actions'

const AddBalance = ({ businessId, onClose }) => {
  const [coupon, setCoupon] = useState()
  const [error, setError] = useState()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handleCouponChange = useCallback(
    (e) => {
      setCoupon(e.target.value)
    },
    [setCoupon]
  )

  const handleSubmit = useCallback(() => {
    api
      .post(`/business/${businessId}/applyCoupon/${coupon}`)
      .then(() => {
        enqueueSnackbar('Coupon applied', { variant: 'success' })
        onClose()
        dispatch(businessActions.fetchBusiness(businessId, true))
      })
      .catch(() => {
        setError('Coupon is not valid')
      })
  }, [coupon, dispatch, businessId, enqueueSnackbar, onClose])

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Enter promo code</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={!!error}
            helperText={error}
            label="Coupon code"
            variant="outlined"
            onChange={handleCouponChange}
            value={coupon}
            margin="dense"
            style={{ marginTop: 4 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!coupon}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default AddBalance
