import React, { useState } from 'react'
import {
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import LocationForm from '../LocationForm'

function truncate (str, n) {
  return (str.length > n) ? str.substr(0, n-1) + '…' : str
};

const MobileSwitcher = ({ location }) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <IconButton onClick={() => setIsVisible(true)}>
        <RoomOutlinedIcon />
        {
          location ?
            <Typography variant="body2" textTransform="normal">
              {truncate(location.city, 12)}
            </Typography>
            :
            <Typography variant="body2" textTransform="normal">
              Change location
            </Typography>
        }

      </IconButton>
      {
        isVisible &&
        <Dialog open onClose={() => setIsVisible(false)} fullWidth>
          <LocationForm location={location} onClose={() => setIsVisible(false)} />
        </Dialog>
      }
    </>
  )
}

export default MobileSwitcher
