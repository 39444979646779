import React, { useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useQueryParam } from 'use-query-params'
import { Box, Button, CardMedia, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    background: '#fff',
    padding: theme.spacing(0.5),
    '&:hover': {
      background: '#fff',
    },
  },
  image: {
    display: 'block',
  }
}))

const isDefaultImage = url => url.indexOf('unsplash.com') !== -1

const OfferCover = ({ company, companyImage, image, isPreview }) => {
  const classes = useStyles()
  const [, setBusinessId] = useQueryParam('businessId')
  const location = useLocation()
  const isNotSearch = location.pathname !== '/search'
  const history = useHistory()

  let imageUrl = image

  if (image && isDefaultImage(image)) {
    imageUrl += '&w=500'
  }

  const handleClick = useCallback(() => {
    if (isPreview) {
      return
    }
    if (isNotSearch) {
      history.push(`/search?businessId=${company}`)
      return
    }
    setBusinessId(company)
  }, [company, history, isNotSearch, isPreview, setBusinessId])

  return (
    <CardMedia image={imageUrl}>
      <Box p={2} pb={4} minHeight={100}>
        <Button className={classes.button} onClick={handleClick} size="small">
          <img className={classes.image} height={36} src={companyImage} alt={company} />
        </Button>
      </Box>
    </CardMedia>
  )
}

export default OfferCover
