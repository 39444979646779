import React, { useState } from 'react'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { period } from 'utils/constants'
import api from 'utils/api'
import * as businessActions from 'modules/Business/actions'

const schema = yup.object().shape({
  maxAmount: yup.number().min(1, 'Minumum of 1$ is required').required('Required'),
  maxClick: yup.number().min(1, 'Minumum of 1 click is required').required('Required'),
})

const BudgetForm = ({ businessBudget, businessId, onClose }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [submitting, setSubmitting] = useState(false)
  const { register, control, handleSubmit, errors } = useForm({
    defaultValues: businessBudget || {
      amountPeriod: period.weekly,
      clickPeriod: period.daily,
      maxAmount: 25,
      maxClick: 10,
    },
    resolver: yupResolver(schema),
  })

  const handleFormSubmit = (data) => {
    const payload = {
      ...data,
      businessId,
    }
    setSubmitting(true)
    api
      .post(`/business/${businessId}/budget`, payload)
      .then(() => {
        enqueueSnackbar('Your business budget was set', { variant: 'success' })
        dispatch(businessActions.updateBusinessBudget(payload))
        onClose()
      })
      .catch(() => {
        setSubmitting(false)
        enqueueSnackbar('Your business budget was not set', { variant: 'error' })
      })
  }

  return (
    <Dialog open fullWidth maxWidth="lg">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <Box mb={4}>
            <Typography variant="h6" component="h2">
              Set a total budget for your business
            </Typography>
            {/* <Typography variant="body2" paragraph>
              You can allocate and adjust portions of this amount to each offer you
              create
            </Typography> */}
          </Box>
          <Grid container spacing={3} sm={12} lg={6}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Max budget"
                  defaultValue=""
                  name="maxAmount"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                  type="number"
                  min={0}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
                {errors.maxAmount && (
                  <FormHelperText error>{errors.maxAmount.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth style={{ margin: '8px 0' }}>
                <InputLabel id="budget-period">Period</InputLabel>
                <Controller
                  name="amountPeriod"
                  control={control}
                  as={
                    <Select
                      fullWidth
                      labelId="budget-period"
                      margin="dense"
                      variant="outlined"
                      label="Period"
                    >
                        {
                          Object.keys(period).filter(k => k !== 'total').map(key => (
                            <MenuItem value={period[key]}>
                              {key}
                            </MenuItem>
                          ))
                        }
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sm={12} lg={6}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Max clicks"
                  defaultValue=""
                  name="maxClick"
                  inputRef={register}
                  variant="outlined"
                  margin="dense"
                  type="number"
                  min={0}
                />
                {errors.maxClick && (
                  <FormHelperText error>{errors.maxClick.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth style={{ margin: '8px 0' }}>
                <InputLabel id="click-period">Period</InputLabel>
                <Controller
                  name="clickPeriod"
                  control={control}
                  as={
                    <Select
                      fullWidth
                      labelId="click-period"
                      margin="dense"
                      variant="outlined"
                      label="Period"
                    >
                        {
                          Object.keys(period).filter(k => k !== 'total').map(key => (
                            <MenuItem value={period[key]}>
                              {key}
                            </MenuItem>
                          ))
                        }
                    </Select>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box bgcolor="background.section" p={2} mt={4}>
            <Typography variant="h6" gutterBottom>
              How your budget works
            </Typography>
            <Divider style={{ marginBottom: 20 }} />
            <Grid container spacing={10} justify="space-between">
              <Grid item sm={4}>
                <Typography variant="subtitle2">What you pay for</Typography>
                <Typography variant="caption" color="textSecondary">
                  Pay only when people click on your offer.
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography variant="subtitle2">Set your max budget</Typography>
                <Typography variant="caption" color="textSecondary">
                  Costs can vary per click, but you won’t spend more than your max
                  budget for the selected amount of time.
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography variant="subtitle2">Set your max clicks</Typography>
                <Typography variant="caption" color="textSecondary">
                  Also manage your offer by the max number of clicks for the
                  selected duration. Your offer will pause when the max is reached.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={submitting}
            onClick={onClose}
            >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            type="submit"
          >
            Set budget
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default BudgetForm
