import { useState, useEffect } from 'react'
import useSWR from 'swr'
import api from 'utils/api'

export const useCoupons = ({
  order,
  orderBy,
  page,
  query,
  size,
}) => {
  const [items, setItems] = useState([])
  const [totalPages, setTotalPages] = useState()
  const couponsRoute = `/coupon?size=${size}&page=${page - 1}&sort=${orderBy},${order}`
  const { data: coupons, isValidating, mutate, } = useSWR(couponsRoute, () => api.get(couponsRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    if (coupons) {
      setItems(coupons.content)
      setTotalPages(coupons.totalPages)
    }
  }, [coupons])

  return {
    coupons: items,
    loadingCoupons: !coupons && isValidating,
    mutate,
    totalPages,
  }
}
