import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { List, ListItem, ListItemIcon, Popover, ListItemText, makeStyles } from '@material-ui/core'
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded'
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import * as userActions from 'modules/User/actions'
import { useBusinessUser } from 'utils/roles'

const useStyles = makeStyles(() => ({
  list: {
    fontSize: 15,
  },
  icon: {
    minWidth: 40,
  }
}))

const Text = ({ children }) => {
  const classes = useStyles()
  return (
    <ListItemText classes={{ primary: classes.list }}>
      {children}
    </ListItemText>
  )
}

const Icon = ({ children }) => {
  const classes = useStyles()
  return (
    <ListItemIcon className={classes.icon}>
      {children}
    </ListItemIcon>
  )
}

const DropdownMenu = ({
  anchorElement,
  onClose,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const businessUser = useBusinessUser()
  return (
    <Popover
      anchorEl={anchorElement}
      onClose={onClose}
      open={Boolean(anchorElement)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List className={classes.list}>
        {
          businessUser &&
          <ListItem button component={NavLink} to="/business">
            <Icon>
              <DashboardRoundedIcon />
            </Icon>
            <Text>
              My Business
            </Text>
          </ListItem>
        }
        <ListItem button component={NavLink} to="/account">
          <Icon>
            <PersonRoundedIcon />
          </Icon>
          <Text>
            My profile
          </Text>
        </ListItem>
        <ListItem button onClick={() => {
          dispatch(userActions.logoutUser())
        }}>
          <Icon>
            <ExitToAppRoundedIcon />
          </Icon>
          <Text>
            Logout
          </Text>
        </ListItem>
      </List>
    </Popover>
  )
}

export default DropdownMenu
