import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { Payment as PaymentIcon } from '@material-ui/icons';
import AddCard from './components/AddCard';
import AddBalance from './components/AddBalance';
import AddPromo from './components/AddPromo';
import SetReload from './components/SetReload';
import BusinessPage from '../../components/BusinessPage';
import { fetchBusiness } from 'modules/Business/actions';
import { Link } from 'react-router-dom';
import PaymentForm from 'modules/Business/modules/PaymentForm';
import { Elements } from '@stripe/react-stripe-js';  // Import Elements
import { loadStripe } from '@stripe/stripe-js';  // Import loadStripe

// Create a Stripe promise
const stripePromise = loadStripe('pk_live_51PGQFXIFGpWpW0agaaNKIwYLzWRVhoikPaPmspUDX7v8akxc1UAUFeiuNHvAcg1osLJrgpSRt5fAWC1o5wXvnxya00azG05oUz');  // Replace with your Stripe publishable key

const Credits = () => {
  const dispatch = useDispatch();
  const [showCardSelection, setShowCardSelection] = useState(false);
  const [showBalancePopup, setShowBalancePopup] = useState(false);
  const [showReloadPopup, setShowReloadPopup] = useState(false);
  const [showPromoPopup, setShowPromoPopup] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const userCards = useSelector(state => get(state, 'user.details.sources.data', []));
  const userHasCard = Boolean(userCards.length);
  const business = useSelector(state => state.business);

  useEffect(() => {
    dispatch(fetchBusiness(business.businessId, true));
  }, [dispatch, business.businessId]);

  return (
    <BusinessPage title="Payments">
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card>
              <CardHeader
                title="Account balance"
                subheader={`${business.accountBalance}`}
                subheaderTypographyProps={{ variant: 'h6', color: 'textPrimary' }}
                titleTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
              />
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card>
              <CardHeader
                title="Credit balance"
                subheader={`${business.creditBalance}`}
                subheaderTypographyProps={{ variant: 'h6', color: 'textPrimary' }}
                titleTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
              />
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card>
              <CardHeader
                title="Promotional balance"
                subheader={`${business.couponBalance}`}
                subheaderTypographyProps={{ variant: 'h6', color: 'textPrimary' }}
                titleTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
              />
            </Card>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card>
              <CardHeader
                title="Reload amount"
                subheader={`${business.reloadAmount}`}
                subheaderTypographyProps={{ variant: 'h6', color: 'textPrimary' }}
                titleTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
              />
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="stretch">
          {!business.card ? (
            <Grid item lg={6} md={8} sm={12}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Current payment method
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {userHasCard
                      ? 'Select credit card from your account'
                      : 'You need to add credit card to your business profile '}
                  </Typography>
                </CardContent>
                <CardActions>
                  {userHasCard ? (
                    <Button color="primary" variant="contained" onClick={() => setShowCardSelection(true)}>
                      Select card
                    </Button>
                  ) : (
                    <Button color="primary" onClick={() => setShowPaymentForm(true)}>
                      Add a card
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ) : (
            <Grid item lg={6} md={10} sm={12} xs={12}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Current payment method
                  </Typography>
                  <Box pt={2} display="flex" alignItems="center">
                    <PaymentIcon fontSize="small" style={{ marginRight: 4 }} />
                    <Typography variant="body1" color="textSecondary" component="p">
                      {business.card.brand} **** **** **** {business.card.last4} - {business.card.expMonth}/{business.card.expYear}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Button color="primary" variant="contained" onClick={() => setShowBalancePopup(true)}>
                      Add balance
                    </Button>
                    <Button color="primary" onClick={() => setShowPromoPopup(true)}>
                      Enter promo code
                    </Button>
                    <Button color="primary" onClick={() => setShowReloadPopup(true)}>
                      Set reload
                    </Button>
                    <Button color="primary" onClick={() => setShowCardSelection(true)}>
                      Change card
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
        {showCardSelection && (
          <AddCard
            businessId={business.businessId}
            cards={userCards}
            card={get(business, 'card.id')}
            onClose={() => setShowCardSelection(false)}
          />
        )}
        {showBalancePopup && <AddBalance businessId={business.businessId} onClose={() => setShowBalancePopup(false)} />}
        {showReloadPopup && <SetReload businessId={business.businessId} onClose={() => setShowReloadPopup(false)} />}
        {showPromoPopup && <AddPromo businessId={business.businessId} onClose={() => setShowPromoPopup(false)} />}

        {/* Conditionally render the PaymentForm component */}
        {showPaymentForm && (
          <Elements stripe={stripePromise}>  {/* Wrap PaymentForm in Elements */}
            <PaymentForm onClose={() => setShowPaymentForm(false)} />
          </Elements>
        )}
      </Box>
    </BusinessPage>
  );
};

export default Credits;