import React from 'react'
import {
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import OfferHeader from './components/OfferHeader'
import OfferActions from "../OfferActions"

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 0,
    paddingTop: 0,
    flex: '1 1 40px',
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
  },
}))

const OfferListItem = ({ isPreview, offer, revalidateList }) => {
  const classes = useStyles()
  return (
    <Card style={{ opacity: isPreview || offer.active ? 1 : 0.3 }}>
      <OfferHeader offer={offer} isPreview={isPreview} />
      <CardContent className={classes.content}>
        <Typography variant="body2" color="textSecondary" component="p">
          {offer.description}
        </Typography>
      </CardContent>
      <OfferActions
        {...offer}
        isPreview={isPreview}
        revalidateList={revalidateList}
      />
    </Card>
  )
}

export default OfferListItem
