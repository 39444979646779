import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers'
import Cookies from 'js-cookie'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import * as yup from 'yup'
import FacebookIcon from './assets/facebook.png'
import GoogleIcon from './assets/google.png'
import { facebookLoginUrl, googleLoginUrl } from 'utils/auth'
import api from 'utils/api'
import * as uiActions from 'modules/UI/actions'

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup.string().required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  terms: yup.boolean().oneOf([true], 'Please review and accept the terms and conditions'),
  businessAccount: yup.boolean(),
})

const Register = ({ onSuccess }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [isEmail, setIsEmail] = useState(false)
  const [isBusiness, setIsBusiness] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    Cookies.set('oauth2_authority', isBusiness ? '2' : '1')
  }, [isBusiness])

  const handleFormSubmit = (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    }
    api
      .post('/auth/signup', payload)
      .then(() => {
        dispatch(uiActions.hideLogin())
        if (onSuccess) {
          onSuccess()  // Call the success callback to redirect to the success page
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
      })
  }

  // Define returnUrl here
  const url = true ? '/' : '/business'
  const returnUrl = encodeURIComponent(`?return=${url}`)

  return (
    <>
      <Box mb={1}>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          Register for free
        </Typography>
      </Box>
      <Box my={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: 8 }}>
            I want to register for
          </FormLabel>
          <RadioGroup
            aria-label="account-type"
            name="accountType"
            value={isBusiness ? 'b' : 'p'}
            onChange={(e, v) => setIsBusiness(v === 'b')}
          >
            <FormControlLabel
              value="p"
              control={<Radio size="small" />}
              label="Personal account"
            />
            <FormControlLabel
              value="b"
              control={<Radio size="small" />}
              label="Business account"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mb={1.5}>
        <Button
          variant="outlined"
          fullWidth
          style={{ height: 50 }}
          href={`${facebookLoginUrl}${returnUrl}`}
        >
          <img
            src={FacebookIcon}
            height={22}
            alt="Facebook"
            style={{ marginRight: 8 }}
          />
          Register with Facebook
        </Button>
      </Box>
      <Box mb={1.5}>
        <Button
          variant="outlined"
          fullWidth
          style={{ height: 50 }}
          href={`${googleLoginUrl}${returnUrl}`}
        >
          <img src={GoogleIcon} height={32} alt="Google" style={{ marginRight: 8 }} />
          Register with Google
        </Button>
      </Box>
      <Box mb={1.5}>
        <Button
          onClick={() => setIsEmail(true)}
          variant={isEmail ? 'contained' : 'outlined'}
          color={isEmail ? 'primary' : 'default'}
          fullWidth
          style={{ height: 50 }}
          disableElevation
        >
          Register with email
        </Button>
      </Box>
      {isEmail && (
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <Box>
            <Box mb={1}>
              <FormControl error={!!errors.email} fullWidth variant="outlined">
                <TextField
                  autoFocus
                  defaultValue=""
                  error={!!errors.email}
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  inputRef={register}
                  variant="outlined"
                  inputProps={{
                    style: { fontSize: 14 },
                  }}
                />
                {!!errors.email && (
                  <FormHelperText>{errors.email.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mb={1}>
              <FormControl
                error={!!errors.password}
                fullWidth
                variant="outlined"
              >
                <TextField
                  defaultValue=""
                  error={!!errors.password}
                  fullWidth
                  label="Password"
                  margin="dense"
                  name="password"
                  inputRef={register}
                  variant="outlined"
                  type="password"
                />
                {!!errors.password && (
                  <FormHelperText>{errors.password.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mb={1}>
              <FormControl
                error={!!errors.passwordConfirmation}
                fullWidth
                variant="outlined"
              >
                <TextField
                  defaultValue=""
                  error={!!errors.passwordConfirmation}
                  fullWidth
                  label="Confirm Password"
                  margin="dense"
                  name="passwordConfirmation"
                  inputRef={register}
                  variant="outlined"
                  type="password"
                />
                {!!errors.passwordConfirmation && (
                  <FormHelperText>
                    {errors.passwordConfirmation.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl error={!!errors.terms}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={register}
                      color="primary"
                      name="terms"
                      defaultValue={false}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      I agree to the Terms & Conditions
                    </Typography>
                  }
                />
                {!!errors.terms && (
                  <FormHelperText>{errors.terms.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                style={{ height: 50 }}
                disableElevation
              >
                Register now
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}

export default Register
