import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HugLogoSrc from 'components/Logo/Logo.png'

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: 14,
    fontWeight: 500,
    margin: '0 20px',

    '& > a': {
      color: "#444",
      textDecoration: 'none'
    }
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <Box>
        <Box display="flex" justifyContent="center" mb={2} mt={8}>
          <img
            src={HugLogoSrc}
            height={30}
            alt="HiddenUnderground logo"
          />
        </Box>
        <Box display="flex" justifyContent="center" flexWrap="wrap" my={4}>
          <Typography className={classes.link}>
            <NavLink to="/">
              Home
            </NavLink>
          </Typography>
          <Typography className={classes.link}>
            <NavLink to="/help">
              Help
            </NavLink>
          </Typography>
          <Typography className={classes.link}>
            <NavLink to="/feedback">
              Feedback
            </NavLink>
          </Typography>
          <Typography className={classes.link}>
            <NavLink to="/privacy">
              Privacy
            </NavLink>
          </Typography>
          <Typography className={classes.link}>
            <NavLink to="/terms">
              Terms
            </NavLink>
          </Typography>
          <Typography className={classes.link}>
            <NavLink to="/about">
              About
            </NavLink>
          </Typography>
        </Box>
        <Box textAlign="center" my={2}>
          <Typography variant="caption">
            {new Date().getFullYear()} &copy; HiddenUnderGround All rights reserved
          </Typography>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
