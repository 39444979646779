import React, { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { IconButton, Tooltip } from '@material-ui/core'
import { Pause as PauseIcon } from '@material-ui/icons'
import { ConfirmationDialog } from 'components'
import api from 'utils/api'

const ActivateButton = ({ couponCode, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [showVerification, setShowVerification] = useState(false)

  const handleClick = useCallback(() => {
    setShowVerification(true)
  }, [])

  const deactivateCoupon = useCallback(
    () => {
      api.post(`/coupon/${couponCode}/deactivate`)
        .then(() => {
          enqueueSnackbar('Coupon disabled', { variant: 'success' })
          setShowVerification(false)
          onSuccess()
        })
        .catch(() => {
          enqueueSnackbar('Cannot disable coupon', { variant: 'error' })
        })
    }, [enqueueSnackbar, couponCode, onSuccess]
  )

  return (
    <>
      <Tooltip enterDelay={500} title="Disable coupon">
        <IconButton onClick={handleClick} size="small">
          <PauseIcon />
        </IconButton>
      </Tooltip>
      {
        showVerification &&
        <ConfirmationDialog
          onCancel={() => setShowVerification(false)}
          onConfirm={deactivateCoupon}
          isOpen
          body="Are you sure you want to disable coupon?"
          title="Disable coupon"
          successButtonLabel="Disable"
        />
      }
    </>
  )
}

export default ActivateButton
