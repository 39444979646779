import api from 'utils/api'
import * as types from './actionTypes.js'

export const fetchBusiness = (businessId, silent = false) => (dispatch) => {
  if (!silent) {
    dispatch(fetchBusinessInit())
  }
  api
    .get(`/business/${businessId}`)
    .then((res) => {
      dispatch(fetchBusinessSuccess(res))
    })
    .catch(() => {
      dispatch(fetchBusinessFail())
    })
}

const fetchBusinessInit = () => ({
  type: types.FETCH_BUSINESS_INIT,
})

const fetchBusinessSuccess = (data) => ({
  type: types.FETCH_BUSINESS_SUCCESS,
  data,
})

const fetchBusinessFail = () => ({
  type: types.FETCH_BUSINESS_FAIL,
})

export const updateBusinessCompanyInfo = (companyInfoData) => ({
  type: types.UPDATE_BUSINESS_COMPANY_INFO,
  companyInfo: companyInfoData,
})

export const cleanupBusiness = (businesses) => ({
  type: types.CLEANUP_BUSINESS,
  data: businesses,
})

export const updateBusinessKeywords = keywords => ({
  type: types.UPDATE_BUSINESS_KEYWORDS,
  keywords,
})

export const updateBusinessBudget = budget => ({
  type: types.UPDATE_BUSINESS_BUDGET,
  budget,
})
