import React from 'react'
import { useSelector } from 'react-redux'
import { Hidden} from '@material-ui/core'
import DesktopSwitcher from './components/Desktop'
import MobileSwitcher from './components/Mobile'

const LocationSwitcher = () => {
  const location = useSelector(state => state.user.location)

  return (
    <>
      <Hidden xsDown>
        <DesktopSwitcher location={location} />
      </Hidden>
      <Hidden smUp>
        <MobileSwitcher location={location} />
      </Hidden>
    </>
  )
}

export default LocationSwitcher
