import React from "react";
import { NavLink } from "react-router-dom";
import {
  fade,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    borderLeft: '6px solid transparent',
    paddingRight: 20,
  },
  menuItemSelected: {
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
    background: fade(theme.palette.secondary.main, 0.15),
  },
}));

const MenuItem = ({ disabled, icon, text, link }) => {
  const classes = useStyles();

  return (
    <ListItem
      activeClassName={classes.menuItemSelected}
      button
      className={classes.menuItem}
      component={NavLink}
      disabled={disabled}
      exact
      to={link}
    >
      <ListItemAvatar>{icon}</ListItemAvatar>
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};

export default MenuItem
