import { useMediaQuery, useTheme } from '@material-ui/core'
import { fade } from '@material-ui/core/styles';

export const useIsMobile = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('sm'));
}

export const getQuantityUrgencyColor = (quantity, theme) => {
  if (quantity < 10) {
    return fade(theme.palette.error.main, 0.6)
  }
  if (quantity < 30) {
    return theme.palette.warning.light
  }
  return 'rgba(78, 215, 225, 0.15)'
}

export const getTimeUrgencyColor = (daysRemaining, theme) => {
  if (daysRemaining < 5) {
    return fade(theme.palette.error.main, 0.6)
  }
  if (daysRemaining < 10) {
    return theme.palette.warning.light
  }
  return 'transparent'
}
