import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableContainer
} from '@material-ui/core'
import BusinessPage from '../../components/BusinessPage'
import * as uiActions from 'modules/UI/actions'
import Definition from './components/Definition'
import Keywords from './components/Keywords'
import BusinessBudget from './components/BusinessBudget'
import Owner from './components/Owner'
import { useIsMobile } from 'utils/UI'

const Info = ({ business }) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const companyInfo =
    business && business.companyInfo ? business.companyInfo : {}

  return (
    <BusinessPage title={isMobile ? 'Business' : 'Business profile'}>
      <Box p={2}>
        <Button color="primary" variant="contained" onClick={() => dispatch(uiActions.showBusinessForm(true))}>
          Edit details
        </Button>
        <Box my={3}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={8} lg={6}>
              <Card>
                <CardHeader subheader="Business details" />
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Box p={2}>
                      <img src={companyInfo.logoUrl} height={50} alt="logo" />
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <Definition id="Business" value={companyInfo.companyName} />
                        <Definition id="Street" value={companyInfo.street} />
                        <Definition id="City" value={companyInfo.city} />
                        <Definition id="State" value={companyInfo.state === null ? 'Outside US' : companyInfo.state} />
                        <Definition id="Zipcode" value={companyInfo.zipCode} />
                        <Definition id="Site url" value={companyInfo.siteUrl} />
                        <Definition id="Categories" value={business.categories.map(c => c.categoryName).join(', ')} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
              <Owner />
            </Grid>
            <Grid item sm={12} md={4} lg={6}>
              <BusinessBudget />
              <Keywords />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </BusinessPage>
  )
}

export default Info
