import Cookies from "js-cookie"

export const BASE = "/api";

const api = {
  get: (url, options) =>
    fetch(`${BASE}${url}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get('access_token') || ''}`
      },
      mode: "cors",
      withCredentials: true,
      credentials: 'include',
      ...options,
    }).then(res => parseResponse(res)),
  patch: (url, data, options) => 
    fetch(`${BASE}${url}`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get('access_token') || ''}`,
        'Content-Type': 'application/json',
      },
      mode: "cors",
      withCredentials: true,
      credentials: 'include',
      ...options,
    }).then(res => parseResponse(res)),
  post: (url, data, options) => 
    fetch(`${BASE}${url}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get('access_token') || ''}`,
        'Content-Type': 'application/json',
      },
      mode: "cors",
      withCredentials: true,
      credentials: 'include',
      ...options,
    }).then(res => parseResponse(res)),
  put: (url, data, options) => 
    fetch(`${BASE}${url}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get('access_token') || ''}`,
        'Content-Type': 'application/json',
      },
      mode: "cors",
      withCredentials: true,
      credentials: 'include',
      ...options,
    }).then(res => parseResponse(res)),
  delete: (url, options) => 
    fetch(`${BASE}${url}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get('access_token') || ''}`,
        'Content-Type': 'application/json',
      },
      mode: "cors",
      withCredentials: true,
      credentials: 'include',
      ...options,
    }).then(res => parseResponse(res)),
};

export default api;

export const parseResponse = response => {
  return new Promise((resolve, reject) => {
    try {
      response
        .json()
        .then((jsonResponse) => {
          if (response.status >= 200 && response.status < 400) {
            resolve(jsonResponse)
          } else {
            reject(jsonResponse)
          }
        })
        .catch(reject)
    } catch (error) {
      reject(error)
    }
  })
}