import React from 'react'
import { useSnackbar } from 'notistack'
import { Box, IconButton, Table, TableCell, TableContainer, TableBody, TableHead, TableRow } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import AccountPage from '../../components/AccountPage'
import useSWR from 'swr'
import api from 'utils/api'
import { Loader } from 'components'
import {NavLink} from "react-router-dom";

const Alerts = () => {
  const { enqueueSnackbar } = useSnackbar()
  const url = '/user/query/list'
  const { data, error, mutate } = useSWR(url, () => api.get(url), { shouldRetryOnError: false, revalidateOnFocus: false })

  const handleDelete = (id) => () => {
    api
      .delete(`/user/query/delete/${id}`)
      .then(() => {
        mutate()
        enqueueSnackbar('Deleted your alert', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Cannot delete your alert', { variant: 'alert' })
      })
  }

  if (error) {
    return (
      <AccountPage title="My alerts">
        <Box p={2} pt={0}>
          Error occured
        </Box>
      </AccountPage>
    )
  }

  if (!data) {
    return (
      <AccountPage title="My alerts">
        <Box p={2} pt={0}>
          <Loader />
        </Box>
      </AccountPage>
    )
  }

  return (
    <AccountPage title="My alerts">
      <Box p={2} pt={0}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Query
                </TableCell>
                <TableCell>
                  Business
                </TableCell>
                <TableCell>
                  Category
                </TableCell>
                <TableCell>
                  Filter used
                </TableCell>
                <TableCell>
                  Location
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.content.length ?
                data.content.map(a => (
                  <TableRow key={a.id}>
                    <TableCell>
                      {a.query || '-'}
                    </TableCell>
                    <TableCell>
                      {a.business || '-'}
                    </TableCell>
                    <TableCell>
                      {a.category || '-'}
                    </TableCell>
                    <TableCell>
                      {a.filter}
                    </TableCell>
                    <TableCell>
                      {a.location}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={handleDelete(a.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={6} align={"center"}>
                    You do not have an alerts currently. Please click <NavLink to="/">here</NavLink> to return to the main homepage.
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </AccountPage>
  )
}

export default Alerts
