import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

const Authorized = ({
  children,
  requiredRoles,
}) => {
  const history = useHistory()
  const location = useLocation()
  const userRoles = useSelector(state => state.user.roles)

  if (!userRoles || !userRoles.find(r => requiredRoles.indexOf(r) !== -1)) {
    history.push('/login', { redirectUrl: location.pathname })
    return null
  }

  return children
}

export default Authorized
