import React from 'react'
import { Hidden, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
  },
  headerLine: {
    width: '100%',
    height: '2px',
    background: '#0F75BC',
    position: 'absolute',
    top: '6px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 0, 3, 0),
    padding: 0,
  },
  circle: {
    width: '15px',
    height: '15px',
    border: '5px solid #fff',
    borderRadius: '50%',
    margin: '0 auto',
    display: 'block',
    backgroundColor: '#DFDFDF',
  },
  separator: {
    flex: 1,
    marginTop: '10px',
    '& > i': {
      display: 'block',
      background: '#0F75BC',
      width: '100%',
      height: '2px',
    },
    '&:last-of-type': {
      display: 'none',
    },
  },
  headerStep: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: '#808080',
    cursor: 'pointer',
    '& > span': {
      display: 'block',
    },
    '&.disabled': {
      cursor: 'not-allowed',
    },
    '&.active': {
      fontSize: 14,
      color: '#000',
      marginTop: '-3px',
      '& > i': {
        width: '20px',
        height: '20px',
        backgroundColor: '#F9B756',
      },
      '& > span': {
        marginTop: 0,
        fontWeight: 500,
      },
    },
  },
}))

const Stepper = ({ activeStep, onStepChange, steps }) => {
  const classes = useStyles()
  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        {steps.map((step, index) => {
          return (
            <>
              <div
                onClick={
                  activeStep > index ?
                  () => onStepChange(index)
                  :
                  undefined
                }
                key={index}
                className={`${classes.headerStep}${
                  activeStep === index ? ' active' : ''
                }${activeStep < index ? ' disabled' : ''}`}
              >
                <i className={classes.circle}></i>
                <Hidden mdDown>
                  <span>{step}</span>
                </Hidden>
              </div>
              <div className={classes.separator}>
                <i></i>
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Stepper
