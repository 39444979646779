import React from 'react'
import { Avatar, ButtonBase, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  avatar: {
    background: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
}))

const UserIconButton = ({ avatar, name, handleClick }) => {
  const classes = useStyles()
  return (
    <>
      <ButtonBase onClick={handleClick}>
        <Avatar alt={name} variant="rounded" src={avatar || undefined} className={classes.avatar}>
          {name[0]}
        </Avatar>
      </ButtonBase>
    </>
  )
}

export default UserIconButton
