import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import { Clear, Done } from '@material-ui/icons'
import ActivateButton from '../ActivateButton'

const columns = [
  { key: 'action', label: '', sortable: false },
  { key: 'couponCode', label: 'Coupon Code', sortable: true },
  { key: 'status', label: 'Status', sortable: false },
  { key: 'type', label: 'Type', sortable: true },
  { key: 'expirationDate', label: 'Expiration Date', sortable: true },
  { key: 'totalUsed', label: 'Total used', sortable: true },
  { key: 'referrer', label: 'Referrer', sortable: true },
  { key: 'newBusiness', label: 'New business', sortable: true, boolean: true },
  { key: 'businessName', label: 'Business Name', sortable: true },
  { key: 'amount', label: 'Amount', sortable: true, align: 'right' },
]

const T = ({
  changeOrder,
  changeOrderBy,
  loading,
  mutate,
  order,
  orderBy,
  rows,
}) => {
  const handleSortChange = (k) => () => {
    if (k === orderBy) {
      changeOrder(order === 'asc' ? 'desc' : 'asc')
      return
    }
    changeOrderBy(k)
    changeOrder('desc')
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((c) => (
              <TableCell
                key={c.key}
                sortDirection={orderBy === c.key ? order : false}
                align={c.align || 'left'}
              >
                <TableSortLabel
                  active={orderBy === c.key}
                  direction={orderBy === c.key ? order : 'desc'}
                  disabled={!c.sortable}
                  onClick={handleSortChange(c.key)}
                >
                  {c.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ opacity: loading ? 0.5 : 1 }}>
          {rows.map((data, i) => (
            <TableRow key={data.userId}>
              {columns.map((c) => {
                if (c.key === 'action') {                  
                  return data.status !== 'Expired' && data.status !== 'Disabled' ?
                    <TableCell key="action"><ActivateButton couponCode={data.couponCode} onSuccess={mutate} /></TableCell>
                    :
                    <TableCell />
                }
                if (c.boolean) {
                  return <TableCell key={c.key}>
                    {
                      data[c.key] ?
                      <Done color="primary" />
                      :
                      <Clear />
                    }
                  </TableCell>
                } 
                if (c.key === 'totalUsed') {
                  return <TableCell>
                    {data.totalUsed} / {data.maxUses}
                  </TableCell>
                }
                return <TableCell align={c.align || 'left'} key={c.key}>{data[c.key] || '-'}</TableCell>
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default T
