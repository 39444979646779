import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const ConfirmationDialog = ({
  onCancel,
  onConfirm,
  isOpen,
  body = 'You have unsaved changes. Are you sure you want to leave this page?',
  title = 'Changes not saved',
  successButtonLabel = 'Leave',
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {successButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
