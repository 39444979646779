import React from 'react'
import { CardContent, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 0,
    paddingTop: 0,
    flex: '1 1 40px',
    overflow: 'hidden',
    marginBottom: theme.spacing(1)
  }
}))

const OfferContent = ({ children }) => {
  const classes = useStyles()
  return (
    <CardContent classes={classes}>
      <Typography variant="body2" color="textSecondary" component="p">
        {
          children.length > 150 ? children.substr(0, 150) + '...' : children
        }
      </Typography>
    </CardContent>
  )
}

export default OfferContent
