import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  makeStyles
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
import * as uiActions from 'modules/UI/actions';
import { fetchBusinesses } from 'modules/User/actions';
import * as businessActions from 'modules/Business/actions';
import api from "utils/api";
import CategorySelector from './components/CategorySelector';
import { STATE_FULL_NAME } from 'utils/constants';
import ImageUpload from 'modules/Business/modules/Offers/components/OfferDetails/components/ImageUpload';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    backgroundColor: '#f9f9f9',
  },
  formTitle: {
    backgroundColor: '#e0e0e0',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  formPaper: {
    padding: theme.spacing(3),
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  selectMenu: {
    backgroundColor: '#f0f0f0',
  },
  selectPaper: {
    backgroundColor: '#f0f0f0',
  },
  blurBackground: {
    filter: 'blur(5px)',
    transition: 'filter 0.3s',
  },
}));

const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

yup.addMethod(yup.mixed, 'defined', function () {
  return this.test('defined', "{path} is required", value => value !== undefined);
});

const schema = yup.object().shape({
  companyName: yup.string().required('Name is required'),
  street: yup.string().required('Street is required'),
  city: yup.string().required('City is required'),
  zipCode: yup.number().typeError('Zipcode must be a number').required('Zipcode is required'),
  state: yup.mixed().defined(),
  siteUrl: yup.string().matches(urlPattern, 'Url is not valid').max(240, 'Too many characters').required('Site url is required'),
  logoUrl: yup.string().required('Logo is required'),
  categories: yup.mixed().required('Pick a category'),
});

const BusinessForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isEdit = useSelector(s => s.ui.showBusinessForm);
  const business = useSelector(s => s.business);

  const [logoUploadSuccess, setLogoUploadSuccess] = useState(false);

  const biz = business.businessId ? {
    categories: business.categories,
    companyName: business.companyInfo.companyName,
    street: business.companyInfo.street,
    zipCode: business.companyInfo.zipCode,
    city: business.companyInfo.city,
    state: business.companyInfo.state !== null ?
        business.companyInfo.state.toUpperCase() : business.companyInfo.state,
    siteUrl: business.companyInfo.siteUrl,
    logoUrl: business.companyInfo.logoUrl,
  } : {};

  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: isEdit === 1 ? biz : {},
    resolver: yupResolver(schema)
  });

  const currentState = watch('state');

  const handleFormSubmit = ({ categories, ...companyInfo }) => {
    const businessId = isEdit === 1 ? business.businessId : null;
    const payload = {
      businessId: businessId,
      companyInfo: {
        ...companyInfo,
        businessId: businessId,
      },
      categories: categories.map(c => c.categoryId),
    };
    postCompany(payload);
  };

  const postCompany = async (payload) => {
    try {
      const businessId = await api.post('/business', payload);
      dispatch(uiActions.hideBusinessForm());
      if (!businessId.error) {
        if (isEdit === 1) {
          dispatch(businessActions.fetchBusiness(business.businessId));
          api.get('/user/businesses/')
            .then(res => {
              dispatch(fetchBusinesses(res.content));
            });
        } else {
          history.push(`/business/${businessId}`);
          api.get('/user/businesses/')
            .then(res => {
              dispatch(fetchBusinesses(res.content));
            });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = useCallback(
    () => {
      dispatch(uiActions.hideBusinessForm());
    }, [dispatch]
  );

  const handleLogoUploadSuccess = () => {
    setLogoUploadSuccess(true);
  };

  return (
    <div className={clsx({ [classes.blurBackground]: isEdit !== null })}>
      <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <DialogTitle className={classes.formTitle}>
            <Typography variant="h5">
              {isEdit === 1 ? 'Edit Business' : 'Create New Business'}
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Paper elevation={3} className={classes.formPaper}>
              <Box className={classes.formField}>
                <FormControl fullWidth variant="outlined" error={!!errors.companyName}>
                  <TextField
                    autoFocus
                    fullWidth
                    label="Business Name"
                    margin="dense"
                    name="companyName"
                    inputRef={register({ required: 'Business name is required' })}
                    variant="outlined"
                  />
                  {errors.companyName && (
                    <FormHelperText>{errors.companyName.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className={classes.formField}>
                <FormControl fullWidth variant="outlined" error={!!errors.street}>
                  <TextField
                    fullWidth
                    label="Street"
                    margin="dense"
                    name="street"
                    inputRef={register({ required: 'Street is required' })}
                    variant="outlined"
                  />
                  {errors.street && (
                    <FormHelperText>{errors.street.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className={classes.formField}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth variant="outlined" error={!!errors.city}>
                      <TextField
                        fullWidth
                        label="City"
                        margin="dense"
                        name="city"
                        inputRef={register({ required: 'City is required' })}
                        variant="outlined"
                      />
                      {errors.city && (
                        <FormHelperText>{errors.city.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined" error={!!errors.zipCode}>
                      <TextField
                        fullWidth
                        label="Zip Code"
                        margin="dense"
                        name="zipCode"
                        inputRef={register({
                          required: 'Zip code is required',
                          pattern: {
                            value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                            message: 'Invalid zip code'
                          }
                        })}
                        variant="outlined"
                        type="number"
                      />
                      {errors.zipCode && (
                        <FormHelperText>{errors.zipCode.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined" error={!!errors.state} margin="dense">
                      <InputLabel id="company-info-state" shrink={!!currentState}>State</InputLabel>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'State is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="company-info-state"
                            displayEmpty
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            MenuProps={{
                              classes: { paper: classes.selectPaper }
                            }}
                          >
                            <MenuItem value="">Outside US</MenuItem>
                            {Object.entries(STATE_FULL_NAME).map(([abbr, name]) => (
                              <MenuItem key={abbr} value={abbr}>{name}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.state && (
                        <FormHelperText>{errors.state.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.formField}>
                <FormControl fullWidth variant="outlined" error={!!errors.siteUrl}>
                  <TextField
                    defaultValue=""
                    fullWidth
                    label="Site URL"
                    margin="dense"
                    name="siteUrl"
                    inputRef={register({
                      pattern: {
                        value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                        message: 'Invalid URL'
                      }
                    })}
                    variant="outlined"
                  />
                  {errors.siteUrl && (
                    <FormHelperText>{errors.siteUrl.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className={classes.formField}>
                <ImageUpload
                  control={control}
                  error={errors.logoUrl}
                  placeholder="Business Logo"
                  name="logoUrl"
                  uploadUrl={`/api/business/saveImage`}
                  onSuccess={handleLogoUploadSuccess}
                  successMessage={logoUploadSuccess ? "Logo uploaded successfully" : ""}
                />
              </Box>
              <Box className={classes.formField}>
                <FormControl fullWidth variant="outlined" error={!!errors.categories}>
                  <CategorySelector control={control} MenuProps={{ classes: { paper: classes.selectPaper } }} />
                  {errors.categories && (
                    <FormHelperText>{errors.categories.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {isEdit === 1 ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default BusinessForm;
