import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParam } from 'use-query-params'
import { IconButton, Input, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const SearchForm = ({ full }) => {
  const history = useHistory()
  const location = useLocation()
  const [query, setQueryParam] = useQueryParam('query')
  const [q, setQ] = useState(query);

  const handleSearch = (e) => {
    e.preventDefault()
    if (!q) {
      return
    }
    if (location.pathname.startsWith('/search')) {
      setQueryParam(q)
    } else {
      history.push(`/search?query=${q}`);
    }
  };

  return (
    <form onSubmit={handleSearch} style={{ maxWidth: full ? 1000 : 500, width: '100%' }}>
      <Input
        color="primary"
        disableUnderline
        fullWidth
        inputProps={{
          style: {
            padding: 16,
            background: '#ededed',
          },
          "aria-label": "search offers"
        }}
        placeholder="Search offers"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Search"
              type="submit"
            >
              <Search />
            </IconButton>
          </InputAdornment>
        }
        style={{
          padding: '0 8px',
          background: '#ededed',
          borderRadius: 4,
          marginRight: 8,
        }}
        onChange={e => setQ(e.target.value)}
        value={q}
      />
    </form>
  )
}

export default SearchForm