import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  Box,
  Card,
  CardHeader,
  Grid,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Pagination } from '@material-ui/lab'
import { Loader } from 'components'
import { dateFormat } from 'utils/time'
import BusinessPage from '../../components/BusinessPage'
import T from './components/Table'
import { useTransactions, useWidgetData } from './utils'
import { useIsMobile } from 'utils/UI'

const Transactions = () => {
  const isMobile = useIsMobile()
  const transactionPerPage = 20
  const [currentPage, setCurrentPage] = useState(1)
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').startOf('day'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [orderBy, setOrderBy] = useState('transactionDatetime')
  const [order, setOrder] = useState('desc')
  const businessId = useSelector(state => state.business.businessId)

  const startDateParsed = startDate && startDate._isValid ? startDate.startOf('day') : null
  const endDateParsed = endDate && endDate._isValid ? endDate.endOf('day') : null
  const startDateLocalized = startDateParsed ? startDateParsed.toISOString() : null
  const endDateLocalized = endDateParsed ? endDateParsed.toISOString() : null

  const { loadingWidgets, widgets } = useWidgetData({ businessId, startDate: startDateLocalized, endDate: endDateLocalized })

  const { items: trans, loading, totalPages } = useTransactions({
    businessId,
    size: transactionPerPage,
    startDate: startDateLocalized,
    endDate: endDateLocalized,
    order,
    orderBy,
    page: currentPage,
  })

  return (
    <BusinessPage title="Transactions">
      <Box p={2} display="flex" alignItems="flex-start" flexDirection={isMobile ? 'column' : 'row'}>
        <Box>
          <KeyboardDatePicker
            autoOk
            InputAdornmentProps={{ position: "end" }}
            variant="inline"
            format={dateFormat}
            inputVariant="outlined"
            label="Start date"
            margin="dense"
            mask="__/__/____"
            placeholder={dateFormat}
            value={startDate}
            maxDate={moment(endDate)}
            maxDateMessage="Start date should not be before End date"
            onChange={val =>
              setStartDate(val)
            }
            KeyboardButtonProps={{
              'aria-label': 'Change start date',
            }}
          />
        </Box>
        <Box ml={isMobile ? 0 : 1}>
          <KeyboardDatePicker
            autoOk
            disableFuture
            InputAdornmentProps={{ position: "end" }}
            variant="inline"
            format={dateFormat}
            inputVariant="outlined"
            label="End date"
            margin="dense"
            mask="__/__/____"
            placeholder={dateFormat}
            value={endDate}
            minDate={moment(startDate)}
            minDateMessage="End date should not be before Start date"
            maxDateMessage="End date should not be a future date"
            onChange={val =>
              setEndDate(val)
            }
            KeyboardButtonProps={{
              'aria-label': 'Change end date',
            }}
          />
        </Box>
      </Box>
      {
        false && !widgets && loadingWidgets &&
        <Box>
          <Loader />
        </Box>
      }
      {
        widgets &&
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Card>
                <CardHeader
                  title="Charges"
                  subheader={widgets.clicks || '-'}
                  subheaderTypographyProps={{
                    variant: 'h6',
                    color: 'textPrimary',
                  }}
                  titleTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card>
                <CardHeader
                  title="Purchase events"
                  subheader={widgets.chargeEvents || '-'}
                  subheaderTypographyProps={{
                    variant: 'h6',
                    color: 'textPrimary',
                  }}
                  titleTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card>
                <CardHeader
                  title="Offers"
                  subheader={widgets.offers || '-'}
                  subheaderTypographyProps={{
                    variant: 'h6',
                    color: 'textPrimary',
                  }}
                  titleTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card>
                <CardHeader
                  title="Last purchase date"
                  subheader={widgets.lastChargeDate ? moment(widgets.lastChargeDate).format(dateFormat) : '-'}
                  subheaderTypographyProps={{
                    variant: 'h6',
                    color: 'textPrimary',
                  }}
                  titleTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                />
              </Card>
            </Grid>
            {
              widgets.startCouponBalance &&
              <Grid item xs={12} sm={6} lg={3}>
                <Card>
                  <CardHeader
                    title="Start promotional balance"
                    subheader={widgets.startCouponBalance}
                    subheaderTypographyProps={{
                      variant: 'h6',
                      color: 'textPrimary',
                    }}
                    titleTypographyProps={{
                      variant: 'body2',
                      color: 'textSecondary',
                    }}
                  />
                </Card>
              </Grid>
            }
            {
              widgets.endCouponBalance &&
              <Grid item xs={12} sm={6} lg={3}>
                <Card>
                  <CardHeader
                    title="End promotional balance"
                    subheader={widgets.endCouponBalance}
                    subheaderTypographyProps={{
                      variant: 'h6',
                      color: 'textPrimary',
                    }}
                    titleTypographyProps={{
                      variant: 'body2',
                      color: 'textSecondary',
                    }}
                  />
                </Card>
              </Grid>
            }
            <Grid item xs={12} sm={6} lg={3}>
              <Card>
                <CardHeader
                  title="Start credit balance"
                  subheader={widgets.startCreditBalance || '-'}
                  subheaderTypographyProps={{
                    variant: 'h6',
                    color: 'textPrimary',
                  }}
                  titleTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card>
                <CardHeader
                  title="End credit balance"
                  subheader={widgets.endCreditBalance || '-'}
                  subheaderTypographyProps={{
                    variant: 'h6',
                    color: 'textPrimary',
                  }}
                  titleTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
      }
      <T
        rows={trans}
        changeOrderBy={v => setOrderBy(v)}
        changeOrder={c => setOrder(c)}
        columns={[
          { key: 'transactionDatetime', label: 'Timestamp', sortable: true },
          { key: 'type', label: 'Transaction Type', sortable: true },
          { key: 'offerTitle', label: 'Offer', sortable: true },
          { key: 'location', label: 'Location', sortable: true },
          { key: 'amount', label: 'Amount', sortable: true, align: 'right' },
          { key: 'totalBalance', label: 'Total balance', sortable: false, align: 'right' },
        ]}
        loading={loading}
        order={order}
        orderBy={orderBy}
      />
      {
        totalPages > 1 &&
        <Box display="flex" justifyContent="center" pt={4}>
          <Pagination page={currentPage} count={totalPages} onChange={(e, newPage) => setCurrentPage(newPage)} color="primary" />
        </Box>
      }
    </BusinessPage>
  )
}

export default Transactions
