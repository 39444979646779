import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import { Page } from 'components'
import { AuthPopup } from 'modules'

const LoginPage = () => {
  useEffect(() =>  window.scrollTo(0,0),[])
  const userRoles = useSelector(s => get(s, 'user.details.userRoles'))
  const isAdmin = userRoles && Array.isArray(userRoles) && userRoles.find(r => r === 'ROLE_BUSINESS')

  if (isAdmin) {
    return <Redirect to="/business" />
  }

  if (userRoles) {
    return <Redirect to="/" />
  }

  return (
    <Page>
      <Helmet>
        <title>Login - HiddenUnderground</title>
      </Helmet>
      <AuthPopup />
    </Page>
  )
}
export default LoginPage
