import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { Loader } from 'components'
import { useIsMobile } from 'utils/UI'
import Sidebar from '../../components/Sidebar'
import BusinessForm from '../../components/BusinessForm'
import Credits from '../Credits'
import Dashboard from '../Dashboard'
import Offers from '../Offers'
import Transactions from '../Transactions'
import Info from '../Info'
import UserList from '../UserList'
import Coupons from '../Coupons'
import * as businessActions from 'modules/Business/actions'
import { useAdminUser } from 'utils/roles'
import Helmet from "react-helmet";

const BusinessContainer = () => {
  const businesses = useSelector((state) => state.user.businesses)
  const showForm = useSelector((state) => state.ui.showBusinessForm)
  const business = useSelector((state) => state.business)
  const dispatch = useDispatch()
  const params = useParams()
  const isMobile = useIsMobile()
  const isAdmin = useAdminUser()

  useEffect(() => {
    dispatch(businessActions.fetchBusiness(params.businessId))
  }, [dispatch, params.businessId])

  if (business.isLoading) {
    return <Loader />
  }

  if (!business.businessId) {
    return null
  }

  return (
    <div style={{ height: '100vh', overflow: 'auto', background: 'fcfcfc' }}>
      <Helmet>
        <title>My Business - HiddenUnderground</title>
      </Helmet>
      {
        !isMobile &&
        <Sidebar businesses={businesses} />
      }
      <Switch>
        <Route exact path="/business/:businessId/dashboard" component={Dashboard} />
        <Route exact path="/business/:businessId/offers" component={Offers} />
        <Route
          exact
          path="/business/:businessId/payments"
          component={Credits}
        />
        <Route
          exact
          path="/business/:businessId/transactions"
          component={Transactions}
        />
        <Route
          exact
          path="/business/:businessId/profile"
          render={(props) => <Info {...props} business={business} />}
        />
        {
          isAdmin &&
          <Route
            exact
            path="/business/:businessId/user-list"
            component={UserList}
          />
        }
        {
          isAdmin &&
          <Route
            exact
            path="/business/:businessId/coupons"
            component={Coupons}
          />
        }
        <Redirect from="/business/:businessId" to="/business/:businessId/dashboard" />
      </Switch>
      {showForm && <BusinessForm />}
    </div>
  )
}

export default BusinessContainer
