export const isProd = () => {
  if (process.env.REACT_APP_STAGE === 'staging') {
    return false
  }
  return process.env.NODE_ENV === 'production'
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
