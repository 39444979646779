import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { Container } from 'components'
import UserIcon from './components/UserIcon'
import BusinessDropdownMenu from "../../modules/Business/components/BusinessDropdownMenu";

const InternalHeader = ({
  children,
  title,
}) => {
  const user = useSelector(state => state.user)
  const [dropdownMenuAnchor, setDropdownMenuAnchor] = useState(null)

  return (
    <>
      <header>
        <Container>
          <Box my={4} display="flex" alignItems="center" justifyContent="space-between" height={48}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5">
                {title}
              </Typography>
              <Box display="flex" alignItems="center">
                {children}
              </Box>
            </Box>
            <Box>
              <UserIcon
                avatar={user.details.imageUrl}
                name={user.details.firstName || user.details.email}
                handleClick={(e) => {
                  setDropdownMenuAnchor(e.currentTarget)
                }}
              />
            </Box>
          </Box>
        </Container>
      </header>
      <BusinessDropdownMenu anchorElement={dropdownMenuAnchor} onClose={() => setDropdownMenuAnchor(null)} />
    </>
  )
}

export default InternalHeader
