import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import { AttachMoney as AttachMoneyIcon } from '@material-ui/icons'
import api from 'utils/api'
import * as businessActions from 'modules/Business/actions'

const SetReload = ({ businessId, onClose }) => {
  const [amount, setAmount] = useState()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handleChange = useCallback(
    (e) => {
      let newValue = e.target.value
      if (newValue && newValue < 0) {
          newValue = 0;
      } else if (newValue && newValue > 500) {
          newValue = 500;
      }

      setAmount(newValue)
    }, [setAmount]
  )

  const handleSubmit = useCallback(
    () => {
      api
        .put(`/business/${businessId}/reload/${amount}`)
        .then(() => {
          enqueueSnackbar('Reload set for your account', { variant: 'success' })
          onClose()
          dispatch(businessActions.fetchBusiness(businessId, true))
        })
        .catch(() => {
          enqueueSnackbar('Cannot set reload', { variant: 'error' })
        })
    }, [amount, dispatch, businessId, enqueueSnackbar, onClose]
  )

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Set reload amount for your account</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="Reload amount"
            variant="outlined"
            inputProps={{
              min: 0,
              max: 500,
            }}
            InputProps={{
              startAdornment: <AttachMoneyIcon />,
            }}
            onChange={handleChange}
            type="number"
            value={amount}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={onClose}>Cancel</Button>
          <Button color="primary" disabled={!amount} variant="contained" onClick={handleSubmit}>
            Set reload
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SetReload
