import React from 'react'
import { Box } from '@material-ui/core'

const Container = ({ children }) => (
  <Box px={2} maxWidth={1600} mx="auto" width="calc(100% - 32px)">
    {children}
  </Box>
)

export default Container
