import React from 'react'
import { yupResolver } from '@hookform/resolvers'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { OfferCard } from 'components'
import ImageUpload from './components/ImageUpload'
import OfferListPreview from '../OfferListPreview'

// eslint-disable-next-line
const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

const schema = yup.object().shape({
  headline1: yup.string().max(30, 'Too many characters').required('Headline 1 is required'),
  headline2: yup.string().max(40, 'Too many characters').required('Headline 2 is required'),
  description: yup.string().max(150, 'Too many characters').required('Description is required'),
  siteUrl: yup.string().matches(urlPattern, 'Url is not valid').max(500, 'Too many characters').required('URL is required'),
  details: yup.string().max(500, 'Too many characters').required('Details are required'),
})

const OfferDetails = ({ back, onClose, onComplete, values }) => {
  const { logoUrl: businessLogo, businessId } = useSelector(state => get(state, 'business.companyInfo'))
  const { control, register, handleSubmit, errors, watch } = useForm({
    defaultValues: values.details || {
      headline1: '',
      headline2: '',
      description: '',
      offerImage: '',
      siteUrl: '',
      details: '',
    },
    resolver: yupResolver(schema),
  })

  const currentValues = watch()

  const handleFormSubmit = (data) => {
    if (! data.offerImage) {
      data.offerImage = get(values, 'description.category[0].categoryImage')
    }

    onComplete(data)
  }

  return (
    <>
      <Box mb={4}>
        <Typography variant="h6" component="h2">
          Let’s write your offer
        </Typography>
        <Typography variant="body2" component="p">
          Tell your audience about your business and your offer
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <form>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Headline 1"
                defaultValue=""
                name="headline1"
                inputRef={register}
                variant="outlined"
                margin="dense"
                />
              <FormHelperText error>
                <Box display="flex" justifyContent="space-between">
                  <span>
                    {errors.headline1 ? errors.headline1.message : ''}
                  </span>
                  <Typography color="textSecondary" variant="caption">
                    {currentValues.headline1.length} / 30
                  </Typography>
                </Box>
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Headline 2"
                defaultValue=""
                name="headline2"
                inputRef={register}
                variant="outlined"
                margin="dense"
              />
              <FormHelperText error>
                <Box display="flex" justifyContent="space-between">
                  <span>
                    {errors.headline2 ? errors.headline2.message : ''}
                  </span>
                  <Typography color="textSecondary" variant="caption">
                    {currentValues.headline2.length} / 40
                  </Typography>
                </Box>
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Description"
                defaultValue=""
                name="description"
                inputRef={register}
                variant="outlined"
                margin="dense"
                multiline
              />
              <FormHelperText error>
                <Box display="flex" justifyContent="space-between">
                  <span>
                    {errors.description ? errors.description.message : ''}
                  </span>
                  <Typography color="textSecondary" variant="caption">
                    {currentValues.description.length} / 150
                  </Typography>
                </Box>
              </FormHelperText>
            </FormControl>
            <Typography variant="caption" color="textSecondary">
              Upload an image to help users identify your offer. If no image is uploaded - the HiddenUnderground system will try to find a suitable image based on categories and keywords set for your offer.
            </Typography>
            <ImageUpload error={errors.offerImage} control={control} uploadUrl={`/api/offer/${businessId}/saveImage`} />
            <Typography variant="caption" color="textSecondary">
              Provide your website URL for your offer. You can append the offer code to be passed to your shopping cart.
            </Typography>
            <FormControl fullWidth variant="outlined" error={!!errors.siteUrl}>
              <TextField
                label="Site URL"
                defaultValue=""
                name="siteUrl"
                inputRef={register}
                variant="outlined"
                margin="dense"
                multiline
              />
              <FormHelperText error>
                <Box display="flex" justifyContent="space-between">
                  <span>
                    {errors.siteUrl ? errors.siteUrl.message : ''}
                  </span>
                  <Typography color="textSecondary" variant="caption">
                    {currentValues.siteUrl.length} / 500
                  </Typography>
                </Box>
              </FormHelperText>
            </FormControl>
            <Typography variant="caption" color="textSecondary">
              Description of the Terms and Conditions of your offer. You can also provide T&C URL if they are posted on your site
            </Typography>
            <FormControl fullWidth variant="outlined" error={!!errors.details}>
              <TextField
                label="Offer details"
                defaultValue=""
                name="details"
                inputRef={register}
                variant="outlined"
                margin="dense"
                multiline
              />
              <FormHelperText error>
                <Box display="flex" justifyContent="space-between">
                  <span>
                    {errors.details ? errors.details.message : ''}
                  </span>
                  <Typography color="textSecondary" variant="caption">
                    {currentValues.details.length} / 500
                  </Typography>
                </Box>
              </FormHelperText>
            </FormControl>
            <Box mt={3}>
              <Typography variant="caption" color="textSecondary">
                Some shopping carts will allow you to pass the offer code in the URL string, to later be captured by the shopping cart. Check your shopping cart vendor for details.
              </Typography>
            </Box>
          </form>
        </Grid>
        <Grid item sm={12} lg={6} xs={12}>
          <Box width="100%" position="sticky" top={0}>
            <Box mx="auto" maxWidth={450}>
              <Box textAlign="right">
                <OfferListPreview offer={{
                  ...currentValues,
                  endDate: get(values, 'budget.endDate', null),
                  imageUrl: currentValues.offerImage || get(values, 'description.category[0].categoryImage'),
                  businessImageUrl: businessLogo,
                }} />
              </Box>
              <OfferCard
                {...currentValues}
                endDate={get(values, 'budget.endDate', null)}
                imageUrl={currentValues.offerImage || get(values, 'description.category[0].categoryImage')}
                businessImageUrl={businessLogo}
                isPreview
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2} mb={1} display="flex" justifyContent="space-between">
        <Box>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            onClick={back}
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default OfferDetails
