import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'
import { Page } from 'components'
import Helmet from "react-helmet";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <Page>
      <Helmet>
        <title>About - HiddenUnderground</title>
      </Helmet>
      <Container maxWidth="md">
        <Box my={10}>
          <Typography variant="h4" component="h1">
            About us!!!
          </Typography>
          <br/> <br/>
          <Typography variant="h6" component="h2">
            Who are we?  
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround.com is a website designed to bring consumers together with retailers, merchants, suppliers, and businesses that want to provide consumers with a discount or special offer. HiddenUnderGround.com aims to improve both the buying and selling experience for both parties. 
          </Typography>
          <Typography variant="h6" component="h2">
            Why do we exist?
          </Typography>
          <Typography variant="body2" paragraph>
            Consumers often spend a lot of time to find what they feel are great offers from third party coupon sites and attempt to make purchases using those offers. Unfortunately, more times than not, they only find out during the final stages of check-out that those offers were expired or invalid for one reason or another. In frustration, many customers abandon their shopping carts. In turn, businesses lose the sale through no fault of their own because third party coupon sites presented the expired/invalid offer as valid. Most third party coupon sites are not focused on the shopper’s experience on the business site.   , their goal is to pass consumer traffic to the businesses site to make a commission on the sale.
          </Typography>
          <Typography variant="h6" component="h2">
            How are we different?
          </Typography>
          <Typography variant="body2" paragraph>
            When consumers search for an offer on HiddenUnderGround, they can feel confident that the offer code will work as presented, delivering a positive, frustration-free shopping experience for customers.
          </Typography>
          <Typography variant="body2" paragraph>
            The offers on HiddenUnderGround.com are provided and managed by the businesses themselves. We do not tell the businesses what kind of offer, how many, or the discount  levels required for each offer.  We do not ask any users to post one time use offer codes on our site, and have other users confirm if they work or not.   
          </Typography>
          <Typography variant="body2" paragraph>
            Most importantly, HiddenUnderGround.com does not scrape offer sites, company offer pages, or present old offers codes just to get your contact information and receive a commission on your purchase. Businesses control their own offers. 
          </Typography>
          <Typography variant="body2" paragraph>
            The main goal for HiddenUnderGround.com is to provide 99% accuracy and validity in all the searchable offers on the site so that consumers can be confident in using them. We are here to help businesses make sales and consumers get great offers.
          </Typography>
          <Typography variant="body2" paragraph>
            For frequently asked questions or additional information, visit our <Link to="/help">help page</Link> or send us an <a href="mailto:HelpPlease@hiddenunderground.com">email</a>.
          </Typography>
        </Box>
      </Container>
    </Page>
  )
}

export default AboutPage
