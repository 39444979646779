import React, { useState, useEffect } from 'react'
import { FormControl, TextField, FormHelperText, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { STATE_FULL_NAME } from 'utils/constants'

const CPC_VALUE = 0.65;

const LocationState = ({ error, updateLocation, value }) => {
  const [estimatedCPC, setEstimatedCPC] = useState(0);

  const states = Object.keys(STATE_FULL_NAME).map(s => ({
    label: STATE_FULL_NAME[s],
    value: s,
  }));

  useEffect(() => {
    setEstimatedCPC(CPC_VALUE);
  }, [value]);

  return (
    <FormControl variant="outlined" error={error} fullWidth>
      <Autocomplete
        onChange={(e, val) => updateLocation(val.map(v => v.value))}
        getOptionLabel={o => o.label}
        size="small"
        multiple
        options={states}
        value={states.filter(s => value.indexOf(s.value) !== -1)}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="States"
            InputLabelProps={{
              color: 'primary',
            }}
            variant="outlined"
          />
        )}
      />
      {
        error &&
        <FormHelperText>
          Select at least 1 state from the list
        </FormHelperText>
      }
      <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
        Estimated Cost Per Click: ${estimatedCPC.toFixed(2)}
      </Typography>
    </FormControl>
  )
}

export default LocationState
