import React from 'react'
import  { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import * as uiActions from 'modules/UI/actions'
import { AuthPopupEnum } from 'utils/constants'

const LoginButton = () => {
  const dispatch = useDispatch()
  return (
    <Button onClick={() => {
      dispatch(uiActions.showLogin(AuthPopupEnum.login))
    }}>
      Login
    </Button>
  )
}
export default LoginButton
