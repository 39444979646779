import React from 'react'
import { get } from 'lodash'
import { Box } from '@material-ui/core'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import moment from 'moment'
import { dateFormat, dateTimeFormat } from 'utils/time'

const Chart = ({ config, points, loading, title }) => {
  if (!loading && !points) {
    return (
      <Box
        height={250}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        No data
      </Box>
    )
  }

  if (!config && !points) {
    return (
      <Box
        height={250}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Loading data...
      </Box>
    )
  }

  const localizedPoints = points.map(p => ({
    ...p,
    time: moment.utc(p.time).local().format(dateTimeFormat),
  }))

  return (
    <Box style={{ opacity: loading ? 0.5 : 1 }}>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          data={localizedPoints}
          margin={{ top: 10, right: 10, left: -20, bottom: 0 }}
        >
          <XAxis
            dataKey="time"
            tickFormatter={(l) => {
              if (config.grouping === 'HOUR') {
                return moment.utc(l).format('HH:mm')
              }
              return moment.utc(l).format(dateFormat)
            }}
            tick={{ fontSize: 11 }}
          />
          <YAxis
            domain={get(config, 'domain')}
            tick={{ fontSize: 11 }}
            tickCount={5}
            interval="preserveStartEnd"
          />
          <Tooltip
            formatter={(value, name, props) => [props.payload.displayValue, title]}
            labelFormatter={l => {
              if (config.grouping === 'HOUR') {
                return moment.utc(l).format(dateTimeFormat)
              }
              return moment.utc(l).format(dateFormat)
            }}
            labelStyle={{ fontSize: 12 }}
            contentStyle={{ fontSize: 14 }}
          />
          <Area
            animationDuration={750}
            type="monotone"
            dataKey="count"
            stroke="#0E75BD"
            fillOpacity={0.8}
            fill="#0E75BD"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default Chart
