import { isProd } from './functions'
const STRIPE_DEV = 'pk_live_51PGQFXIFGpWpW0agaaNKIwYLzWRVhoikPaPmspUDX7v8akxc1UAUFeiuNHvAcg1osLJrgpSRt5fAWC1o5wXvnxya00azG05oUz'
const STRIPE_PROD = 'pk_live_51PGQFXIFGpWpW0agaaNKIwYLzWRVhoikPaPmspUDX7v8akxc1UAUFeiuNHvAcg1osLJrgpSRt5fAWC1o5wXvnxya00azG05oUz'

//const STRIPE_DEV = 'pk_test_51PGQFXIFGpWpW0ag3bYlnGQzn9RVOJQechi695t3evQvus2GPe04AKk98lW5MDaeoNHLO5oSIXXAVIApwew10p1q00xEBINkov'
//const STRIPE_PROD = 'pk_test_51PGQFXIFGpWpW0ag3bYlnGQzn9RVOJQechi695t3evQvus2GPe04AKk98lW5MDaeoNHLO5oSIXXAVIApwew10p1q00xEBINkov'
//pk_live_kYi5V4xMAkGgMEqE9MCCTNzx
const GTM_DEV = 'GTM-TXLBMZK'
const GTM_PROD = 'GTM-WXDQNMP'

export const googleApiKey = 'AIzaSyB5qzLnw-LdfExstYLn8C-lMc9HXapWdZU'

export const stripeApiKey = isProd() ? STRIPE_PROD : STRIPE_DEV
export const gtmID = isProd() ? GTM_PROD : GTM_DEV
