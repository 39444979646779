import React from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { LocalOffer, Payment } from '@material-ui/icons'
import { green } from '@material-ui/core/colors';

const chargeTypes = {
  charge_credit: 'charge_credit',
  auto_charge_credit: 'auto_charge_credit',
  coupon_credit: 'coupon_credit',
  debit_coupon: 'debit_coupon',
  debit: 'debit',
}

const chargeLabels = {
  charge_credit: 'Offer Credit Purchase',
  auto_charge_credit: 'Automatic Reload',
  coupon_credit: 'Promotion Credit',
  debit_coupon: 'Promotion Debit',
  debit: 'Offer Debit',
}

const getIcon = (chargeType) => {
  switch (chargeType) {
    case chargeTypes.coupon_credit:
      return <LocalOffer style={{ color: green[500], marginRight: 4 }} />
    case chargeTypes.debit_coupon:
      return <LocalOffer color="error" style={{ marginRight: 4}} />
    case chargeTypes.debit:
      return <Payment color="error" style={{ marginRight: 4}} />
    default:
      return <Payment style={{ color: green[500], marginRight: 4 }} />
  }
}

const ChargeIcon = ({ chargeType }) => {

  if (!chargeType) {
    return '-'
  }

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={chargeLabels[chargeType]}>
        {getIcon(chargeType)}
      </Tooltip>
      {chargeLabels[chargeType] || ''}
    </Box>
  )
}

export default ChargeIcon