import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useSWR, { mutate } from 'swr'
import api from 'utils/api'
import { Box, Button, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import BusinessPage from '../../components/BusinessPage'
import OfferWizard from './components/OfferWizard'
import OfferCard from './components/OfferCard'

const offerTypes = {
  active: 'active',
  archived: 'archived',
  scheduled: 'scheduled',
  paused: 'paused',
  expired: 'expired',
}

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: `1px solid #ddd`,
  },
}))

const OFFERS_PER_PAGE = 6

const Offers = () => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(1)
  const businessId = useSelector(state => state.business.businessId)
  const [offerType, setOfferType] = useState(offerTypes.active)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeNum, setActiveNum] = useState(0);
  const [pausedNum, setPausedNum] = useState(0);
  const [scheduledNum, setScheduledNum] = useState(0);
  const [expiredNum, setExpiredNum] = useState(0);
  const [archivedNum, setArchivedNum] = useState(0);

  const handleChange = (e, val) => {
    setOfferType(val)
    setCurrentPage(1)
  }

  const activeApiRoute = `/offer/business/${businessId}?status=ACTIVE&size=${OFFERS_PER_PAGE}&page=0`
  const { data: activeOffers, activeError } = useSWR(activeApiRoute, () => api.get(activeApiRoute), { shouldRetryOnError: false, revalidateOnFocus: false })
  const activeOffersCount = activeOffers ? activeOffers.totalElements : 0
  useEffect(() => {
    setActiveNum(activeOffersCount);
  }, [activeOffersCount]);

  const pausedApiRoute = `/offer/business/${businessId}?status=PAUSED&size=${OFFERS_PER_PAGE}&page=0`
  const { data: pausedOffers, pausedError } = useSWR(pausedApiRoute, () => api.get(pausedApiRoute), { shouldRetryOnError: false, revalidateOnFocus: false })
  const pausedOffersCount = pausedOffers ? pausedOffers.totalElements : 0
  useEffect(() => {
    setPausedNum(pausedOffersCount);
  }, [pausedOffersCount])

  const scheduledApiRoute = `/offer/business/${businessId}?status=SCHEDULED&size=${OFFERS_PER_PAGE}&page=0`;
  const { data: scheduledOffers, error: scheduledError } = useSWR(scheduledApiRoute, () => api.get(scheduledApiRoute), { shouldRetryOnError: false, revalidateOnFocus: false });
  const scheduledOffersCount = scheduledOffers ? scheduledOffers.totalElements : 0
  useEffect(() => {
    setScheduledNum(scheduledOffersCount);
  }, [scheduledOffersCount]);

  const expiredApiRoute = `/offer/business/${businessId}?status=EXPIRED&size=${OFFERS_PER_PAGE}&page=0`
  const { data: expiredOffers, expiredError } = useSWR(expiredApiRoute, () => api.get(expiredApiRoute), { shouldRetryOnError: false, revalidateOnFocus: false })
  const expiredOffersCount = expiredOffers ? expiredOffers.totalElements : 0
  useEffect(() => {
    setExpiredNum(expiredOffersCount);
  }, [expiredOffersCount]);

  const archivedApiRoute = `/offer/business/${businessId}?status=ARCHIVED&size=${OFFERS_PER_PAGE}&page=0`
  const { data: archivedOffers, archivedError } = useSWR(archivedApiRoute, () => api.get(archivedApiRoute), { shouldRetryOnError: false, revalidateOnFocus: false })
  const archivedOffersCount = archivedOffers ? archivedOffers.totalElements : 0
  useEffect(() => {
    setArchivedNum(archivedOffersCount);
  }, [archivedOffersCount]);

  const apiRoute = `/offer/business/${businessId}?status=${offerType.toUpperCase()}&size=${OFFERS_PER_PAGE}&page=${currentPage - 1}`

  const { data: offers, error } = useSWR(apiRoute, () => api.get(apiRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  return (
    <BusinessPage title="Offers">
      <Box px={2} pb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
        >
          New offer
        </Button>
      </Box>
      <Tabs
        value={offerType}
        onChange={handleChange}
        fullWidth
        classes={classes}
      >
        <Tab value={offerTypes.active} label={`Active (${activeNum})`} />
        <Tab value={offerTypes.paused} label={`Paused (${pausedNum})`} />
        <Tab value={offerTypes.scheduled} label={`Scheduled (${scheduledNum})`} />
        <Tab value={offerTypes.expired} label={`Expired (${expiredNum})`} />
        <Tab value={offerTypes.archived} label={`Archived (${archivedNum})`} />
      </Tabs>
      <Box p={2}>
        <Grid container spacing={2}>
          {
            !offers && !error &&
            <Grid item xs={12} md={10}>
              <Typography>
                Loading your offers
              </Typography>
            </Grid>
          }
          {offers && offers.content && offers.content.map((offer, i) => {
            return (
            <Grid item xs={12} md={10} key={offer.offer.id}>
              <OfferCard {...offer} revalidateOffers={() => mutate(apiRoute)} editOffer={n => setIsModalOpen(n)} 
              offerType = {offerType} offerTypes = {offerTypes}
              activeNum = {activeNum} setActiveNum = {setActiveNum}
              pausedNum = {pausedNum} setPausedNum = {setPausedNum} 
              scheduledNum = {scheduledNum} setScheduledNum = {setScheduledNum}
              expiredNum = {expiredNum} setExpiredNum = {setExpiredNum}
              archivedNum = {archivedNum} setArchivedNum = {setArchivedNum}/>
            </Grid>
          )})}
        </Grid>
        {
          offers && offers.totalPages > 1 &&
          <Box display="flex" justifyContent="center" pt={4}>
            <Pagination page={currentPage} count={offers.totalPages} onChange={(e, newPage) => setCurrentPage(newPage)} color="primary" />
          </Box>
        }
      </Box>
      {
        isModalOpen &&
        <OfferWizard
          offer={typeof isModalOpen === 'number' ? offers.content.find(o => o.offer.offerId === isModalOpen) : undefined}
          offerType = {offerType} offerTypes = {offerTypes}
          activeNum = {activeNum} setActiveNum = {setActiveNum}
          pausedNum = {pausedNum} setPausedNum = {setPausedNum} 
          scheduledNum = {scheduledNum} setScheduledNum = {setScheduledNum}
          expiredNum = {expiredNum} setExpiredNum = {setExpiredNum}
          archivedNum = {archivedNum} setArchivedNum = {setArchivedNum}
          onClose={() => {
            setIsModalOpen(false)
            mutate(apiRoute)
          }}
        />
      }
    </BusinessPage>
  )
}

export default Offers