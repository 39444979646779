import api from 'utils/api'

export const sendImpression = (offerId, info) => {
  return api.put(`/offer/${offerId}/impression`, { info }).catch(() => {})
}

export const listingType = {
  card: 'card',
  list: 'list',
}
