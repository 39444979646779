import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Box, Container, Typography } from '@material-ui/core'
import { Page } from 'components'

const PrivacyPage = () => {
  useEffect(() =>  window.scrollTo(0,0),[])
  return (
    <Page>
      <Helmet>
        <title>Privacy - HiddenUnderground</title>
      </Helmet>
      <Box my={10}>
        <Container maxWidth="md">
          <Typography component="h1" variant="h4">
            Privacy policy
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Last Updated: June 18, 2024
          </Typography>
          <Box my={4} />
          <Typography variant="body2" paragraph>
            HiddenUnderGround, Inc. (together, <b>"HiddenUnderGround,"</b> <b>"HUG,"</b> <b>"we,"</b> <b>"us,"</b> or <b>"our"</b>), including the website www.hiddenunderground.com values its users' privacy on all the HiddenUnderGround websites, mobile site and apps ("Properties"). This Privacy Policy pertains to the services that we offer on our Properties (the "Services"). We have implemented this Privacy Policy to provide you with information about how HiddenUnderGround collects, uses, and discloses information about you. By visiting our Properties or using our Services, you are agreeing to the practices described in this Privacy Policy.
          </Typography>
          <Typography variant="h6" component="h2">
            WHAT TYPE OF INFORMATION DOES HIDDENUNDERGROUND COLLECT FROM ITS USERS?
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround will collect the personal information you provide to us, for example when you register, create an account or Offer, take a survey, sign up for notifications, participate in a promotion, email us or otherwise send us your information. Personal information may include your name, email address, mailing and/or billing address, phone number, company, place of employment, payment card information, and other information that can be used to identify you as an individual. If you access our Services from a mobile or other device, we may collect a unique device identifier assigned to that device. We also collect information about your current location to provide you with more relevant deals. You can use some of the Services without providing any personal information, but some features may not be available.
          </Typography>
          <Typography variant="body2" paragraph>
            Each time you use our Services, HiddenUnderGround also collects other, technical information, which may be combined with any personal information we collect about you. This technical information includes, for example, your browser type, the URL of the previous web site you visited, your ISP, your operating system, and your IP Address. We may use cookies to collect such information as described below. We also use analytics and reporting technologies to collect records of your browsing and activities when using our Properties, as well as other usage information such as your click patterns, your preferences, and the dates and times that you access our Properties or use our Services. If you receive email from us, we will analyze related information such as whether you open the email and whether you click within the email.
          </Typography>
          <Typography variant="body2" paragraph>
            If you log in via a third-party service like Facebook/Meta or Google, we will collect the information described in the third-party service's request for authorization. This information may include your name, email address, gender, city, and state. You should review your privacy settings with these third-party services to be sure you are only sharing information you wish to share.
          </Typography>
          <Typography variant="body2" paragraph>
            When you provide us with your address, we may use third-party services such as the Google Maps API to help complete your address information after you begin to type it. We will combine the address information that we collect through this service with any other information that we collect from or about you, and we will use, retain, and share it as described in this Privacy Policy. Additionally, this address completion service allows Google to collect certain information as described below.
          </Typography>
          <Typography variant="body2" paragraph>
            We may obtain additional personal and other information about you from third parties and combine it with the information that we collect from you.
          </Typography>
          <Typography variant="h6" component="h2">
            HIDDENUNDERGROUND’S USE OF COOKIES
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround uses "cookies," a small data string our server writes to your computer, and other technologies to help us manage and analyze your interaction with our Services. These technologies collect information linked to your browser that we use for a number of purposes, including improving our Services, keeping count of return visits to our Properties, collecting and reporting statistical information about our users, saving your password and login information, and displaying Content (as defined in our Terms Of Use) based on your interests. We collect information about your search criteria and results, the date and time of your visit to our Properties, and your connection speed and IP address. Third parties whose products or services are accessible or advertised via our Services may also use cookies on our Properties, and we encourage you to check their privacy policies for information about their information privacy practices. You may be able to refuse the use of cookies by selecting the appropriate settings on your browser; however, refusing to receive cookies will not stop all data collection and may prevent you from being able to use the full functionality of our Properties.
          </Typography>
          <Typography variant="h6" component="h2">
            THIRD PARTIES DATA COLLECTION
          </Typography>
          <Typography variant="body2" paragraph>
            We use analytics services provided by third parties such as Google, Looker and Tableau. These companies use cookies and other technologies to collect data about your use of our Properties and other web sites over time. This information will be transmitted to and stored by our analytics providers, who will use this information for purposes such as evaluating your use of the website, compiling reports on website activity for website operators, providing HiddenUnderGround with information for customer service purposes, and providing other services relating to activity on our Properties and internet usage. These providers may also transfer this information to third parties where required to do so by law, or where such third parties process the information on their behalf. To learn more about how Google collects and processes data and the choices Google may offer to control these activities, you may visit <a href="https://www.google.com/intl/en/policies/privacy/partners/">https://www.google.com/intl/en/policies/privacy/partners/</a>
          </Typography>
          <Typography variant="body2" paragraph>
            As noted above, when you provide us with your address, we may use third-party services such as the Google Maps API to help provide Geolocation services associated to your address information. This Geolocation service allows Google to collect the Geolocation information that you input, along with your IP address. To learn more about Google's Privacy Policy, you may visit <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
          </Typography>
          <Typography variant="body2" paragraph>
            If you use a credit card provider, or a third-party payment service such as PayPal to pay for purchases that you make on our Properties, the third party may collect information about you, your online activities and/or your purchase and combine that with any information that you have separately provided to that third party. The third party's privacy policy will govern the information you provide to them, including information you provide through your interactions with them on our Properties.
          </Typography>
          <Typography variant="h6" component="h2">
            HOW DOES HIDDENUNDERGROUND USE MY INFORMATION?
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround uses the information collected from users for our business purposes, including the following:
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li>To customize and personalize the Offers and the Content you see.</li>
              <li>To personalize and improve your experience on our Properties and when using our Services.</li>
              <li>To fulfill your requests for our Services.</li>
              <li>To send you information about topics that we think will be of interest to you.</li>
              <li>To alert you to the latest developments and features on our Services.</li>
              <li>To operate, maintain, and improve our Properties and Services.</li>
              <li>To communicate with you, including responding to your communications with us and other communications regarding your registration or use of our Services.</li>
              <li>For our research, recordkeeping, and reporting purposes.</li>
              <li>To monitor our Properties and Services for compliance with our Terms of Service.</li>
            </ul>
          </Typography>
          <Typography variant="h6" component="h2">
            WHEN WILL HIDDENUNDERGROUND DISCLOSE MY INFORMATION TO THIRD PARTIES?
          </Typography>
          <Typography variant="body2" paragraph>
            We may share your personal information with other companies or individuals. For example:
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li>We may share your personal information with third parties for the third party's marketing purposes. Please see below for your choices regarding this sharing.</li>
              <li>We may make any information you disclose when you participate in community and social networking features on our Properties available to other users. HiddenUnderGround is not responsible for the information you disclose in public forums, and we strongly recommend that you disclose your personal information only to users that you know to be trustworthy.</li>
              <li>We may share your personal information with participating merchants, in which case your information may be used and disclosed by the merchant (including for marketing purposes) subject to its privacy policy.</li>
              <li>We may share your personal information with your consent or as otherwise disclosed at the time of collection.</li>
              <li>We may share information with our agents, contractors, service providers, and partners who are providing services on our behalf and on behalf of our merchants and other partners, such as payment processing, data processing, customer service functions, and hosting.</li>
              <li>We may share your information in connection with an actual or potential merger, sale, acquisition, assignment, or transfer of all or part of HiddenUnderGround' assets, affiliates, lines of business, or products, including at bankruptcy.</li>
              <li>We may share your information in order to (i) protect or defend our legal rights or property or the rights/property of our other users, business partners, employees, agents and contractors (including enforcement of our agreements); (ii) pursue available remedies or limit the damages that we may sustain; (iii) protect the safety and security of our employees, users, or members of the public; (iv) protect against fraud or for other risk management purposes; or (v) comply with the law or respond to law enforcement requests, subpoenas, legal process or inquiries.    </li>
            </ul>  
          </Typography>
          <Typography variant="body2" paragraph>
            We may share information that identifies your browser or device over time (including device identifiers and/or personal information which has been hashed or obfuscated) as well as information about your activities on our Properties, with third-party companies for their own purposes including interest-based advertising across different websites, apps, browsers and devices. Please see below for information about opting out of the collection and use of data for interest-based advertising by companies participating in industry self-regulation.
          </Typography>
          <Typography variant="body2" paragraph>
            We also may use and share technical information, de-identified information, and aggregate information with third parties, advertisers and/or business partners for our own business purposes and for third party marketing and other purposes. The use and disclosure of these types of information is not subject to any restrictions under this Privacy Policy.
          </Typography>
          <Typography variant="h6" component="h2">
            INTERNET BASED ADVERTISING 
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround adheres to the Digital Advertising Alliance ("DAA") Self-Regulatory Principles. We may use third-party advertising companies to serve ads when you visit our Properties, and to serve ads on our behalf on non-affiliated properties. These companies may use cookies or other non-cookie technologies to collect information about your visits and activities on our Properties and other online properties over time and across different browsers and devices, in order to provide advertisements for us and others about goods and services of interest to you. This type of advertising is called interest-based advertising. The information collected by these third parties may include information about your browser or device including information that identifies your browser or device over time (including device identifiers and/or personal information which has been hashed or obfuscated).
          </Typography>
          <Typography variant="body2" paragraph>
            You may visit <a href="www.aboutads.info/choices">www.aboutads.info/choices</a> to learn more about interest-based advertising, to opt-out of this form of advertising on your web browser by companies participating in the DAA self-regulatory program, and to learn about the effect of an opt-out choice. If you wish to opt-out of interest-based advertising in mobile apps on your device by companies that participate in the DAA's AppChoices app, you may download that app onto your device and exercise your choice. AppChoices is available at the links below:
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li>
                <a href="https://itunes.apple.com/us/app/appchoices/id894822870?mt%3D8" target="_blank" rel="noopener noreferrer">DAA AppChoices iOS</a>  
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id%3Dcom.DAA.appchoices%26hl%3Den" target="_blank" rel="noopener noreferrer">DAA AppChoices Android</a>  
              </li>
              <li>
                <a href="https://www.amazon.com/Digital-Advertising-Alliance-AppChoices/dp/B00SVQ4FMO" target="_blank" rel="noopener noreferrer">DAA AppChoices Amazon</a>  
              </li>
            </ul>
          </Typography>
          <Typography variant="body2" paragraph>
            You may also limit data collection and use by third parties for interest-based advertising by using your mobile device settings where available.
          </Typography>
          <Typography variant="body2" paragraph>
            Please note that electing to opt-out will not stop advertising from appearing in your browser or applications. It may make the ads you see less relevant to your interests. Additionally, please note that if you use a different browser or erase cookies from your browser, reset your device's advertising identifier, or use a different mobile device, you may need to renew your opt-out choices. Also, any settings available in your web browser to block cookies may not block the use of non-cookie technologies by third parties as described above.
          </Typography>
          <Typography variant="h6" component="h2">
            SECURITY
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround uses commercially reasonable administrative, technical, and physical measures to protect your personal information. HiddenUnderGround uses third party cloud hosting providers to store data and offer its Service. Privacy information pertaining to our cloud hosting providers can be found at:
          </Typography>
          <Typography variant="body2" paragraph>
            <ul>
              <li>
                <a href="https://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer">
                  Amazon AWS Privacy
                </a>
              </li>
              <li>
                <a href="https://cloud.google.com/security/privacy/" target="_blank" rel="noopener noreferrer">
                  Google GCP Privacy
                </a>
              </li>
              <li>
                <a href="https://azure.microsoft.com/en-us/support/legal/" target="_blank" rel="noopener noreferrer">
                  Microsoft Azure Privacy
                </a>
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" component="h2">
            Links to third party ads and properties
          </Typography>
          <Typography variant="body2" paragraph>
            You may be able to access third-party ads, web sites, mobile sites and/or apps directly from links on the Properties. These links may include opportunities to provide personal information to such third parties. This Privacy Policy does not apply when you access such third-party properties. We cannot control the privacy practices of third-party properties, and we are not responsible for such practices. We encourage you to carefully review the privacy policy of any third-party properties you visit or ads that you respond to, before disclosing any information to the providers of such properties.
          </Typography>
          <Typography variant="body2" paragraph>
            Our Properties may include social media plug-ins that link to and share information with third-party social media web sites. If you use these tools to share information about your use of our Properties or otherwise interact with any social media features on our Properties, those social media companies may collect information about you and may use and disclose that information in accordance with their own privacy policies and your account settings. We encourage you to carefully review the privacy policy of any social media company before using any social media plug-ins on our web site to understand the practices that will apply to any information that you provide to these companies.  
          </Typography>
          <Typography variant="h6" component="h2">
            YOUR CALIFORNIA PRIVACY RIGHTS AND CHOICES
          </Typography>
          <Typography variant="body2" paragraph>
            You may give consent for HiddenUnderGround to send you one or more text messages, such as a message with a link to download our app. These text messages are automated. You can use and purchase our services without consenting to receive our automated text messages. If you agree to receive text messages, message and data rates will apply. You can opt out of recurring text messages at any time by replying "STOP" to any message or you can email us at <a href = "HUGprivacy@HiddenUnderGround.com">HUGprivacy@HiddenUnderGround.com</a>
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround may send you email offers that we believe will interest you. If you do not want to receive such offers, you can unsubscribe at any time using the link included in any message. We will continue to send you servicing emails related to your interactions and relationship with us.
          </Typography>
          <Typography variant="body2" paragraph>
            We may share personal information about you with third parties for the third party's own marketing purposes, including sending direct marketing materials to you. If you would like to opt out of this kind of sharing, please send us an email <a href = "HUGprivacy@HiddenUnderGround.com">HUGprivacy@HiddenUnderGround.com</a> to inform us of your decision.
          </Typography>
          <Typography variant="body2" paragraph>
            If you buy a product or service from a third-party merchant on our Properties, you agree to HiddenUnderGround sharing your purchase information with the merchant, and the merchant may then use or disclose your information subject to its privacy policy. If you are a California resident, you may request that HiddenUnderGround give you a list of the personal information that we have shared in this way and the third-party merchants that have received it, by contacting HiddenUnderGround at <a href = "HUGprivacy@HiddenUnderGround.com">HUGprivacy@HiddenUnderGround.com</a> . You can receive this information once per calendar year.
          </Typography>
          <Typography variant="h6" component="h2">
            INTERNATIONAL VISITORS
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround does not currently offer its Services in, or support user accounts from, Switzerland and countries in the European Economic Area. Users from other countries should note that HiddenUnderGround collects, processes and stores information in the United States. By using our Services from locations outside of the United States, you consent to the transfer of your personal information to, and the collection and processing of such personal information in the United States. The laws pertaining to the collection, use, disclosure, and processing of personal information in the United States may vary from those in other countries and may be less stringent than the applicable laws in your state or country.
          </Typography>
          <Typography variant="h6" component="h2">
            CHILDREN’S PRIVACY
          </Typography>
          <Typography variant="body2" paragraph>
            Our Services are not intended to be used by children, and we do not knowingly collect or maintain personal information from children. If we learn that we have collected personal information from children under 13 years old, we will take appropriate actions to delete this information.
          </Typography>
          <Typography variant="body2" paragraph>
            If you are a parent or legal guardian of a child under 13 years old, and you believe that your child has provided personal information to HiddenUnderGround, you may contact us at <a href = "HUGprivacy@HiddenUnderGround.com">HUGprivacy@HiddenUnderGround.com</a> to request that we delete your child's personal information from our systems.
          </Typography>
          <Typography variant="h6" component="h2">
            UPDATING YOUR PERSONAL INFORMATION
          </Typography>
          <Typography variant="body2" paragraph>
            You may review, update, or change information that you have provided to us, or request that we deactivate your account, by emailing us at <a href = "HelpPlease@HiddenUnderGround.com">HelpPlease@HiddenUnderGround.com</a> . If you request that we deactivate your account, we may retain certain personal information in our archives for record-keeping purposes. We are not able to remove personal information from the records or databases of third parties with which we have already shared such information.
          </Typography>
          <Typography variant="h6" component="h2">
            CHANGES TO OUR PRIVACY POLICY
          </Typography>
          <Typography variant="body2" paragraph>
            HiddenUnderGround may make changes to this Privacy Policy from time to time. If we modify this Privacy Policy, we will post an updated Privacy Policy online and link to it from our Properties. We encourage you to regularly review our Privacy Policy so that you are aware of and understand our current privacy practices.
          </Typography>
          <Typography variant="h6" component="h2">
            CONTACT US
          </Typography>
          <Typography variant="body2" paragraph>
            If you have any questions or comments regarding this Privacy Policy, please contact HiddenUnderGround at <a href = "HUGprivacy@HiddenUnderGround.com">HUGprivacy@HiddenUnderGround.com</a> .
          </Typography>
        </Container>
      </Box>
    </Page>
  )
}
export default PrivacyPage
