import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MomentFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Loader } from 'components'
import { fetchBusinesses } from 'modules/User/actions'
import BusinessContainer from './modules/BusinessContainer'
import BusinessCreator from './modules/BusinessCreator'
import api from 'utils/api'

const Business = () => {
  const [loadingBusiness, setLoadingBusiness] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  // const businesses = useSelector(state => state.business)

  useEffect(
    () => {
      if (user.details && !user.businesses) {
        setLoadingBusiness(true)
        api.get('/user/businesses')
          .then(res => {
            setLoadingBusiness(false)
            dispatch(fetchBusinesses(res.content))
          })
      }
    }, [user.businesses, user.details, dispatch]
  )

  if (user.isFetching) {
    return <Loader />
  }

  if (!user.details) {
    history.push('/login', { redirectUrl: location.pathname })
    return null
  }

  if (loadingBusiness || !user.businesses) {
    return <Loader />
  }

  if (!user.businesses.length) {
    if (params.businessId) {
      return <Redirect to="/business" />
    }
    return (
      <BusinessCreator />
    )
  }

  return (
    <MuiPickersUtilsProvider utils={MomentFnsUtils}>
      <Switch>
        <Redirect exact from="/business" to={`/business/${user.details.defaultBusinessId || user.businesses[0].businessId}`} />
        <Route path="/business/:businessId" component={BusinessContainer} />
      </Switch>
    </MuiPickersUtilsProvider>
  )

}

export default Business
