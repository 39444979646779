import React from 'react'
import moment from 'moment'
import { Box, CardActions, Chip, makeStyles } from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';
import { getQuantityUrgencyColor, getTimeUrgencyColor } from 'utils/UI'
import { dateFormat, parsableDateTimeFormat } from 'utils/time'

const useStyles = makeStyles((theme) => ({
  claim: {
    background: props => getQuantityUrgencyColor(props.quantity, theme),
  },
  time: {
    background: props => getTimeUrgencyColor(props.daysRemaining, theme),
  },
}))

const getEndsIn = (days, hours, expirationTime) => {
  if (hours < 0) {
    return 'Expired'
  }
  if (hours < 2) {
    return `Ends in 1 hour`
  }
  if (hours < 24) {
    return `Ends in ${hours} hours`
  }
  if (days === 1) {
    return 'Ends tomorrow'
  }
  if (days < 14) {
    return `Ends in ${days} days`
  }
  return expirationTime ? `Ends on ${expirationTime.format(dateFormat)}` : 'N/A'
}

const OfferMeta = ({ quantity, endDate, isPreview }) => {
  const expirationTime = isPreview ? moment(endDate) : moment(moment.utc(endDate, parsableDateTimeFormat).toISOString())
  const currentTime = moment().local()
  const hoursRemaining = Math.ceil(expirationTime.diff(currentTime, 'hours', true))
  const daysRemaining = Math.floor(hoursRemaining / 24)
  const classes = useStyles({ quantity, daysRemaining })

  const qty = isPreview ? 100 : quantity
  const qtyLabel = qty ? `${qty} left` : 'depleted'

  return (
    <CardActions disableSpacing>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Chip className={classes.claim} label={qtyLabel} />
        <Chip className={classes.time} label={getEndsIn(daysRemaining, hoursRemaining, expirationTime)} icon={<QueryBuilder htmlColor={daysRemaining < 5 ? '#fff' : 'unset'} />} variant="outlined" />
      </Box>
    </CardActions>
  )
}

export default OfferMeta
