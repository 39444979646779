import React, { useState } from 'react'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import {
  Box,
  Button,
  CircularProgress,
  Chip,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Container, Page, Offers } from 'components'
import CreateAlert from './components/CreateAlert'
import ListIcon from '@material-ui/icons/ViewList'
import CardIcon from '@material-ui/icons/ViewModule'
import FilterIcon from '@material-ui/icons/FilterList'
import CancelIcon from '@material-ui/icons/Cancel'
import useSWR from 'swr'
import api from 'utils/api'
import { useLocation } from 'utils/location'
import TopOffers from 'modules/LandingPage/components/TopOffers'

const filterTypes = {
  expireSoon: 'expireSoon',
  global: 'global',
  local: 'local',
}

const filterTypeLabels = {
  expireSoon: 'Expire soon',
  global: 'Global offers',
  local: 'Local offers',
}

const getBusinessName = (offers) => {
  return get(offers, 'content[0].businessName')
}

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(2),
  },
}))

const SearchPageContainer = () => {
  const userLocation = useLocation()
  const [showFilterMenu, setShowFilterMenu] = useState()
  const [global, setGlobal] = useQueryParam(filterTypes.global)
  const [expireSoon, setExpireSoon] = useQueryParam(filterTypes.expireSoon)
  const [local, setLocal] = useQueryParam(filterTypes.local)
  const [radius, setRadius] = useQueryParam('radius')
  const [query] = useQueryParam('query')
  const [selectedCategory, setSelectedCategory] = useQueryParam('category')
  const [businessId, setBusinessId] = useQueryParam('businessId')
  const [currentPage, setCurrentPage] = useQueryParam(
    'currentPage',
    NumberParam
  )
  const [businessName] = useQueryParam('businessName')
  const [listMode, setListMode] = useState('card')
  const { lat, lng } = useSelector((s) => ({
    lat: get(s, 'user.location.latitude'),
    lng: get(s, 'user.location.longitude'),
  }))
  const searchApi = `/offer/search?rows=12&query=${query || ''}&latitude=${
    lat || ''
  }&longitude=${lng || ''}&start=${((currentPage || 1) - 1) * 12}&category=${
    selectedCategory || ''
  }&businessId=${businessId || ''}&global=${global || 'false'
  }&expireSoon=${expireSoon || 'false'}&local=${local || 'false'}&radius=${radius || ''}`
  const { data: offers, error } = useSWR(
    userLocation ? searchApi : null,
    () => api.get(searchApi),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    }
  )

  const handleFilter = (filterType) => () => {
    switch (filterType) {
      case filterTypes.expireSoon:
        setShowFilterMenu(null)
        setRadius(undefined)
        setGlobal(undefined)
        setLocal(undefined)
        setExpireSoon(true)
        return
      case filterTypes.global:
        setShowFilterMenu(null)
        setRadius(undefined)
        setExpireSoon(undefined)
        setLocal(undefined)
        setGlobal(true)
        return
      case filterTypes.local:
        setShowFilterMenu(null)
        setGlobal(undefined)
        setExpireSoon(undefined)
        setLocal(true)
        setRadius(10)
        return
      default:
        setShowFilterMenu(null)
        setRadius(undefined)
        setExpireSoon(undefined)
        setLocal(undefined)
        setGlobal(undefined)
    }
  }

  const classes = useStyles()

  if (error) {
    return (
      <Page>
        <Helmet>
          <title>Search offers - HiddenUnderground</title>
        </Helmet>
        <Container>
          <Box p={4} display="flex" justifyContent="center">
            <Typography variant="h6" color="error">
              Something went wrong
            </Typography>
          </Box>
        </Container>
      </Page>
    )
  }

  if (!offers) {
    return (
      <Page>
        <Helmet>
          <title>Search offers - HiddenUnderground</title>
        </Helmet>
        <Container>
          <Box p={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Container>
      </Page>
    )
  }

  const filteredCategoryOffers = offers.content
  const getCurrentFilter = () => {
    if (expireSoon) {
      return filterTypeLabels[filterTypes.expireSoon]
    }
    if (global) {
      return filterTypeLabels[filterTypes.global]
    }
    if (local) {
      return filterTypeLabels[filterTypes.local]
    }
    return 'Filter'
  }

  return (
    <Page>
      <Helmet>
        <title>Search offers - HiddenUnderground</title>
      </Helmet>
      <Container>
        <Box>
          {query && (
            <>
              <Typography component="h2" variant="h5" gutterBottom>
                Showing offers for '{query}'
              </Typography>
            </>
          )}
          {businessId && (
            <Box display="flex" alignItems="end">
              <Typography
                component="h2"
                variant="h6"
                gutterBottom
                style={{ fontWeight: 400 }}
              >
                {`Selected business: ${businessName || getBusinessName(offers) || `Business ID ${businessId}`}`}
              </Typography>
              <IconButton size="small" onClick={() => setBusinessId(undefined)}>
                <CancelIcon color="disabled" />
              </IconButton>
            </Box>
          )}
          <Typography variant="body1" color="textSecondary">
            {`${offers.totalElements} offers found`}
          </Typography>
        </Box>
        {offers && offers.categories && !!offers.categories.length && (
          <Box display="flex" my={2} overflow="auto">
            {offers.categories.map((category) => (
              <Chip
                className={classes.chip}
                clickable
                color={category === selectedCategory ? 'primary' : undefined}
                onClick={() => {
                  setSelectedCategory(category)
                  setCurrentPage(1)
                }}
                onDelete={
                  category === selectedCategory
                    ? () => {
                        setSelectedCategory()
                        setCurrentPage(1)
                      }
                    : undefined
                }
                label={category}
              />
            ))}
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <div>
            {offers && !!offers.totalElements && (
              <>
                <Hidden smDown>
                  <>
                    <IconButton
                      aria-label="list view"
                      onClick={() => setListMode('list')}
                      color={listMode === 'list' ? 'primary' : 'default'}
                    >
                      <ListIcon />
                    </IconButton>
                    <IconButton
                      aria-label="cards view"
                      onClick={() => setListMode('card')}
                      color={listMode === 'card' ? 'primary' : 'default'}
                    >
                      <CardIcon />
                    </IconButton>
                  </>
                </Hidden>
                <>
                  <Button
                    color={(!expireSoon && !local && !global) ? 'default' : 'secondary'}
                    disableElevation
                    onClick={(e) => setShowFilterMenu(e.currentTarget)}
                    size="small"
                    startIcon={<FilterIcon />}
                    variant="contained"
                  >
                    { getCurrentFilter() }
                  </Button>
                  <Menu
                    anchorEl={showFilterMenu}
                    id="filter-menu"
                    keepMounted
                    open={Boolean(showFilterMenu)}
                    onClose={() => setShowFilterMenu(null)}
                  >
                    <MenuItem
                      selected={!expireSoon && !local && !global}
                      dense
                      onClick={handleFilter()}
                    >
                      None
                    </MenuItem>
                    <MenuItem
                      selected={!!expireSoon}
                      dense
                      onClick={handleFilter(filterTypes.expireSoon)}
                    >
                      Expire soon
                    </MenuItem>
                    <MenuItem
                      selected={!!local}
                      dense
                      onClick={handleFilter(filterTypes.local)}
                    >
                      Local offers
                    </MenuItem>
                    <MenuItem
                      selected={!!global}
                      dense
                      onClick={handleFilter(filterTypes.global)}
                    >
                      Global offers
                    </MenuItem>
                  </Menu>
                </>
              </>
            )}
          </div>
        </Box>
        {offers && !offers.totalElements && (
          <CreateAlert
            alertConfig={{
              query,
              lng,
              lat,
              radius,
              expireSoon,
              global,
              selectedCategory,
              businessId,
            }}
          />
        )}
        {offers && !!offers.totalElements && (
          <>
            <Offers offers={filteredCategoryOffers} offerType={listMode} />
            <Box display="flex" justifyContent="center" pt={4}>
              <Pagination
                page={currentPage}
                count={offers.totalPages}
                onChange={(e, newPage) => setCurrentPage(newPage)}
                color="primary"
              />
            </Box>
          </>
        )}
        <Box my={6}>
          <Box mb={3}>
            <Typography component="h3" variant="h5" gutterBottom>
              Popular offers
            </Typography>
          </Box>
          <TopOffers />
        </Box>
      </Container>
    </Page>
  )
}

export default SearchPageContainer
