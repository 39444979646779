import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import { Clear, Done } from '@material-ui/icons'
import LockButton from '../LockButton'

const T = ({
  changeOrder,
  changeOrderBy,
  columns,
  loading,
  order,
  orderBy,
  rows,
}) => {
  const handleSortChange = (k) => () => {
    if (k === orderBy) {
      changeOrder(order === 'asc' ? 'desc' : 'asc')
      return
    }
    changeOrderBy(k)
    changeOrder('desc')
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((c) => (
              <TableCell
                key={c.key}
                sortDirection={orderBy === c.key ? order : false}
                align={c.align || 'left'}
              >
                <TableSortLabel
                  active={orderBy === c.key}
                  direction={orderBy === c.key ? order : 'desc'}
                  disabled={!c.sortable}
                  onClick={handleSortChange(c.key)}
                >
                  {c.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ opacity: loading ? 0.5 : 1 }}>
          {rows.map((data, i) => (
            <TableRow key={data.userId}>
              {columns.map((c) => {
                if (c.key === 'action') {
                  return <TableCell key="action"><LockButton id={data.userId} isLocked={!data.active} /></TableCell>
                }
                if (c.boolean) {
                  return <TableCell key={c.key}>
                    {
                      data[c.key] ?
                      <Done color="primary" />
                      :
                      <Clear />
                    }
                  </TableCell>
                } 
                return <TableCell align={c.align || 'left'} key={c.key}>{data[c.key] || '-'}</TableCell>
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default T
