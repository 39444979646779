import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Box, Button, Typography } from '@material-ui/core'
import * as uiActions from 'modules/UI/actions'
import api from 'utils/api'
import { AuthPopupEnum } from 'utils/constants'

const CreateAlert = ({ alertConfig }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { details: userDetails } = useSelector(s => s.user)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitAlert = () => {
    if (!userDetails) {
      dispatch(uiActions.showLogin(AuthPopupEnum.register))
      return
    }
    const payload = {
      query: alertConfig.query,
      longitude: alertConfig.lng,
      latitude: alertConfig.lat,
      radius: alertConfig.radius,
      expireSoon: alertConfig.expireSoon,
      global: alertConfig.global,
      category: alertConfig.selectedCategory,
      businessId: alertConfig.businessId,
    }
    setIsSubmitting(true)
    api
      .post('/user/query/add', payload)
      .then(() => {
        enqueueSnackbar('Created alert for this search', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Cannot create alert', { variant: 'error' })
      })
    
  }

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        There are currently no offers, if you would like to be notified
        when these offers arrive please create alert for it
      </Typography>
      <Box mt={3}>
        <Button color="primary" disabled={isSubmitting} variant="contained" onClick={submitAlert}>
          Create alert
        </Button>
      </Box>
    </>
  )
}

export default CreateAlert
