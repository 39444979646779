import React from 'react'
import AccountHeader from '../AccountHeader'
import { useIsMobile } from 'utils/UI'

const AccountPage = ({ children, title }) => {
  const isMobile = useIsMobile()
  return (
    <main
      style={{
        marginLeft: isMobile ? 0 : 300,
        minHeight: '90vh',
      }}
    >
      <AccountHeader title={title} />
      {children}
    </main>
  )
}

export default AccountPage
