import { useSelector } from 'react-redux'
import { get } from 'lodash'

const roles = {
  ROLE_USER: 'ROLE_USER',
  ROLE_BUSINESS: 'ROLE_BUSINESS',
  ROLE_ADMIN: 'ROLE_ADMIN',
}

export const useBusinessUser = () => {
  const user = useSelector((state) => state.user)
  if (!get(user, 'details.userRoles')) {
    return false
  }
  return !!user.details.userRoles.find(r => r === roles.ROLE_BUSINESS)
}

export const useAdminUser = () => {
  const user = useSelector((state) => state.user)
  if (!get(user, 'details.userRoles')) {
    return false
  }
  return !!user.details.userRoles.find(r => r === roles.ROLE_ADMIN)
}

export default roles