import React from 'react'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import ChargeIcon from '../ChargeIcon'

const T = ({
  changeOrder,
  changeOrderBy,
  columns,
  loading,
  order,
  orderBy,
  rows,
}) => {
  const handleSortChange = (k) => () => {
    if (k === orderBy) {
      changeOrder(order === 'asc' ? 'desc' : 'asc')
      return
    }
    changeOrderBy(k)
    changeOrder('desc')
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((c) => (
              <TableCell
                key={c.key}
                sortDirection={orderBy === c.key ? order : false}
                align={c.align || 'left'}
              >
                <TableSortLabel
                  active={orderBy === c.key}
                  direction={orderBy === c.key ? order : 'desc'}
                  disabled={!c.sortable}
                  onClick={handleSortChange(c.key)}
                >
                  {c.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ opacity: loading ? 0.5 : 1 }}>
          {rows.map((data) => (
            <TableRow>
              {columns.map((c) => {
                if (c.key === 'transactionDatetime') {
                  return <TableCell>
                    {moment.utc(data.transactionDatetime).local().format('MM/DD/YYYY HH:mm:ss')}
                  </TableCell>
                }
                if (c.key === 'type') {
                  return <TableCell>
                    <ChargeIcon chargeType={data.type} />
                  </TableCell>
                }
                return <TableCell align={c.align || 'left'}>{data[c.key]}</TableCell>
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default T
