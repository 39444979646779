import React, {useState} from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import OfferListItem from 'components/offers/OfferListItem'
import { useIsMobile } from 'utils/UI'

const OfferListPreview = ({ offer }) => {
  const [showPreview, setShowPreview] = useState()
  const isMobile = useIsMobile()

  if (isMobile) {
    return null
  }

  return (
    <>
      <Button size="small" onClick={() => setShowPreview(true)}>
        Preview as list
      </Button>
      {
        showPreview &&
        <Dialog open fullWidth maxWidth="lg">
          <DialogContent>
            <OfferListItem
              offer={offer}
              isPreview
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPreview(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

export default OfferListPreview
