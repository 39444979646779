import { combineReducers } from 'redux'
import businessReducer from 'modules/Business/reducer'
import userReducer from 'modules/User/reducer'
import uiReducer from 'modules/UI/reducer'

const rootReducer = combineReducers({
  business: businessReducer,
  ui: uiReducer,
  user: userReducer,
})

export default rootReducer