import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  makeStyles,
  Typography} from '@material-ui/core'
import KeywordsEditor from '../KeywordEditor'

const useStyles = makeStyles(() => ({
  chip: {
    margin: '0 4px 4px 0',
  }
}))

const Keywords = () => {
  const [showEditor, setShowEditor] = useState(false)
  const classes = useStyles()
  const businessId = useSelector(state => state.business.businessId)
  const keywords = useSelector(state => state.business.keywords)

  return (
    <>
      <Card>
        <CardHeader subheader="Business keywords" />
        <CardContent>
          {
            keywords.length !== 0 ?
                keywords.map(k => (
                    <Chip className={classes.chip} color="primary" key={k} label={k} />
                    ))
                :
                <Typography color="textSecondary" variant="body2" paragraph>
                  You didn't define any business keyword
                </Typography>
          }
        </CardContent>
        <CardActions>
          <Button color="primary" onClick={() => setShowEditor(true)}>
            {keywords.length !== 0 ? "Edit keywords" : "Add keywords"}
          </Button>
        </CardActions>
      </Card>
      {
        showEditor &&
        <KeywordsEditor
          businessId={businessId}
          keywords={keywords}
          onClose={() => {
            setShowEditor(false)
          }}
        />
      }
    </>
  )
}

export default Keywords
