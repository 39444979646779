import React, { useEffect } from 'react';
import Helmet from 'react-helmet'
import {
  Box,
  Container,
  useMediaQuery,
  useTheme,
  Typography,
} from '@material-ui/core'
import { Page } from 'components'

import SearchForm from 'components/Header/components/SearchForm'
import ImageSrc from 'components/Logo/LogoFull.png'
import TopOffers from './components/TopOffers';

const LandingPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => window.scrollTo(0, 0), [])
  
  return (
    <Page>
      <Helmet>
        <title>HiddenUnderground - Unearth Great Deals</title>
      </Helmet>
      <Container maxWidth="xl" fullWidth>
        <Box mb={6} mt={2} display="flex" alignItems="center" justifyContent="center">
          <img src={ImageSrc} alt="HiddenUnderground logo" height={matches ? 60 : 80} />
        </Box>
        <Box mb={6} display="flex" alignItems="center" justifyContent="center">
          <SearchForm />
        </Box>
        <Box my={6}>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>Popular offers</Typography>
          </Box>
          <TopOffers />
        </Box>
      </Container>
    </Page>
  );
};


export default LandingPage