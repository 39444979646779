import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Box, Button, Typography } from '@material-ui/core'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import { changeLocation } from 'modules/User/actions'
import AddressSearch from 'modules/Business/modules/Offers/components/OfferAudience/components/AddressSearch'
import api from 'utils/api'

const LocationForm = ({ location, onClose }) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()
  const [showSearch, setShowSearch] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.details)

  const handleClick = () => {
    const notification = enqueueSnackbar('Calculating your location...', { persist: true, variant: 'info' })
    if (onClose) {
      onClose()
    }
    navigator.geolocation.getCurrentPosition(
      async ({ coords }) => {
        const { latitude, longitude } = coords
        const fullLocation = await api.post('/geo', { latitude, longitude })
        dispatch(changeLocation(fullLocation))
        closeSnackbar(notification)
        enqueueSnackbar("Got your location!", {autoHideDuration: 3_000, variant: 'success'})
      },
      (error) => {
        closeSnackbar(notification)
        if (error.code === error.PERMISSION_DENIED) {
          enqueueSnackbar('Your rejected location sharing', { variant: 'error' })
        } else {
          enqueueSnackbar("Could not get your location!", {variant: 'error'})
        }
      },
      { enableHighAccuracy: false, timeout: 10_000 }
    );
  }

  const handleSelect = async (locationObject) => {
    onClose()
    try {
      const fullLocation = await api.post('/geo', locationObject)
      dispatch(changeLocation(fullLocation))
    } catch {
      enqueueSnackbar('We cannot set your location', { variant: 'error' })
    }
  }

  return (
    <Box px={2} py={1}>
      <Typography variant="body2" color="textSecondary">
      Filter offers by location:
      </Typography>
      {
        location &&
        <Box display="flex" alignItems="center" mt={1.5} mb={1}>
          <RoomOutlinedIcon style={{ marginRight: 8 }} />
          <Typography variant="body2">
            {
              location.city ? `${location.city}, `: ''
            }
            {
              location.region_name ?
              `${location.region_name}, ${location.country_code}`
              :
              `${location.country_name}`
            }
          </Typography>
        </Box>
      }
      <Button onClick={handleClick} color="primary">
        Use my current position
      </Button>
      {
        user &&
        <Button onClick={() => setShowSearch(true)} color="primary">
          Enter location manually
        </Button>
      }
      {
        showSearch &&
        <Box p={1}>
          <AddressSearch onSelect={handleSelect} label={"Your address"}/>
        </Box>
      }
    </Box>
  )
}

export default LocationForm
