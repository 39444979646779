import React, { useCallback, useState } from 'react'
import { Button, Box, Grid } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import BusinessPage from '../../components/BusinessPage'
import { useCoupons } from './utils'
import Table from './components/Table'
import CouponForm from './components/CouponForm'

const Coupons = () => {
  const usersPerPage = 100
  const [showForm, setShowForm] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [orderBy, setOrderBy] = useState('expirationDate')
  const [order, setOrder] = useState('desc')

  const { coupons, loadingCoupons, totalPages, mutate } = useCoupons({ page: currentPage, order, orderBy, size: usersPerPage })

  const handleOpen = useCallback(() => {
    setShowForm(true)
  }, [])

  return (
    <BusinessPage title="Coupons">
      <Box px={2}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Create new coupon
        </Button>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid xs={12} lg={12}>
            <Table
              mutate={mutate}
              rows={coupons}
              changeOrderBy={v => setOrderBy(v)}
              changeOrder={c => setOrder(c)}
              loading={loadingCoupons}
              order={order}
              orderBy={orderBy}
            />
          </Grid>
          {
            totalPages > 1 &&
            <Grid xs={12} lg={12}>
              <Box display="flex" justifyContent="center" pt={4}>
                <Pagination page={currentPage} count={totalPages} onChange={(e, newPage) => setCurrentPage(newPage)} color="primary" />
              </Box>
            </Grid>
          }
        </Grid>
      </Box>
      {
        showForm &&
        <CouponForm onClose={() => setShowForm(false)} onSuccess={mutate} />
      }
    </BusinessPage>
  )
}

export default Coupons
