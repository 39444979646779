import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { get } from 'lodash'
import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import { ConfirmationDialog } from 'components'
import api from 'utils/api'
import * as businessActions from 'modules/Business/actions'
import * as userActions from 'modules/User/actions'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.error.main,
  }
}))

const PaymentCard = ({ card }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [confirm, setConfirm] = useState(false)
  const businessId = useSelector(state => get(state, 'business.businessId'))

  const handleDelete = useCallback(
    () => {
      api
        .delete(`/stripe/customer/card/${card.id}`)
        .then(() => {
          setConfirm(false)
          enqueueSnackbar('Card deleted', { variant: 'success' })
          dispatch(businessActions.fetchBusiness(businessId, true))
          dispatch(userActions.fetchUser())
        })
        .catch(() => {
          enqueueSnackbar('Cannot delete card', { variant: 'error' })
        })
    }, [businessId, card.id, dispatch, enqueueSnackbar]
  )

  return (
    <>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {card.brand} **** **** **** {card.last4}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Expires on {card.expMonth}/{card.expYear}
          </Typography>
        </CardContent>
        <CardActions>
          <Button classes={classes} onClick={() => setConfirm(true)}>
            Delete card
          </Button>
        </CardActions>
      </Card>
      {
        confirm &&
        <ConfirmationDialog
          isOpen
          title="Delete card"
          body="Are you sure you want to delete your card?"
          successButtonLabel="Delete"
          onCancel={() => setConfirm(false)}
          onConfirm={handleDelete}
        />
      }
    </>
  )
}

export default PaymentCard
