import Cookies from 'js-cookie'
import api from 'utils/api'
import { getBrowserPosition, getSavedLocation, persistLocation } from 'utils/location'
import { getParameterByName } from 'utils/functions'
import * as types from './actionTypes'

export const fetchUser = () => dispatch => {
  api.get('/user/me')
    .then(res => {
      dispatch(fetchUserSuccess(res))
    })
    .catch(() => {
      dispatch(fetchUserError())
    })
}

export const fetchUserSuccess = (userData) => {
  return {
    type: types.FETCH_USER_SUCCESS,
    details: userData,
  }
}

export const fetchUserError = () => ({
  type: types.FETCH_USER_ERROR,
})

export const logoutUser = () => dispatch => {
  api.get('/auth/logout')
    .then(() => {
      Cookies.remove('access_token')
      window.location.href = '/?logout=1'
    })
}

export const logout = () => ({
  type: types.LOGOUT,
})

const fetchLocation = (location) => ({
  type: types.FETCH_LOCATION,
  location,
})

export const fetchUserLocation = () => async (dispatch) => {
  const savedLocation = getSavedLocation()

  if (savedLocation) {
    const isLoggedOut = getParameterByName('logout')
    if (isLoggedOut) {
      const { latitude, longitude } = savedLocation
      const fullLocation = await api.post('/geo', { latitude, longitude })
      dispatch(changeLocation(fullLocation))
      return
    }
    dispatch(fetchLocation(savedLocation))
    return
  }

  if (navigator.permissions) {
    const geoPermission = await navigator.permissions.query({ name: 'geolocation' })
    try {
        if (geoPermission.state === 'granted') {
         const { latitude, longitude } = await getBrowserPosition()
         const fullLocation = await api.post('/geo', { latitude, longitude })
         dispatch(changeLocation(fullLocation))
         return
        }     
    } catch(error){
    }
  }

  api.post(`/geo`, {})
    .then((res) => {
      dispatch(changeLocation(res))
    })
}

export const changeLocation = (location) => (dispatch) => {
  dispatch(fetchLocation(location))
  persistLocation(location)
}

const fetchFavorites = (favorites) => ({
  type: types.FETCH_FAVORITES,
  favorites,
})

export const fetchUserFavorites = () => (dispatch) => {
  api.get(`/favorite/ids`)
    .then((res) => {
      dispatch(fetchFavorites(res))
    })
}

export const fetchBusinesses = (businesses) => ({
  type: types.FETCH_BUSINESSES,
  data: businesses,
})

export const cleanupBusinesses = (businesses) => ({
  type: types.CLEANUP_BUSINESSES,
  data: businesses,
})
