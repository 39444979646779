import React from 'react'
import { Box, Typography } from '@material-ui/core';

const OfferHeader = ({ h1, h2, location }) => (
  <Box px={2} py={1}>
    <Typography variant="h5" component="h3" style={{ fontSize: 22, fontWeight: 500 }}>
      {h1}
    </Typography>
    <Typography variant="h6" component="h4" style={{ fontSize: 16, fontWeight: 400 }}>
      {h2}
    </Typography>
    <Typography variant="body2" color="primary">
      {location || 'Global'}
    </Typography>
  </Box>
)

export default OfferHeader
