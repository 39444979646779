import React, { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box,  makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import api from 'utils/api'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.secondary.main,
    borderRadius: 0,
    color: '#fff',
  },
  select: {
    padding: '20px',
    background: theme.palette.secondary.main,
    '&:focus': {
      background: theme.palette.secondary.main,
    }
  },
  focused: {
    background: theme.palette.secondary.main,
    color: '#fff',
  },
  icon: {
    fill: '#fff',
  },
  input: {
    border: 0,
    borderRadius: 0,
    background: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const CompanySelector = () => {
  const [query, setQuery] = useState('')
  const classes = useStyles()
  const history = useHistory()
  const businesses = useSelector((state) => state.user.businesses)
  const [options, setOptions] = useState(businesses)
  const currentBusiness = useSelector(state => state.business)
  const [debouncedQuery] = useDebounce(query, 250);

  const handleSelect = useCallback(
    (e, newValue) => {
      const id = newValue.businessId
      history.push(`/business/${id}`)
    }, [history]
  )

  const handleChange = (e, val, reason) => {
    if (reason !== 'reset'){
      setQuery(val)
    }
  }

  const searchBusiness = useCallback(
    async () => {
      const res = await api.get(`/user/businesses?search=${debouncedQuery}`)
      return res.content
    }, [debouncedQuery]
  )

  useEffect(() => {
    async function getResults() {
      if (!debouncedQuery) {
        setOptions(businesses)
        return
      }
  
      try {
        const newOptions = await searchBusiness()
        setOptions(newOptions)
      } catch(e) {
        console.error(e)
      }
    }
    getResults()
  }, [debouncedQuery, searchBusiness, businesses])

  return (
    <Box py={3}>
      <Autocomplete
        disableClearable
        getOptionLabel={o => o.companyInfo != null ? o.companyInfo.companyName : "No business yet!"}
        onInputChange={handleChange}
        onChange={handleSelect}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            className={classes.input}
            fullWidth
            InputLabelProps={{
              color: 'primary',
            }}
            variant="outlined"
          />
        )}
        value={currentBusiness}
      />
    </Box>
  )
}

export default CompanySelector
