import React, { useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import api from 'utils/api'

const isBusinessCategory = (category, bussinessCategories) => {
  return !!bussinessCategories.find(c => c.categoryId === category.categoryId)
}

const CategorySelector = ({
  control
}) => {
  const [options, setOptions] = useState([])
  const [defaultOptions, setDefaultOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const businessCategories = useSelector(state => state.business.categories)

  useEffect(
    () => {
      api.get('/category')
        .then(resData => {
          setOptions(resData.content)
          setDefaultOptions(resData.content)
        })
    }, []
  )

  const handleInput = (e, value) => {
    if (!value) {
      setOptions(defaultOptions)
      return
    }
    setOptions([])
    setIsLoading(true)
    api.get(`/category?name=${value}`)
      .then(resData => {
        setOptions(resData.content)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getLabel = useCallback(o => o.categoryName, [])

  return (
    <Controller
      name="category"
      control={control}
      render={({ onChange, value }) => (
        <Autocomplete
          getOptionLabel={getLabel}
          loading={isLoading}
          onChange={(e, val) => {
            onChange(val)
          }}
          size="small"
          multiple
          onInputChange={handleInput}
          options={options}
          value={value}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              return (
                <Chip
                  {...getTagProps({ index })}
                  label={option.categoryName}
                  color={isBusinessCategory(option, businessCategories) ? 'primary' : undefined}
                />
              )
              })
          }}
          renderInput={params => (
            <TextField
            {...params}
              fullWidth
              helperText="Pick your category or search for a custom category"
              label="Categories"
              InputLabelProps={{
                color: 'primary',
              }}
              variant="outlined"
            />
          )}
        />
      )}
    />
  )
}

export default CategorySelector
