import React, { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce';
import { useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import api from 'utils/api'

const BusinessSelect = ({ onChange }) => {
  const [query, setQuery] = useState('')
  const businesses = useSelector((state) => state.user.businesses)
  const [options, setOptions] = useState(businesses)
  const [debouncedQuery] = useDebounce(query, 250);

  const handleSelect = useCallback(
    (e, newValue) => {
      const id = newValue.businessId
      onChange(id)
    }, [onChange]
  )

  const handleChange = (e, val, reason) => {
    if (reason !== 'reset'){
      setQuery(val)
    }
  }

  const searchBusiness = useCallback(
    async () => {
      const res = await api.get(`/user/businesses?search=${debouncedQuery}`)
      return res.content
    }, [debouncedQuery]
  )

  useEffect(() => {
    async function getResults() {
      if (!debouncedQuery) {
        setOptions(businesses)
        return
      }
  
      try {
        const newOptions = await searchBusiness()
        setOptions(newOptions)
      } catch(e) {
        console.error(e)
      }
    }
    getResults()
  }, [debouncedQuery, searchBusiness, businesses])

  return (
    <Autocomplete
      disableClearable
      getOptionLabel={o => o.companyInfo.companyName}
      onInputChange={handleChange}
      onChange={handleSelect}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="Business"
          InputLabelProps={{
            color: 'primary',
          }}
          margin="dense"
          variant="outlined"
        />
      )}
    />
  )
}

export default BusinessSelect
