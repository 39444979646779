import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation
import { theme } from 'utils/theme';
import Header from './HeaderWithoutIcon/Header';  // Import the Header component
import Footer from './Footer/index';  // Import the Footer component

const RegistrationSuccess = () => {
  const history = useHistory(); // Initialize useHistory
  const [countdown, setCountdown] = useState(10); // State for countdown

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      history.push('/'); // Redirect to home page after 10 seconds
    }, 10000);

    return () => {
      clearInterval(timer); // Clear countdown interval if component unmounts
      clearTimeout(redirectTimer); // Clear redirect timeout if component unmounts
    };
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        textAlign="center"
        p={4}
      >
        <Typography variant="h4" style={{ fontWeight: 700, marginBottom: 20 }}>
          Thank you!
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 20 }}>
          Please verify your account by clicking on the verification link sent to your email.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Redirecting to the home page in {countdown} seconds...
        </Typography>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default RegistrationSuccess;
