import * as types from './actionTypes'
import * as BusinessTypes from '../Business/actionTypes'

const initialState = {
  isFetching: true,
  location: undefined,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER:
      return {
        ...state,
        isFetching: true,
      }
    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.details,
      }
    case types.FETCH_USER_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case types.FETCH_LOCATION:
      return {
        ...state,
        location: action.location,
      }
    case types.FETCH_BUSINESSES:
      return {
        ...state,
        businesses: action.data,
      }
    case types.FETCH_FAVORITES:
      return {
        ...state,
        favorites: action.favorites,
      }
    case BusinessTypes.UPDATE_BUSINESS_COMPANY_INFO:
      // Update company info of updated business.
      const companyInfo = action.companyInfo
      const currentBusinessRecord = state.businesses.find(
        (record) => record.businessId === companyInfo.businessId
      )

      const newBusinessRecord = {
        ...currentBusinessRecord,
        companyInfo: {
          ...companyInfo,
        },
      }

      return {
        ...state,
        businesses: state.businesses
          .filter((record) => record.businessId !== companyInfo.businessId)
          .concat([newBusinessRecord]),
      }
    case types.CLEANUP_BUSINESSES:
      return {
        ...state,
        businesses: undefined,
      }
    case types.LOGOUT:
      return {
        ...state,
        details: undefined,
      }
    default:
      return state
  }
}

export default userReducer
