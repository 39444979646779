import React from "react";
import { Box, Card } from '@material-ui/core'
import OfferContent from "./components/OfferContent";
import OfferCover from "./components/OfferCover";
import OfferHeader from "./components/OfferHeader";
import OfferMeta from "./components/OfferMeta";
import OfferActions from "../OfferActions";
import { useStyles } from "./styles";

const OfferCard = ({
  active,
  id,
  description,
  claimsRemaining,
  details,
  distance,
  endDate,
  imageUrl = 'https://images.unsplash.com/photo-1449824913935-59a10b8d2000?&auto=format&fit=crop&w=800&q=80',
  bussinessId,
  businessImageUrl,
  headline1,
  headline2,
  isPreview,
  revalidateList,
}) => {
  const classes = useStyles()
  return (
    <Card className={classes.root} style={{ opacity: isPreview || active ? 1 : 0.3 }}>
      <OfferCover isPreview={isPreview} companyImage={businessImageUrl} image={imageUrl} company={bussinessId} />
      <Box className={classes.autoHeight}>
        <OfferHeader h1={headline1} h2={headline2} location={isPreview ? '{dynamic location}' : distance} />
        <OfferContent>{description}</OfferContent>
      </Box>
      <OfferMeta isPreview={isPreview} quantity={claimsRemaining} endDate={endDate} />
      <OfferActions
        active={active}
        businessImageUrl={businessImageUrl}
        claimsRemaining={claimsRemaining}
        description={description}
        details={details}
        distance={distance}
        endDate={endDate}
        headline1={headline1}
        headline2={headline2}
        id={id}
        imageUrl={imageUrl}
        isPreview={isPreview}
        revalidateList={revalidateList}
      />
    </Card>
  );
};

export default OfferCard;
