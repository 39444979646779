import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../PaymentForm'
import { stripeApiKey } from 'utils/config'

const stripePromise = loadStripe(stripeApiKey);

const PaymentPopup = ({ onClose }) => {
  return (
    <Elements stripe={stripePromise}>
      <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
        <DialogTitle>
          Add new payment method
        </DialogTitle>
        <DialogContent>
          <PaymentForm onClose={onClose} />
        </DialogContent>
      </Dialog>
    </Elements>
  )
}

export default PaymentPopup
