import React, { useCallback, useState } from "react";
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import api from 'utils/api'
import * as businessActions from 'modules/Business/actions'

const AddCard = ({ businessId, card, cards, onClose }) => {
  const dispatch = useDispatch()
  const [selectedCard, setSelectedCard] = useState(card)
  const { enqueueSnackbar } = useSnackbar()

  const handleChange = useCallback(
    (e) => {
      setSelectedCard(e.target.value)
    }, []
  )

  const handleSubmit = useCallback(
    () => {
      if (!selectedCard) {
        return
      }
      api
        .put(`/business/${businessId}/card/${selectedCard}`)
        .then((res) => {
          onClose()
          enqueueSnackbar('Card added to your business account', { variant: 'success' })
          dispatch(businessActions.fetchBusiness(businessId, true))
        })
        .catch(() => {
          enqueueSnackbar('Cannot setup your card', { variant: 'error' })
        })
    }, [businessId, dispatch, enqueueSnackbar, selectedCard, onClose]
  )

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Select credit card</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Your cards</InputLabel>
          <Select
            value={selectedCard}
            onChange={handleChange}
            label="Your cards"
          >
            {
              cards &&
              cards.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {c.brand} **** **** **** {c.last4} - expires on {c.expMonth}/{c.expYear}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={onClose}>Cancel</Button>
          <Button color="primary" disabled={!selectedCard} variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddCard;
