import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import BusinessHeader from '../BusinessHeader'

const BusinessPage = ({ children, title }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <main
      style={{
        background: '#fcfcfc',
        marginLeft: isMobile ? 0 : 300,
        minHeight: '100vh',
      }}
    >
      <BusinessHeader title={title} />
      {children}
    </main>
  )
}

export default BusinessPage
