import React from 'react'
import {get} from 'lodash'
import useSWR from 'swr'
import { Grid, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Offer } from 'components';
import api from 'utils/api'
import { useLocation } from 'utils/location'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
  }
}))

const TopOffers = () => {
  const location = useLocation()
  const classes = useStyles()
  const url = `/offer/top10clicks?latitude=${get(location,'latitude')}&longitude=${get(location,'longitude')}`
  const { data: offers } = useSWR(location ? url : null, () => api.get(url), { revalidateOnFocus: false })

  return (
    <Grid
      alignItems="stretch"
      container
      direction="row"
      justifyContent="center"
      spacing={2}
    >
      {
        offers ?
        offers
          .map(offer => (
            <Grid key={offer.id} item xs={12} sm={6} md={4} lg={3} className={classes.grid}>
              <Offer
                offer={offer}
                offerOrigin="topOffers"
              />
            </Grid>
          ))
        :
        [0,1,2,3].map(() => (
          <Grid item xs>
            <Skeleton variant="rect" height={150} style={{ marginBottom: 10 }} />
            <Skeleton height={20} style={{ marginBottom: 4 }} />
            <Skeleton height={20} style={{ marginBottom: 4 }} />
            <Skeleton height={20} style={{ marginBottom: 4 }} />
            <Skeleton height={20} style={{ marginBottom: 4 }} />
          </Grid>
        ))
      }
    </Grid>
  )
}

export default TopOffers
