import { createMuiTheme } from '@material-ui/core/styles';

const getOverrides = () => ({
  MuiButton: {
    root: {
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'none',
    },
  },
})

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
  input: {
    bgColor: '#EDEDED',
  },
  palette: {
    primary: {
      main: '#0E75BD',
    },
    secondary: {
      main: '#F9B756',
    },
    error: {
      main: '#f44336',
    },
    background: {
      section: '#f5f5f5',
    }
  },
  overrides: getOverrides()
})
