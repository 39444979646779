import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'  
import { QueryParamProvider } from 'use-query-params'
import TagManager from 'react-gtm-module'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core/styles'
import { store } from 'store'
import AppContainer from './components/AppContainer'
import RegistrationSuccess from '../AuthPopup/components/Register/RegistrationSuccess'  
import { theme } from 'utils/theme'
import { gtmID } from 'utils/config'

TagManager.initialize({ gtmId: gtmID })

const App = () => {
  return (
    <Router>
      <meta name="referrer" content="no-referrer" />
      <QueryParamProvider ReactRouterRoute={Route}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
              <Switch>
                {/* Define the route for the RegistrationSuccess page */}
                <Route path="/registration-success" component={RegistrationSuccess} />
                
                {/* All other routes and main app logic */}
                <Route path="/" component={AppContainer} />
              </Switch>
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </QueryParamProvider>
    </Router>
  )
}

export default App
