import React, { useState, useEffect } from 'react'
import { FormControl, FormHelperText, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import api from 'utils/api'

const CPC_VALUE = 0.65;

const LocationZip = ({ error, updateLocation, value }) => {
  const [options, setOptions] = useState([])
  const [estimatedCPC, setEstimatedCPC] = useState(0);

  const handleSearch = (e, q) => {
    api.get(`/geo/zipcodes?code=${q}`)
      .then((res) => {
        setOptions(res.content)
      })
      .catch(() => {
        setOptions([])
      })
  }

  useEffect(() => {
    setEstimatedCPC(CPC_VALUE);
  }, [value]);

  return (
    <FormControl variant="outlined" error={error} fullWidth>
      <Autocomplete
        onChange={(e, val) => { updateLocation(val) }}
        onInputChange={handleSearch}
        getOptionLabel={o => o}
        size="small"
        multiple
        options={options}
        value={value}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="Zipcodes"
            InputLabelProps={{
              color: 'primary',
            }}
            variant="outlined"
          />
        )}
      />
      {
        error &&
        <FormHelperText>
          Select at least 1 Zipcode
        </FormHelperText>
      }
      <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
        Estimated Cost Per Click: ${estimatedCPC.toFixed(2)}
      </Typography>
    </FormControl>
  )
}

export default LocationZip
