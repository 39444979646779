import React from 'react'
import { Grid } from '@material-ui/core'
import { useIsMobile } from 'utils/UI'
import Offer from '../Offer'
import { listingType } from '../utils'


const Offers = ({ offers, offerOrigin = 'search', offerType = listingType.card, columns = 3, revalidateList }) => {
  const isCard = offerType === listingType.card
  const isMobile = useIsMobile()
  const isFavorites = offerOrigin === 'favorite'

  return (
    <Grid
      alignItems="stretch"
      container
      direction={isCard ? 'row' : 'column'}
      justifyContent="center"
      spacing={2}
    >
      {
        offers
          .map(offer => (
            <Grid key={offer.id} item xs={12} sm={6} md={isFavorites ? 6 : 4} lg={isCard ? columns : 12} style={{
              display: isCard ? 'flex' : 'block',
            }}>
              <Offer
                offer={offer}
                offerOrigin={offerOrigin}
                offerType={isMobile ? listingType.card : offerType}
                revalidateList={revalidateList}
              />
            </Grid>
          ))
      }
    </Grid>
  )
}

export default Offers
