import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Offers } from 'components'
import ListIcon from '@material-ui/icons/ViewList'
import CardIcon from '@material-ui/icons/ViewModule'
import useSWR from 'swr'
import api from 'utils/api'
import AccountPage from '../../components/AccountPage'

const FavoritePageContainer = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [listMode, setListMode] = useState('card')
  const apiRoute = `/favorite?size=12&page=${currentPage - 1}`
  const { data: offers, mutate } = useSWR(apiRoute, () => api.get(apiRoute), { shouldRetryOnError: false, revalidateOnFocus: false })

  if (!offers) {
    return (
      <AccountPage title="My Favorites">
        <Box p={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </AccountPage>
    )
  }

  return (
    <AccountPage title="My favorites">
      <Box
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        my={3}
      >
        <Typography variant="p" />
        <div>
          <Hidden smDown>
            <>
              <IconButton
                aria-label="list view"
                onClick={() => setListMode('list')}
                color={listMode === 'list' ? 'primary' : 'default'}
              >
                <ListIcon />
              </IconButton>
              <IconButton
                aria-label="cards view"
                onClick={() => setListMode('card')}
                color={listMode === 'card' ? 'primary' : 'default'}
              >
                <CardIcon />
              </IconButton>
            </>
          </Hidden>
        </div>
      </Box>
      <Box p={2}>
        {
          Boolean(offers.content.length) ?
          <Offers columns={4} offers={offers.content} offerOrigin="favorite" offerType={listMode} revalidateList={mutate} />
          :
          <Typography paragraph>
            You currently don't have any favorites
          </Typography>
        }
        {
          offers.totalPages > 1 &&
          <Box display="flex" justifyContent="center" pt={4}>
            <Pagination page={currentPage} count={offers.totalPages} onChange={(e, newPage) => setCurrentPage(newPage)} color="primary" />
          </Box>
        }
      </Box>
    </AccountPage>
  )
}

export default FavoritePageContainer

