import React, { useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import api from 'utils/api'

const CategorySelector = ({
  control
}) => {
  const [options, setOptions] = useState([])


  useEffect(
    () => {
      api.get('/category')
        .then(resData => {
          setOptions(resData.content)
        })
    }, []
  )

  const searchCategories = function (e, val) {
      api.get(`/category?name=${val}`)
          .then( (res) => setOptions(res.content))
  }

  const getLabel = useCallback(o => o.categoryName, [])

  return (
    <Controller
      name="categories"
      control={control}
      render={({ onChange, value }) => {
        const val = typeof value === 'object' ? value : options ? options.find(o => o.categoryId === value) : null
        return (
        <Autocomplete
          ChipProps={{
            color: 'primary',
          }}
          disabled={!options}
          getOptionLabel={getLabel}
          onInputChange={(e, val) => {searchCategories(e, val)}}
          onChange={(e, val) => {
            // onChange(val ? val.categoryId : undefined)
            onChange(val)
          }}
          multiple
          size="small"
          options={options || []}
          value={val}
          renderInput={params => (
            <TextField
            {...params}
              fullWidth
              helperText="Pick your category or search for a custom category"
              label="Categories"
              InputLabelProps={{
                color: 'primary',
              }}
              variant="outlined"
            />
          )}
        />
      )}}
    />
  )
}

export default CategorySelector
