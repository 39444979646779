import React, { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { Lock as LockIcon } from '@material-ui/icons'
import { ConfirmationDialog } from 'components'
import api from 'utils/api'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: props => props.isLocked ? theme.palette.error.main : '#ccc',
  },
}))

const LockButton = ({ id, isLocked }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [lockedStatus, setLockedStatus] = useState(isLocked)
  const [showVerification, setShowVerification] = useState(false)

  const handleClick = useCallback(() => {
    setShowVerification(true)
  }, [])

  const unlockUser = useCallback(
    () => {
      api.post(`/admin/user/unlock/${id}`)
        .then(() => {
          enqueueSnackbar('User unlocked', { variant: 'success' })
          setShowVerification(false)
          setLockedStatus(false)
        })
        .catch(() => {
          enqueueSnackbar('Cannot unlock user', { variant: 'error' })
        })
    }, [enqueueSnackbar, id]
  )

  const lockUser = useCallback(
    () => {
      api.post(`/admin/user/lock/${id}`)
        .then(() => {
          enqueueSnackbar('User locked', { variant: 'success' })
          setShowVerification(false)
          setLockedStatus(true)
        })
        .catch(() => {
          enqueueSnackbar('Cannot lock user', { variant: 'error' })
        })
    }, [enqueueSnackbar, id]
  )

  const classes = useStyles({ isLocked: lockedStatus })

  return (
    <>
      <Tooltip enterDelay={500} title={lockedStatus ? 'Unlock user' : 'Lock user'}>
        <IconButton onClick={handleClick}>
          <LockIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      {
        showVerification &&
        <ConfirmationDialog
          onCancel={() => setShowVerification(false)}
          onConfirm={lockedStatus ? unlockUser : lockUser}
          isOpen
          body={`Are you sure you want to ${lockedStatus ? 'unlock user' : 'lock user'}?`}
          title={lockedStatus ? 'Unlock user' : 'Lock user'}
          successButtonLabel={lockedStatus ? 'Unlock user' : 'Lock user'}
        />
      }
    </>
  )
}

export default LockButton
