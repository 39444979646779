import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useIsMobile } from 'utils/UI'

const Barcode = ({ code }) => {
  const h = 100
  const w = 400
  const isMobile = useIsMobile()
  return (
    <Box borderRadius={4} p={4} mb={1} style={{ border: '1px solid #ddd'}} display="flex" alignItems="center" flexDirection="column">
      <Box width={isMobile ? '100%' : w} minHeight={h}>
        <img src={`/api/barcodes/barcode?code=${code}&width=${w}&height=${h}`} alt="barcode" style={{ maxWidth: '100%' }} />
      </Box>
      <Typography variant="h6" color="primary">
        {code}
      </Typography>
    </Box>
  )
}

export default Barcode
